import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faBox } from '@fortawesome/free-solid-svg-icons';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { useNavigate } from 'react-router-dom';
import ListItem from '../ui/list/ListItem';
import { InventoryType } from '../../../../global';
import numeral from 'numeral';

type InventoryProps = {
  inventory: InventoryType;
};

const Inventory = ({ inventory }: InventoryProps) => {
  const navigate = useNavigate();
  return (
    <ListItem onClick={() => navigate(`/inventories/${inventory.id}`)}>
      <div className="self-center col-span-5 text-lh-head-black">
        <FontAwesomeIcon icon={faUserCircle} /> {inventory.distributor.name}
      </div>
      <div className="grid grid-cols-12 col-span-4 h-48">
        <p className="col-span-6 text-left pt-3 truncate overflow-hidden">
          Classic
        </p>
        <p className="col-span-6 text-lh-text-black text-right pt-3 truncate overflow-hidden">
          {numeral(inventory.current.classic).format('0.[0]')}
          <span className="text-gray-500 font-light ml-1">tons</span>
        </p>
        <p className="col-span-6 text-left pt-3 truncate overflow-hidden">
          Supaset
        </p>
        <p className="col-span-6 text-lh-text-black text-right pt-3 truncate overflow-hidden">
          {numeral(inventory.current.supaset).format('0.[0]')}
          <span className="text-gray-500 font-light ml-1">tons</span>
        </p>
        <p className="col-span-6 text-left pt-3 truncate overflow-hidden">
          Supafix C0
        </p>
        <p className="col-span-6 text-lh-text-black text-right pt-3 truncate overflow-hidden">
          {numeral(inventory.current.supafixc0).format('0.[0]')}
          <span className="text-gray-500 font-light ml-1">tons</span>
        </p>
        <p className="col-span-6 text-left pt-3 truncate overflow-hidden">
          Supafix C1
        </p>
        <p className="col-span-6 text-lh-text-black text-right pt-3 truncate overflow-hidden">
          {numeral(inventory.current.supafixc1).format('0.[0]')}
          <span className="text-gray-500 font-light ml-1">tons</span>
        </p>
      </div>
      <p className="self-center text-right pr-4 pt-1 col-span-1 text-lh-head-black">
        <FontAwesomeIcon icon={faAngleRight} />
      </p>
    </ListItem>
  );
};

export default Inventory;
