import { useParams } from 'react-router-dom';
import Alert from '../../../components/ui/Alert';
import Card from '../../../components/ui/Card';
import Back from '../../../components/ui/Back';
import Loader from '../../../components/ui/Loader';
import Content from '../../../components/layout/Content';
import { faBox } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useInventory } from '../../../lib/hooks/use-inventories';
import dayjs from 'dayjs';
import { useAssociate } from '../../../lib/hooks/use-associates';
import Edit from '../../../components/ui/Edit';
import { useCheckAccess } from '../../../lib/auth/use-checkAccess';
import Products from '../../../components/inventories/Products';
import isToday from 'dayjs/plugin/isToday';

dayjs.extend(isToday);

type TargetDetailsParams = 'id';

const Inventory = () => {
  const { id } = useParams<TargetDetailsParams>();
  const checkAccess = useCheckAccess();
  const { inventory, error, loading } = useInventory(id);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [date, setDate] = useState<dayjs.Dayjs>();
  const [isToday, setIsToday] = useState<boolean>(false);

  useEffect(() => {
    if (error) {
      setShowAlert(true);
    }
  }, [error]);

  useEffect(() => {
    if (inventory) {
      const date = dayjs(
        `${inventory.year}-${inventory.month}-${inventory.day}`
      );
      setDate(date);
      setIsToday(date.isToday());
    } else {
      setDate(undefined);
    }
  }, [inventory]);

  return (
    <Content>
      <Alert
        title="Error"
        message={error && error.message}
        open={showAlert}
        setOpen={(open) => setShowAlert(open)}
      />
      <Alert
        title="Error"
        message={error && error.message}
        open={showAlert}
        setOpen={(open) => setShowAlert(open)}
      />
      <Loader show={loading} />
      <Card className="grid grid-cols-12">
        <Back to="/inventories" className="col-span-4" />
        {inventory && (
          <h2 className="col-span-4 flex justify-center items-center text-center text-xl font-bold text-lh-head-black">
            {isToday ? 'Today' : date?.format(`ddd, DD.MM.YYYY`)}
          </h2>
        )}
        {inventory ? (
          <>
            {isToday ? (
              <Products
                className="col-span-full"
                products={inventory.current}
                title={'Current Inventory'}
              />
            ) : (
              <Products
                className="col-span-full"
                products={inventory.eod}
                title={'End of Day Inventory'}
              />
            )}
            <Products
              className="col-span-full mt-4"
              products={inventory.incoming}
              title={'Added Inventory'}
            />
          </>
        ) : (
          <div className="flex justify-center">
            <p className="text-lh-text-black">No Inventory Found</p>
          </div>
        )}
      </Card>
    </Content>
  );
};

export default Inventory;
