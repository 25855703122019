import { useEffect, useState } from 'react';
import Loader from '../../components/ui/Loader';
import SearchAndSort from '../../components/ui/SearchAndSort';
import {
  useAssociates,
  useAssociatesTypesense,
} from '../../lib/hooks/use-associates';
import Alert from '../../components/ui/Alert';
import Content from '../../components/layout/Content';
import Reload from '../../components/ui/Reload';
import TopBar from '../../components/layout/TopBar';
import List from '../../components/ui/list/List';
import Retailer from '../../components/retailer/Retailer';
import useStore from '../../lib/hooks/use-store';
import { Link } from 'react-router-dom';
import { useCheckAccess } from '../../lib/auth/use-checkAccess';
import { useAuth } from '../../lib/hooks/use-auth';
import New from '../../components/ui/New';

const Retailers = () => {
  const checkAccess = useCheckAccess();
  const { typesenseKey } = useAuth();

  const [state, dispatch] = useStore();
  const [searchText, setSearchText] = useState(null);
  const [searchEnabled, setSearchEnabled] = useState(false);

  const {
    error,
    hasMore,
    load,
    loading,
    associates: retailers,
    reset,
  } = useAssociates({
    roles: 'retailer',
    category: state.retailerStatus,
    searchText: searchText,
  });

  const {
    error: errorTypesense,
    hasMore: hasMoreTypesense,
    load: loadTypesense,
    loading: loadingTypesense,
    associates: retailersTypesense,
    reset: resetTypesense,
  } = useAssociatesTypesense({
    roles: 'retailer',
    category: state.retailerStatus,
    searchText: searchText,
  });

  const [showAlert, setShowAlert] = useState<boolean>(false);

  useEffect(() => {
    if (typesenseKey) {
      setSearchEnabled(true);
    }
  });

  useEffect(() => {
    if (error) {
      setShowAlert(true);
    } else if (showAlert) {
      setShowAlert(false);
    }
    return () => {
      setShowAlert(false);
    };
  }, [error]);

  const onCategoryChange = (event) => {
    dispatch('SET_RETAILER_STATUS', event.target.value);
  };

  return (
    <Content topBar>
      <Alert
        title="Error"
        message={error && error.message}
        open={showAlert}
        setOpen={(open) => setShowAlert(open)}
      />
      <Alert
        title="Error"
        message={errorTypesense}
        open={showAlert}
        setOpen={(open) => setShowAlert(open)}
      />
      <Reload
        onClick={() => {
          reset();
          resetTypesense();
        }}
        blocked={loading}
      />
      <TopBar>
        <SearchAndSort
          onCategoryChange={onCategoryChange}
          selectedCategory={state.retailerStatus}
          categories={{
            all: 'All',
            actionRequired: 'Action required',
            noWhatsApp: 'No WhatsApp',
            enabledWhatsApp: 'WhatsApp enabled',
            phoneChanged: 'Phone Nr changed',
          }}
          displayCategory={true}
          displaySearch={true}
          searchPlaceHolder="Retailer name"
          className={'mt-4'}
          setSearchText={setSearchText}
          searchDisabled={!searchEnabled}
        />
      </TopBar>
      {(!searchText || (searchText && searchText.length < 3)) && (
        <>
          <Loader show={loading} />
          <List load={load} hasMore={hasMore} loading={loading}>
            {retailers &&
              retailers.map((ret) => <Retailer retailer={ret} key={ret.id} />)}
          </List>
        </>
      )}

      {searchText && searchText.length >= 3 && (
        <>
          <Loader show={loadingTypesense} />
          <List
            load={loadTypesense}
            hasMore={hasMoreTypesense}
            loading={loadingTypesense}
          >
            {retailersTypesense &&
              retailersTypesense.map((ret) => (
                <Retailer retailer={ret} key={ret.id} />
              ))}
          </List>
        </>
      )}
      {checkAccess(['sop', 'fsa']) && <New to="new" />}
    </Content>
  );
};

export default Retailers;
