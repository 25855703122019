import { faAngleRight, faTruck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { DeliveryType } from '../../../../global';

type DeliveryProps = {
  delivery: DeliveryType;
};

const Delivery = ({ delivery }: DeliveryProps) => {
  const navigate = useNavigate();
  return (
    <li
      className="flex justify-between mx-2 mb-2 list-none shadow-md rounded-lg pb-4 px-2 pt-2 cursor-pointer bg-white"
      onClick={() => navigate(`/deliveries/${delivery.id}`)}
    >
      <div className="grid grid-cols-10 w-full">
        <div className="col-span-6">
          <p className="text-lh-head-black align-middle col-span-4 font-medium">
            <FontAwesomeIcon icon={faTruck} className="text-lh-head-black" />{' '}
            {delivery.LOGONOrderNumber}
          </p>
          <p className="text-sm text-lh-text-black">
            {dayjs(delivery.deliveryDate).format('DD.MM.YYYY HH:mm')}
          </p>
        </div>
        <p className="col-span-3 text-right self-center mt-1 text-lh-head-black font-semibold">
          {delivery.volume}{' '}
          <span className="text-gray-500 font-light">tons of </span>{' '}
          {delivery.productNameInternal}
        </p>
        <p className="col-span-1 text-right self-center mt-1 text-lh-head-black font-bold mr-4">
          <FontAwesomeIcon
            icon={faAngleRight}
            className="text-lh-head-black font-bold align-middle"
          />
        </p>
      </div>
    </li>
  );
};

export default Delivery;
