import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { RegisterOptions, useForm, useWatch } from 'react-hook-form';
import Input from '../../../components/forms/Input';
import { useState } from 'react';

type Props = {
  editable: boolean;
  setStockLevel: any;
  setStockIsDirty: any;
  setStockIsValid: any;
  stockLevel: {
    classicAmount: number;
    classicBuyPrice: number;
    classicSellPrice: number;
    supasetAmount: number;
    supasetBuyPrice: number;
    supasetSellPrice: number;
    supafixc0Amount: number;
    supafixc0BuyPrice: number;
    supafixc0SellPrice: number;
    supafixc1Amount: number;
    supafixc1BuyPrice: number;
    supafixc1SellPrice: number;
    comp1fAmount: number;
    comp1fBuyPrice: number;
    comp1fSellPrice: number;
    comp1bAmount: number;
    comp1bBuyPrice: number;
    comp1bSellPrice: number;
    comp1xAmount: number;
    comp1xBuyPrice: number;
    comp1xSellPrice: number;
    comp2Amount: number;
    comp2BuyPrice: number;
    comp2SellPrice: number;
  };
};

const VisitStep1 = forwardRef((props: Props, ref) => {
  const [formValid, setFormValid] = useState(null);
  const {
    control,
    register,
    getValues,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm({
    defaultValues: { ...props.stockLevel },
    mode: 'all',
  });

  const classicAmount = useWatch({
    control,
    name: 'classicAmount',
  });

  const classicBuyPrice = useWatch({
    control,
    name: 'classicBuyPrice',
  });

  const classicSellPrice = useWatch({
    control,
    name: 'classicSellPrice',
  });

  const supasetAmount = useWatch({
    control,
    name: 'supasetAmount',
  });

  const supasetBuyPrice = useWatch({
    control,
    name: 'supasetBuyPrice',
  });

  const supasetSellPrice = useWatch({
    control,
    name: 'supasetSellPrice',
  });

  const supafixc0Amount = useWatch({
    control,
    name: 'supafixc0Amount',
  });

  const supafixc0BuyPrice = useWatch({
    control,
    name: 'supafixc0BuyPrice',
  });

  const supafixc0SellPrice = useWatch({
    control,
    name: 'supafixc0SellPrice',
  });

  const supafixc1Amount = useWatch({
    control,
    name: 'supafixc1Amount',
  });

  const supafixc1BuyPrice = useWatch({
    control,
    name: 'supafixc1BuyPrice',
  });

  const supafixc1SellPrice = useWatch({
    control,
    name: 'supafixc1SellPrice',
  });

  const comp1fAmount = useWatch({
    control,
    name: 'comp1fAmount',
  });

  const comp1fBuyPrice = useWatch({
    control,
    name: 'comp1fBuyPrice',
  });

  const comp1fSellPrice = useWatch({
    control,
    name: 'comp1fSellPrice',
  });

  const comp1bAmount = useWatch({
    control,
    name: 'comp1bAmount',
  });

  const comp1bBuyPrice = useWatch({
    control,
    name: 'comp1bBuyPrice',
  });

  const comp1bSellPrice = useWatch({
    control,
    name: 'comp1bSellPrice',
  });

  const comp1xAmount = useWatch({
    control,
    name: 'comp1xAmount',
  });

  const comp1xBuyPrice = useWatch({
    control,
    name: 'comp1xBuyPrice',
  });

  const comp1xSellPrice = useWatch({
    control,
    name: 'comp1xSellPrice',
  });

  const comp2Amount = useWatch({
    control,
    name: 'comp2Amount',
  });

  const comp2BuyPrice = useWatch({
    control,
    name: 'comp2BuyPrice',
  });

  const comp2SellPrice = useWatch({
    control,
    name: 'comp2SellPrice',
  });

  const priceOptions: RegisterOptions = {
    valueAsNumber: true,
    min: {
      value: 1000,
      message: 'Price needs to be higher than 1000!',
    },
    max: {
      value: 9999,
      message: 'Price needs to be lower than 10000!',
    },
  };

  const amountOptions: RegisterOptions = {
    valueAsNumber: true,
    min: {
      value: 1,
      message: 'Amount needs to be higher than 0!',
    },
  };

  useEffect(() => {
    const values = getValues();
    if (
      isValid &&
      Object.keys(values).find(
        (value) => !isNaN(values[value]) && values[value] !== undefined
      ) &&
      ((classicAmount && classicBuyPrice && classicSellPrice) ||
        ((classicAmount === undefined || isNaN(classicAmount)) &&
          (classicBuyPrice === undefined || isNaN(classicBuyPrice)) &&
          (classicSellPrice === undefined || isNaN(classicSellPrice)))) &&
      ((supasetAmount && supasetBuyPrice && supasetSellPrice) ||
        ((supasetAmount === undefined || isNaN(supasetAmount)) &&
          (supasetBuyPrice === undefined || isNaN(supasetBuyPrice)) &&
          (supasetSellPrice === undefined || isNaN(supasetSellPrice)))) &&
      ((supafixc0Amount && supafixc0BuyPrice && supafixc0SellPrice) ||
        ((supafixc0Amount === undefined || isNaN(supafixc0Amount)) &&
          (supafixc0BuyPrice === undefined || isNaN(supafixc0BuyPrice)) &&
          (supafixc0SellPrice === undefined || isNaN(supafixc0SellPrice)))) &&
      ((supafixc1Amount && supafixc1BuyPrice && supafixc1SellPrice) ||
        ((supafixc1Amount === undefined || isNaN(supafixc1Amount)) &&
          (supafixc1BuyPrice === undefined || isNaN(supafixc1BuyPrice)) &&
          (supafixc1SellPrice === undefined || isNaN(supafixc1SellPrice)))) &&
      ((comp1fAmount && comp1fBuyPrice && comp1fSellPrice) ||
        ((comp1fAmount === undefined || isNaN(comp1fAmount)) &&
          (comp1fBuyPrice === undefined || isNaN(comp1fBuyPrice)) &&
          (comp1fSellPrice === undefined || isNaN(comp1fSellPrice)))) &&
      ((comp1bAmount && comp1bBuyPrice && comp1bSellPrice) ||
        ((comp1bAmount === undefined || isNaN(comp1bAmount)) &&
          (comp1bBuyPrice === undefined || isNaN(comp1bBuyPrice)) &&
          (comp1bSellPrice === undefined || isNaN(comp1bSellPrice)))) &&
      ((comp1xAmount && comp1xBuyPrice && comp1xSellPrice) ||
        ((comp1xAmount === undefined || isNaN(comp1xAmount)) &&
          (comp1xBuyPrice === undefined || isNaN(comp1xBuyPrice)) &&
          (comp1xSellPrice === undefined || isNaN(comp1xSellPrice)))) &&
      ((comp2Amount && comp2BuyPrice && comp2SellPrice) ||
        ((comp2Amount === undefined || isNaN(comp2Amount)) &&
          (comp2BuyPrice === undefined || isNaN(comp2BuyPrice)) &&
          (comp2SellPrice === undefined || isNaN(comp2SellPrice))))
    ) {
      props.setStockIsDirty(true);
      props.setStockIsValid(true);
      setFormValid(true);
    } else if (
      isValid &&
      (classicAmount === undefined || isNaN(classicAmount)) &&
      (classicBuyPrice === undefined || isNaN(classicBuyPrice)) &&
      (classicSellPrice === undefined || isNaN(classicSellPrice)) &&
      (supasetAmount === undefined || isNaN(supasetAmount)) &&
      (supasetBuyPrice === undefined || isNaN(supasetBuyPrice)) &&
      (supasetSellPrice === undefined || isNaN(supasetSellPrice)) &&
      (supafixc0Amount === undefined || isNaN(supafixc0Amount)) &&
      (supafixc0BuyPrice === undefined || isNaN(supafixc0BuyPrice)) &&
      (supafixc0SellPrice === undefined || isNaN(supafixc0SellPrice)) &&
      (supafixc1Amount === undefined || isNaN(supafixc1Amount)) &&
      (supafixc1BuyPrice === undefined || isNaN(supafixc1BuyPrice)) &&
      (supafixc1SellPrice === undefined || isNaN(supafixc1SellPrice)) &&
      (comp1fAmount === undefined || isNaN(comp1fAmount)) &&
      (comp1fBuyPrice === undefined || isNaN(comp1fBuyPrice)) &&
      (comp1fSellPrice === undefined || isNaN(comp1fSellPrice)) &&
      (comp1bAmount === undefined || isNaN(comp1bAmount)) &&
      (comp1bBuyPrice === undefined || isNaN(comp1bBuyPrice)) &&
      (comp1bSellPrice === undefined || isNaN(comp1bSellPrice)) &&
      (comp1xAmount === undefined || isNaN(comp1xAmount)) &&
      (comp1xBuyPrice === undefined || isNaN(comp1xBuyPrice)) &&
      (comp1xSellPrice === undefined || isNaN(comp1xSellPrice)) &&
      (comp2Amount === undefined || isNaN(comp2Amount)) &&
      (comp2BuyPrice === undefined || isNaN(comp2BuyPrice)) &&
      (comp2SellPrice === undefined || isNaN(comp2SellPrice))
    ) {
      props.setStockIsDirty(false);
      setFormValid(true);
    } else {
      props.setStockIsValid(false);
      props.setStockIsDirty(true);
      setFormValid(false);
    }
  }, [
    classicAmount,
    classicBuyPrice,
    classicSellPrice,
    comp1bAmount,
    comp1bBuyPrice,
    comp1bSellPrice,
    comp1fAmount,
    comp1fBuyPrice,
    comp1fSellPrice,
    comp1xAmount,
    comp1xBuyPrice,
    comp1xSellPrice,
    comp2Amount,
    comp2BuyPrice,
    comp2SellPrice,
    getValues,
    isValid,
    props,
    supafixc0Amount,
    supafixc0BuyPrice,
    supafixc0SellPrice,
    supafixc1Amount,
    supafixc1BuyPrice,
    supafixc1SellPrice,
    supasetAmount,
    supasetBuyPrice,
    supasetSellPrice,
  ]);

  useImperativeHandle(ref, () => ({
    saveStock() {
      doSaveStock();
    },
  }));

  const doSaveStock = () => {
    handleSubmit(props.setStockLevel)();
  };

  return (
    <>
      <div className="grid grid-cols-9 col-span-9 ">
        <div className="grid grid-cols-12 col-span-9 content-center ">
          <p className={'col-span-12 text-center mt-4 font-semibold'}>
            Capture Stock
          </p>
          <p className={'col-span-3 text-left mt-2 mb-4 font-semibold'}>
            Product
          </p>
          <p className={'col-span-3 text-center mt-2 mb-4 font-semibold'}>
            Stock (bags)
          </p>
          <p className={'col-span-3 text-center mt-2 mb-4 font-semibold'}>
            Buy price (₦)
          </p>
          <p className={'col-span-3 text-center mt-2 mb-4 font-semibold'}>
            Sell price (₦)
          </p>
          <p className={'col-span-3 text-left mb-2 font-normal mt-1'}>
            Classic
          </p>
          <Input
            register={register}
            options={amountOptions}
            type="number"
            name="classicAmount"
            placeholder="0"
            error={errors.classicAmount}
            disabled={props.editable}
            className={'col-span-3  pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <Input
            register={register}
            options={priceOptions}
            type="number"
            name="classicBuyPrice"
            placeholder="0"
            error={errors.classicBuyPrice}
            disabled={props.editable}
            className={'col-span-3 pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <Input
            register={register}
            options={priceOptions}
            type="number"
            name="classicSellPrice"
            placeholder="0"
            error={errors.classicSellPrice}
            disabled={props.editable}
            className={'col-span-3 pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <p className={'col-span-3 text-left mb-2 font-normal mt-1'}>
            Supaset
          </p>
          <Input
            register={register}
            options={amountOptions}
            type="number"
            name="supasetAmount"
            placeholder="0"
            error={errors.supasetAmount}
            disabled={props.editable}
            className={'col-span-3  pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <Input
            register={register}
            options={priceOptions}
            type="number"
            name="supasetBuyPrice"
            placeholder="0"
            error={errors.supasetBuyPrice}
            disabled={props.editable}
            className={'col-span-3 pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <Input
            register={register}
            options={priceOptions}
            type="number"
            name="supasetSellPrice"
            placeholder="0"
            error={errors.supasetSellPrice}
            disabled={props.editable}
            className={'col-span-3 pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <p className={'col-span-3 text-left mb-2 font-normal mt-1'}>
            Supafix C0
          </p>
          <Input
            register={register}
            options={amountOptions}
            type="number"
            name="supafixc0Amount"
            placeholder="0"
            error={errors.supafixc0Amount}
            disabled={props.editable}
            className={'col-span-3  pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <Input
            register={register}
            options={priceOptions}
            type="number"
            name="supafixc0BuyPrice"
            placeholder="0"
            error={errors.supafixc0BuyPrice}
            disabled={props.editable}
            className={'col-span-3 pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <Input
            register={register}
            options={priceOptions}
            type="number"
            name="supafixc0SellPrice"
            placeholder="0"
            error={errors.supafixc0SellPrice}
            disabled={props.editable}
            className={'col-span-3 pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <p className={'col-span-3 text-left mb-2 font-normal mt-1'}>
            Supafix C1
          </p>
          <Input
            register={register}
            options={amountOptions}
            type="number"
            name="supafixc1Amount"
            placeholder="0"
            error={errors.supafixc1Amount}
            disabled={props.editable}
            className={'col-span-3  pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <Input
            register={register}
            options={priceOptions}
            type="number"
            name="supafixc1BuyPrice"
            placeholder="0"
            error={errors.supafixc1BuyPrice}
            disabled={props.editable}
            className={'col-span-3 pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <Input
            register={register}
            options={priceOptions}
            type="number"
            name="supafixc1SellPrice"
            placeholder="0"
            error={errors.supafixc1SellPrice}
            disabled={props.editable}
            className={'col-span-3 pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <p className={'col-span-3 text-left mb-2 font-normal mt-1'}>
            Comp 1 F
          </p>
          <Input
            register={register}
            options={amountOptions}
            type="number"
            name="comp1fAmount"
            placeholder="0"
            error={errors.comp1fAmount}
            disabled={props.editable}
            className={'col-span-3  pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <Input
            register={register}
            options={priceOptions}
            type="number"
            name="comp1fBuyPrice"
            placeholder="0"
            error={errors.comp1fBuyPrice}
            disabled={props.editable}
            className={'col-span-3 pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <Input
            register={register}
            options={priceOptions}
            type="number"
            name="comp1fSellPrice"
            placeholder="0"
            error={errors.comp1fSellPrice}
            disabled={props.editable}
            className={'col-span-3 pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <p className={'col-span-3 text-left mb-2 font-normal mt-1'}>
            Comp 1 B
          </p>
          <Input
            register={register}
            options={amountOptions}
            type="number"
            name="comp1bAmount"
            placeholder="0"
            error={errors.comp1bAmount}
            disabled={props.editable}
            className={'col-span-3  pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <Input
            register={register}
            options={priceOptions}
            type="number"
            name="comp1bBuyPrice"
            placeholder="0"
            error={errors.comp1bBuyPrice}
            disabled={props.editable}
            className={'col-span-3 pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <Input
            register={register}
            options={priceOptions}
            type="number"
            name="comp1bSellPrice"
            placeholder="0"
            error={errors.comp1bSellPrice}
            disabled={props.editable}
            className={'col-span-3 pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <p className={'col-span-3 text-left mb-2 font-normal mt-1'}>
            Comp 1 X
          </p>
          <Input
            register={register}
            options={amountOptions}
            type="number"
            name="comp1xAmount"
            placeholder="0"
            error={errors.comp1xAmount}
            disabled={props.editable}
            className={'col-span-3  pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <Input
            register={register}
            options={priceOptions}
            type="number"
            name="comp1xBuyPrice"
            placeholder="0"
            error={errors.comp1xBuyPrice}
            disabled={props.editable}
            className={'col-span-3 pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <Input
            register={register}
            options={priceOptions}
            type="number"
            name="comp1xSellPrice"
            placeholder="0"
            error={errors.comp1xSellPrice}
            disabled={props.editable}
            className={'col-span-3 pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <p className={'col-span-3 text-left mb-2 font-normal mt-1'}>Comp 2</p>
          <Input
            register={register}
            options={amountOptions}
            type="number"
            name="comp2Amount"
            placeholder="0"
            error={errors.comp2Amount}
            disabled={props.editable}
            className={'col-span-3  pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <Input
            register={register}
            options={priceOptions}
            type="number"
            name="comp2BuyPrice"
            placeholder="0"
            error={errors.comp2BuyPrice}
            disabled={props.editable}
            className={'col-span-3 pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <Input
            register={register}
            options={priceOptions}
            type="number"
            name="comp2SellPrice"
            placeholder="0"
            error={errors.comp2SellPrice}
            disabled={props.editable}
            className={'col-span-3 pr-2 text-right mx-1 mb-2 font-normal'}
          />
        </div>
        {!formValid && (
          <p
            className={
              'col-span-12 pr-2 text-center text-red-500 mx-1 mb-2 font-normal'
            }
          >
            Please fill in three values in a row.
          </p>
        )}
      </div>
    </>
  );
});

export default VisitStep1;
