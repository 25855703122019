import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDown,
  faAngleUp,
  faUserCircle,
} from '@fortawesome/free-solid-svg-icons';
import { Associate } from '../../../../global';
import Button from '../ui/Button';
import { useCheckAccess } from '../../lib/auth/use-checkAccess';

type RouteElementProps = {
  id: string;
  name: string;
  retailers?: Associate[];
  removeOutlet: Function;
  moveLower: Function;
  moveHigher: Function;
  tempRoute?: boolean;
  routeEdited?: boolean;
};

const RouteElement = (props: RouteElementProps) => {
  const checkAccess = useCheckAccess();

  return (
    <li className="grid grid-cols-12 col-span-full justify-between shadow-sm w-full list-none rounded-sm p-5">
      <div>
        <p className="col-span-1">
          {(checkAccess(['sop']) && !props.routeEdited) ||
          (checkAccess(['fsa']) && props.tempRoute) ? (
            <>
              <p
                className="cursor-pointer"
                onClick={() => props.moveHigher(props.id)}
              >
                <FontAwesomeIcon icon={faAngleUp} />
              </p>
              <p
                className="cursor-pointer"
                onClick={() => props.moveLower(props.id)}
              >
                {' '}
                <FontAwesomeIcon icon={faAngleDown} />
              </p>
            </>
          ) : (
            ''
          )}
        </p>
      </div>
      <div className="col-span-8">
        <p className="text-sm">
          <FontAwesomeIcon icon={faUserCircle} /> {props.name}
        </p>
      </div>
      <p className="col-span-3 text-right">
        {(checkAccess(['sop']) && !props.routeEdited) ||
        (checkAccess(['fsa']) && props.tempRoute) ? (
          <Button
            onClick={() => props.removeOutlet(props.id)}
            text="Remove"
            color="red"
          />
        ) : (
          ''
        )}
      </p>
    </li>
  );
};

export default RouteElement;
