import { Status, Wrapper } from '@googlemaps/react-wrapper';
import { DevTool } from '@hookform/devtools';
import { useEffect, useState } from 'react';
import { RegisterOptions, useForm } from 'react-hook-form';
import { To } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router-dom';
import { OutletChannel, Option } from '../../../../../global';
import { Confirm } from '../../../components/forms/Confirm';
import Input from '../../../components/forms/Input';
import SearchableSelect from '../../../components/forms/SearchableSelect';
import Select from '../../../components/forms/Select';
import Content from '../../../components/layout/Content';
import Marker from '../../../components/map/Marker';
import Alert from '../../../components/ui/Alert';
import Back from '../../../components/ui/Back';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import Loader from '../../../components/ui/Loader';
import { useCheckAccess } from '../../../lib/auth/use-checkAccess';
import { useAssociates } from '../../../lib/hooks/use-associates';
import { useOutlet } from '../../../lib/hooks/use-outlets';
import { useRetailer } from '../../../lib/hooks/use-retailers';
import Map from '../../../components/map/Map';
import { useLocation } from '../../../lib/hooks/use-location';

const render = (status: Status) => {
  return <h1>{status}</h1>;
};

type EditOutletParams = 'id';

type EditOutletForm = {
  geohash: string;
  name: string;
  primaryDistributorId: Option;
  primaryFSAId: Option;
  channel: OutletChannel;
  lat: number;
  lng: number;
  accuracy: number;
};

const CHANNEL_TYPES = [
  {
    key: 'blockmaker',
    label: 'Blockmaker',
    value: 'blockmaker',
  },
  {
    key: 'blockmaker_retailer',
    label: 'Blockmaker / Retailer',
    value: 'blockmaker_retailer',
  },
  {
    key: 'container',
    label: 'Container',
    value: 'container',
  },
  {
    key: 'neighborhood_shop',
    label: 'Neighborhood Shop',
    value: 'neighborhood_shop',
  },
  {
    key: 'palleter',
    label: 'Palleter',
    value: 'palleter',
  },
  {
    key: 'tiel_seller',
    label: 'Tile Seller',
    value: 'tile_seller',
  },
];

export const EditOutlet = () => {
  const { id } = useParams<EditOutletParams>();
  const navigate = useNavigate();
  const checkAccess = useCheckAccess();
  const [retailerId, setRetailerId] = useState('');
  const {
    error: retailerError,
    loading: retailerLoading,
    update: retailerUpdate,
  } = useRetailer(retailerId);
  const {
    outlet,
    error: outletError,
    loading: outletLoading,
    update: outletUpdate,
  } = useOutlet(id);
  const {
    error: distributorsError,
    loading: distributorsLoading,
    associates: distributors,
  } = useAssociates({ roles: 'distributor' });
  const {
    error: fsasError,
    loading: fsasLoading,
    associates: fsas,
  } = useAssociates({ roles: 'fsa' });
  const {
    register,
    formState: { isValid, isDirty, errors, dirtyFields },
    reset,
    handleSubmit,
    control,
    setValue,
    trigger,
  } = useForm<EditOutletForm>();
  const [showOutletAlert, setShowOutletAlert] = useState<boolean>(false);
  const [showRetailerAlert, setShowRetailerAlert] = useState<boolean>(false);
  const [showDistributorsAlert, setShowDistributorsAlert] =
    useState<boolean>(false);
  const [showFSAsAlert, setShowFSAsAlert] = useState<boolean>(false);
  const [showLocationAlert, setShowLocationAlert] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [backString, setBackString] = useState<To>('/retailers');
  const [geohashLoading, setGeohashLoading] = useState<boolean>(false);
  const [geohashCaptured, setGeoHashCaptured] = useState<boolean>(false);
  const [accuracyThreshold, setAccuracyThreshold] = useState<number>(100);
  const [zoom, setZoom] = useState(13); // initial zoom
  const [center, setCenter] = useState<google.maps.LatLngLiteral>({
    lat: 0,
    lng: 0,
  });
  const [marker, setMarker] = useState<google.maps.LatLngLiteral>();
  const [location, accuracy, locationError] = useLocation(
    geohashLoading,
    accuracyThreshold,
    60
  );

  const [locationErrorMessage, setLocationErrorMessage] = useState<string>();

  const onIdle = (m: google.maps.Map) => {
    setZoom(m.getZoom()!);
    setCenter(m.getCenter()!.toJSON());
  };

  const nameOptions: RegisterOptions = {
    required: 'Please enter a name!',
    minLength: {
      value: 3,
      message: 'Please enter a name with at least 3 characters!',
    },
    maxLength: {
      value: 50,
      message: 'The name cannot exceed 50 characters!',
    },
  };

  const channelOptions: RegisterOptions = {
    required: 'Please select a channel type!',
  };

  useEffect(() => {
    if (outlet) {
      setRetailerId(outlet.retailer.id);
    }
  }, [outlet]);

  useEffect(() => {
    if (outlet) {
      const values = {
        name: outlet.name,
        primaryDistributorId: {
          value: outlet.primaryDistributor.id,
          label: outlet.primaryDistributor.name,
        },
        primaryFSAId: {
          value: outlet.primaryFSA.id,
          label: outlet.primaryFSA.name,
        },
      };
      if (outlet.location) {
        values['lat'] = outlet.location.lat;
        values['lng'] = outlet.location.lng;
        values['geohash'] = outlet.location.geohash;
      }
      if ('channel' in outlet) {
        values['channel'] = outlet.channel;
      }
      reset(values);
    }
  }, [outlet]);

  useEffect(() => {
    if (outletError) {
      setShowOutletAlert(true);
    } else if (showOutletAlert) {
      setShowOutletAlert(false);
    }
  }, [outletError]);

  useEffect(() => {
    if (distributorsError) {
      setShowDistributorsAlert(true);
    } else if (showOutletAlert) {
      setShowDistributorsAlert(false);
    }
  }, [distributorsError]);

  useEffect(() => {
    if (fsasError) {
      setShowFSAsAlert(true);
    } else if (showOutletAlert) {
      setShowFSAsAlert(false);
    }
  }, [outletError]);

  useEffect(() => {
    if (outlet) {
      setBackString(`/retailers/${outlet.retailer.id}`);
    }
  }, [outlet]);

  useEffect(() => {
    if (location) {
      setGeohashLoading(true);
      const { lat, lng, geohash } = location;
      setValue('lat', lat, { shouldDirty: true });
      setValue('lng', lng, { shouldDirty: true });
      setValue('geohash', geohash, { shouldDirty: true });
      setValue('accuracy', accuracy, { shouldDirty: true });
      setGeoHashCaptured(true);
      setMarker({ lat, lng });
      setCenter({ lat, lng });
      setGeohashLoading(false);
      trigger(['lat', 'lng', 'geohash', 'accuracy']);
    } else if (locationError) {
      if (locationError.code === 5) {
        setLocationErrorMessage(locationError.message);
        setShowLocationAlert(true);
        setAccuracyThreshold(null);
      } else {
        setLocationErrorMessage(locationError.message);
        setShowLocationAlert(true);
        setGeohashLoading(false);
      }
    }
  }, [location, locationError, accuracy]);

  const submitHandler = (data: EditOutletForm) => {
    if (isDirty) {
      const updateObj = {};
      let firstCapture = false;
      let otherUpdated = false;
      if (dirtyFields.name) {
        updateObj['name'] = data.name;
        otherUpdated = true;
      }
      if (dirtyFields.primaryDistributorId) {
        const dist = distributors.find(
          (dist) => dist.id === data.primaryDistributorId.value
        );
        updateObj['primaryDistributor'] = {
          id: dist.id,
          name: dist.name,
          phone: dist.phone,
        };
        otherUpdated = true;
      }
      if (dirtyFields.primaryFSAId) {
        const fsa = fsas.find((fsa) => fsa.id === data.primaryFSAId.value);
        updateObj['primaryFSA'] = {
          id: fsa.id,
          name: fsa.name,
          phone: fsa.phone,
        };
        otherUpdated = true;
      }
      if (dirtyFields.channel) {
        updateObj['channel'] = data.channel;
        otherUpdated = true;
      }
      if (dirtyFields.geohash) {
        updateObj['location'] = {
          geohash: data.geohash,
          lat: data.lat,
          lng: data.lng,
        };
        if (!outlet.location) {
          firstCapture = true;
        } else {
          otherUpdated = true;
        }
      }
      outletUpdate({ ...updateObj, firstCapture });
      if (otherUpdated && !checkAccess(['sop'])) {
        retailerUpdate({ outletChanged: true });
      }
      navigate(backString, { replace: true });
    }
  };

  const handleOnConfirm = () => {
    setShowConfirmation(false);
    handleSubmit(submitHandler)();
  };

  const handleOnCancel = () => {
    setShowConfirmation(false);
  };

  return (
    <Content>
      <Loader
        show={
          outletLoading ||
          distributorsLoading ||
          fsasLoading ||
          geohashLoading ||
          retailerLoading
        }
      />
      <Alert
        message={retailerError && retailerError.message}
        open={showRetailerAlert}
        setOpen={(open) => setShowRetailerAlert(open)}
        title="Error"
      />
      <Alert
        message={outletError && outletError.message}
        open={showOutletAlert}
        setOpen={(open) => setShowOutletAlert(open)}
        title="Error"
      />
      <Alert
        message={distributorsError && distributorsError.message}
        open={showDistributorsAlert}
        setOpen={(open) => setShowDistributorsAlert(open)}
        title="Error"
      />
      <Alert
        message={fsasError && fsasError.message}
        open={showFSAsAlert}
        setOpen={(open) => setShowFSAsAlert(open)}
        title="Error"
      />
      <Alert
        message={locationErrorMessage}
        open={showLocationAlert}
        setOpen={(open) => setShowLocationAlert(open)}
        title="Error"
      />
      <Confirm
        title="Are you sure you want to save these changes?"
        open={showConfirmation}
        onConfirm={handleOnConfirm}
        onCancel={handleOnCancel}
        setOpen={setShowConfirmation}
      />
      <Card>
        <Back to={backString} />
        <form
          onSubmit={(event) => event.preventDefault()}
          className="col-span-full flex flex-col"
        >
          <label htmlFor="name">Name</label>
          <Input
            register={register}
            name="name"
            placeholder="Name..."
            error={errors.name}
            options={nameOptions}
          />
          <label htmlFor="channel">Channel</label>
          <Select
            register={register}
            name="channel"
            items={CHANNEL_TYPES}
            placeholder="Select channel..."
            error={errors.channel}
            options={channelOptions}
            className="mb-5"
          />
          <SearchableSelect
            control={control}
            name="primaryDistributorId"
            rules={{ required: true }}
            label="Primary Distributor"
            placeholder="Select Distributor..."
            values={
              distributors
                ? distributors.map((fsa) => ({
                    value: fsa.id,
                    label: fsa.name,
                  }))
                : []
            }
            className="w-full text-lh-text-black mb-4"
          />
          {checkAccess(['sop']) && (
            <SearchableSelect
              control={control}
              name="primaryFSAId"
              rules={{ required: true }}
              label="Primary FSA"
              placeholder="Select FSA..."
              values={
                fsas
                  ? fsas.map((fsa) => ({
                      value: fsa.id,
                      label: fsa.name,
                    }))
                  : []
              }
              className="w-full text-lh-text-black mb-4"
            />
          )}
          <Button
            onClick={() => setGeohashLoading(true)}
            text={
              geohashCaptured
                ? 'Re-Capture Location'
                : 'Capture Outlet Location'
            }
            color="green"
          />
          <input {...register('lat')} className="hidden" />
          <input {...register('lng')} className="hidden" />
          <input {...register('geohash')} className="hidden" />
          <input {...register('accuracy')} className="hidden" />
          {geohashCaptured && (
            <>
              <p className="text-green-500 font-thin text-sm">
                The location has ben captured!
              </p>
              <div className="col-span-full h-56 flex mt-4">
                <Wrapper
                  apiKey={process.env.REACT_APP_MAPS_API_KEY}
                  render={render}
                >
                  <Map
                    center={center}
                    onIdle={onIdle}
                    zoom={zoom}
                    className="flex-grow h-full rounded-lg"
                    disableDefaultUI
                  >
                    ={marker && <Marker position={marker} />}
                  </Map>
                </Wrapper>
              </div>
            </>
          )}
          <Button
            buttonDisabled={!isValid || !isDirty}
            text="Save"
            color="green"
            onClick={() => {
              setShowConfirmation(true);
            }}
          />
        </form>
        <DevTool control={control} />
      </Card>
    </Content>
  );
};
