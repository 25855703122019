import { useState } from 'react';
import Loader from '../../components/ui/Loader';
import List from '../../components/ui/list/List';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../lib/hooks/use-auth';
import Content from '../../components/layout/Content';
import TopBar from '../../components/layout/TopBar';
import Reload from '../../components/ui/Reload';
import Alert from '../../components/ui/Alert';
import dayjs from 'dayjs';
import useStore from '../../lib/hooks/use-store';
import { useSiteVisits } from '../../lib/hooks/use-siteVisits';
import SiteVisit from '../../components/siteVisits/SiteVisit';

const SiteVisits = () => {
  const [state, dispatch] = useStore();
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [showAlertRoutePlan, setShowAlertRoutePlan] = useState<boolean>(false);
  const {
    visits,
    loading,
    hasMore,
    load,
    reset: resetVisits,
  } = useSiteVisits({
    day: state.dateVisits.date(),
    month: state.dateVisits.month() + 1,
    year: state.dateVisits.year(),
  });

  const onDayChange = (direction) => {
    if (direction === 'add') {
      dispatch('ADD_DAY_VISITS', {});
      resetVisits();
    } else {
      dispatch('SUBTRACT_DAY_VISITS', {});
      resetVisits();
    }
  };

  return (
    <Content topBar>
      <Alert
        title="Error"
        message={'Error'}
        open={showAlert}
        setOpen={(open) => setShowAlert(open)}
      />
      <Alert
        title="Error"
        message={'Error'}
        open={showAlertRoutePlan}
        setOpen={(open) => setShowAlertRoutePlan(open)}
      />
      <Reload onClick={() => resetVisits()} blocked />
      <TopBar>
        <div className="flex justify-between w-full flex-wrap">
          <div className="w-full grid col-span-11 grid-cols-12 justify-center mt-4">
            <button
              className={
                'font-semibold md:col-span-1 md:col-start-5 col-span-1 col-start-4 text-center cursor-pointer'
              }
              onClick={() => onDayChange('substract')}
            >
              <FontAwesomeIcon
                icon={faAngleLeft}
                className={'font-semibold '}
              />
            </button>

            <p
              className={
                'font-semibold align-middle text-center md:col-span-2 md:col-start-6 col-span-4 col-start-5 pt-4'
              }
            >
              {state.dateVisits.format('DD.MM.YYYY') ===
              dayjs().format('DD.MM.YYYY')
                ? 'Today'
                : state.dateVisits.format('DD.MM.YYYY')}
            </p>

            <button
              className={
                'font-semibold md:col-span-1 md:col-start-8 col-span-1 col-start-9 text-center cursor-pointer block'
              }
              onClick={() => onDayChange('add')}
            >
              <FontAwesomeIcon
                icon={faAngleRight}
                className={'font-semibold'}
              />
            </button>
          </div>
        </div>
      </TopBar>
      <Loader show={loading} />
      <List load={load} hasMore={hasMore} loading={loading}>
        {visits &&
          visits.map((visit) => (
            <SiteVisit
              siteName={visit.site.name}
              siteVisit={visit}
              key={visit.id}
              done={true}
            />
          ))}
        {!visits ||
          (visits && visits.length === 0 && visits.length === 0 && (
            <p className="justify-between mx-2 mb-2 h-18 list-none shadow-md rounded-lg pb-4 px-2 pt-2 bg-white text-center">
              No site visits done on this day.
            </p>
          ))}
      </List>
    </Content>
  );
};

export default SiteVisits;
