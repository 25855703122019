import { useEffect, useState } from 'react';
import { NotificationType } from '../../../../global';
import Content from '../../components/layout/Content';
import { Notification } from '../../components/notifications/Notification';
import Card from '../../components/ui/Card';
import List from '../../components/ui/list/List';
import { useAuth } from '../../lib/hooks/use-auth';
import { useNotifications } from '../../lib/hooks/use-notifiactions';
import { useUser } from '../../lib/hooks/use-user';

const Notifications = () => {
  const [type, setType] = useState<NotificationType>();
  const [read, setRead] = useState<boolean | undefined>(false);
  const [notificationCount, setNotificationCount] = useState<number>(0);
  const { error, hasMore, load, loading, notifications, reset } =
    useNotifications({ type, read });
  const { user } = useUser();

  useEffect(() => {
    if (!loading && user && user.notifications !== notificationCount) {
      reset();
      setNotificationCount(user.notifications);
    }
  }, [user, notificationCount, loading]);

  return (
    <Content>
      <List load={load} hasMore={hasMore} loading={loading}>
        {notifications && notifications.length > 0 ? (
          notifications.map((n) => <Notification {...n} key={n.id} />)
        ) : (
          <p className="justify-between mx-2 mb-2 h-18 list-none shadow-md rounded-lg pb-4 px-2 pt-2 bg-white text-center">
            No Notification.
          </p>
        )}
      </List>
    </Content>
  );
};

export default Notifications;
