import { useState } from 'react';
import { Confirm } from '../forms/Confirm';
import Button from '../ui/Button';
import Modal from '../ui/Modal';

type ConfirmDeliveryProps = {
  open: boolean;
  onConfirm: () => void;
  setOpen: (open: boolean) => void;
};

const ConfirmDelivery = ({
  open,
  onConfirm,
  setOpen,
}: ConfirmDeliveryProps) => {
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [block, setBlock] = useState<boolean>(false);

  const handleOnConfirm = () => {
    setBlock(false);
    setShowConfirmation(false);
    onConfirm();
  };

  const handleOnCancel = () => {
    setBlock(false);
    setShowConfirmation(false);
  };

  return (
    <Modal open={open} setOpen={setOpen} block={block}>
      <Confirm
        title="Are you sure you want to confirm the delivery?"
        open={showConfirmation}
        onConfirm={handleOnConfirm}
        onCancel={handleOnCancel}
        setOpen={(open) => {
          setShowConfirmation(open);
          setBlock(open);
        }}
      />
      <Button
        text="Accept"
        color="green"
        className="w-full mt-10"
        onClick={() => {
          setBlock(true);
          setShowConfirmation(true);
        }}
      />
    </Modal>
  );
};

export default ConfirmDelivery;
