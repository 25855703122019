import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faTruck } from '@fortawesome/free-solid-svg-icons';
import { faEye, faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { useNavigate } from 'react-router-dom';
import ListItem from '../ui/list/ListItem';
import { FSATarget } from '../../../../global';

type TargetsProps = {
  target: FSATarget;
  type: string;
};

const Target = ({ target, type }: TargetsProps) => {
  const navigate = useNavigate();
  return (
    <ListItem onClick={() => navigate(`/targets/${target.id}`)}>
      <div className="self-center col-span-5 text-lh-head-black">
        <FontAwesomeIcon icon={faUserCircle} />{' '}
        {target.fsa
          ? target.fsa.name
          : target.distributor
          ? target.distributor.name
          : ''}
      </div>
      <div className="grid grid-cols-12 col-span-5 h-32">
        <form className="grid grid-cols-12 self-center text-right col-span-12 text-lh-head-black mr-2">
          {type === 'fsa' && (
            <>
              <FontAwesomeIcon icon={faEye} className="col-span-2 mt-2" />
              <p className="col-span-10  mb-1 mt-1 text-lh-text-black text-right pr-2">
                {target.visitTarget ? target.visitTarget : 0}
              </p>
            </>
          )}
          <svg
            id="Icons"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 22 28"
            className="col-span-2 h-6 self-center"
          >
            <defs></defs>
            <path
              className="fill-transparent	stroke-black stroke-2"
              d="M6,16a31.62,31.62,0,0,0,1.2,9H24.8A35.63,35.63,0,0,0,26,16a31.62,31.62,0,0,0-1.2-9H7.2A31.62,31.62,0,0,0,6,16Z"
              transform="translate(-5 -2)"
            />
            <polygon
              className="fill-transparent	stroke-black stroke-2"
              points="21 27 1 27 2 23 20 23 21 27"
            />
            <polygon
              className="fill-transparent	stroke-black stroke-2"
              points="1 1 21 1 20 5 2 5 1 1"
            />
            <path
              d="M13.68,22a3.77,3.77,0,0,1-1.58-1.63,5.65,5.65,0,0,1-.54-2.58V14.23a5.71,5.71,0,0,1,.54-2.58A3.77,3.77,0,0,1,13.68,10a5.27,5.27,0,0,1,2.5-.56,4.36,4.36,0,0,1,2.15.52,4.13,4.13,0,0,1,1.57,1.51,6.4,6.4,0,0,1,.86,2.38h-2.5a3.3,3.3,0,0,0-.43-1.08,2.08,2.08,0,0,0-.71-.68,1.85,1.85,0,0,0-.94-.24,2.18,2.18,0,0,0-1.15.28,1.78,1.78,0,0,0-.73.8,3.11,3.11,0,0,0-.25,1.28v3.51A3,3,0,0,0,14.3,19a1.8,1.8,0,0,0,.73.81,2.28,2.28,0,0,0,1.15.27,1.88,1.88,0,0,0,.95-.23,1.92,1.92,0,0,0,.71-.68,3.26,3.26,0,0,0,.42-1.09h2.5a6.63,6.63,0,0,1-.87,2.39A4.14,4.14,0,0,1,18.33,22a4.36,4.36,0,0,1-2.15.52A5.27,5.27,0,0,1,13.68,22Z"
              transform="translate(-5 -2)"
            />
          </svg>
          <p className="col-span-10  mb-1 mt-1 text-lh-text-black text-right pr-2">
            {target.cementTarget ? target.cementTarget : 0}
          </p>
          <svg
            id="Icons"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 22 28"
            className="col-span-2 h-6 self-center"
          >
            <defs></defs>
            <path
              className="fill-transparent	stroke-black stroke-2"
              d="M6,16a31.62,31.62,0,0,0,1.2,9H24.8A35.63,35.63,0,0,0,26,16a31.62,31.62,0,0,0-1.2-9H7.2A31.62,31.62,0,0,0,6,16Z"
              transform="translate(-5 -2)"
            />
            <polygon
              className="fill-transparent	stroke-black stroke-2"
              points="21 27 1 27 2 23 20 23 21 27"
            />
            <polygon
              className="fill-transparent	stroke-black stroke-2"
              points="1 1 21 1 20 5 2 5 1 1"
            />
            <path
              d="M18.45,9.59h3.17V22.37H19.43V12.45l.09.56L17.08,21H15l-2.44-7.76.08-.74v9.92H10.41V9.59h3.17L16,18.21Z"
              transform="translate(-5 -2)"
            />
          </svg>
          <p className="col-span-10 mb-1 mt-1 text-lh-text-black text-right pr-2">
            {target.mortarTarget ? target.mortarTarget : 0}
          </p>
        </form>
      </div>
    </ListItem>
  );
};

export default Target;
