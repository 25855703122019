import { RecaptchaVerifier } from 'firebase/auth';
import { useRef, useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import Alert from '../../components/ui/Alert';
import Card from '../../components/ui/Card';
import { useSignInWithPhone } from '../../lib/auth/use-signInWithPhone';
import { auth } from '../../lib/utils/firebase';
import { useAuth } from '../../lib/hooks/use-auth';
import { RegisterOptions, useForm } from 'react-hook-form';
import Input from '../../components/forms/Input';
import Submit from '../../components/forms/Submit';
import Loader from '../../components/ui/Loader';
import Back from '../../components/ui/Back';
import { asyncDebounce, debounce } from '../../lib/utils/debounce';
import { DevTool } from '@hookform/devtools';

type SendCodeFormValues = {
  phone: string;
};

type VerifyCodeFormValues = {
  code: string;
};

const PhoneSignIn = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [signInWithPhone, verifyCode, loading, error] =
    useSignInWithPhone(auth);
  const [recaptcha, setRecaptcha] = useState(null);
  const element = useRef(null);
  const verificationCodeTextInput = useRef(null);
  const [sentCode, setSentCode] = useState<boolean>(false);
  const [isVerifying, setIsVerifying] = useState<boolean>(false);

  const {
    register: sendCodeRegister,
    handleSubmit: sendCodeHandleSubmit,
    formState: {
      errors: sendCodeErrors,
      isValid: sendCodeIsValid,
      isValidating,
    },
    control,
  } = useForm<SendCodeFormValues>();
  const {
    register: verifyCodeRegister,
    handleSubmit: verifyCodeHandleSubmit,
    formState: {
      isSubmitSuccessful: verifyCodeIsSubmitSuccessful,
      errors: verifyCodeErrors,
      isValid: verifyCodeValid,
    },
  } = useForm<VerifyCodeFormValues>();

  const sendCodeOptions: RegisterOptions = {
    required: 'Please enter a phone number!',
    pattern: {
      value: /^\+[0-9]{11,13}$/,
      message: 'Please enter a valid phone number!',
    },
  };

  const verifyCodeOptions: RegisterOptions = {
    required: 'Please enter the code that was sent to you!',
    minLength: 6,
    maxLength: 6,
  };

  const [showAlert, setShowAlert] = useState<boolean>(false);

  useEffect(() => {
    if (error) {
      setShowAlert(true);
    } else if (showAlert) {
      setShowAlert(false);
    }
    return () => {
      setShowAlert(false);
    };
  }, [error]);

  const getRecaptcha = () => {
    if (!recaptcha) {
      const verifier = new RecaptchaVerifier(
        element.current,
        {
          size: 'invisible',
        },
        auth
      );
      setIsVerifying(true);
      verifier.verify().then(() => {
        setRecaptcha(verifier);
        setIsVerifying(false);
      });
    }
  };

  useEffect(() => {
    getRecaptcha();
    auth.useDeviceLanguage();
  }, []);

  useEffect(() => {
    if (user !== null) navigate('/', { replace: true });
  }, [user, navigate]);

  useEffect(() => {
    console.log('isValidating:', isValidating);
  }, [isValidating]);

  const handleSendCode = async (data: SendCodeFormValues) => {
    await signInWithPhone(data.phone, recaptcha);
    setSentCode(true);
    if (error && error.code === 'auth/argument-error') {
      getRecaptcha();
      return;
    }
    verificationCodeTextInput.current?.focus();
  };

  const handleVerifyCode = async (data: VerifyCodeFormValues) => {
    await verifyCode(data.code);
  };

  return (
    <>
      <Loader show={loading || isVerifying} />
      <Card className="pt-24 px-4">
        <div className="flex justify-between">
          <Back to="/enter " />
        </div>
        <div id="recaptcha-container" ref={element} />
        <Alert
          message={error && error.message}
          open={showAlert}
          setOpen={(open) => setShowAlert(open)}
          title="Error"
        />
        <form
          className="flex justify-center space-y-4 flex-col w-full"
          name="sendCodeForm"
          onSubmit={sendCodeHandleSubmit(handleSendCode)}
        >
          <Input
            type="tel"
            placeholder="+234XXXXXXXXXX"
            name="phone"
            register={sendCodeRegister}
            error={sendCodeErrors.phone}
            options={sendCodeOptions}
            disabled={sentCode}
          ></Input>
          {!sentCode && <Submit value="Send code" color="gray" />}
        </form>
        {sentCode && (
          <form
            className="flex justify-center space-y-4 flex-col w-full"
            name="verifyCodeForm"
            onSubmit={verifyCodeHandleSubmit(handleVerifyCode)}
          >
            <Input
              type="number"
              placeholder="000000"
              name="code"
              register={verifyCodeRegister}
              error={verifyCodeErrors.code}
              options={verifyCodeOptions}
            ></Input>
            <Submit value="Verify Code" color="gray" />
          </form>
        )}
        <DevTool control={control} />
      </Card>
    </>
  );
};

export default PhoneSignIn;
