import dayjs from 'dayjs';
import { FirebaseError } from 'firebase/app';
import {
  collection,
  doc,
  FirestoreError,
  onSnapshot,
  orderBy,
  query,
  Query,
  Timestamp,
  where,
} from 'firebase/firestore';
import { useEffect, useMemo, useState } from 'react';
import {
  Associate,
  CreateVisitData,
  RetailerVisit,
  Route,
  RoutePlan,
  Visit,
} from '../../../../global';
import { addVisit } from '../db/visits';
import { firestore, docToJSON } from '../utils/firebase';
import { usePagination } from '../utils/usePagination';
import { useAuth } from './use-auth';

type VisitsHook = {
  error: FirebaseError;
  hasMore: boolean;
  load: () => void;
  loading: boolean;
  reset: () => void;
  visits: RetailerVisit[];
};

type VisitsHookOptions = {
  month: number;
  year: number;
  day: number;
};

export const useVisits = ({
  month,
  year,
  day,
}: VisitsHookOptions): VisitsHook => {
  const { id } = useAuth();
  const [visitsQuery, setVisitsQuery] = useState<Query>();
  const { error, hasMore, load, loading, reset, values } = usePagination(
    visitsQuery,
    0
  );

  useEffect(() => {
    if (id) {
      setVisitsQuery(
        query(
          collection(firestore, 'visits'),
          where('createdBy.id', '==', id),
          where('day', '==', day),
          where('month', '==', month),
          where('year', '==', year)
        )
      );
    } else if (visitsQuery) {
      setVisitsQuery(undefined);
    }
  }, [id, month, year, day]);

  useEffect(() => {
    console.log(error);
  }, [error]);

  return useMemo(
    () => ({
      error,
      hasMore,
      load,
      loading,
      reset,
      visits: values
        ? values.map((v) => docToJSON(v) as RetailerVisit)
        : values,
    }),
    [error, hasMore, load, loading, reset, values]
  );
};

export const useVisit = (id?: string) => {
  const [loading, setLoading] = useState(false);
  const [visit, setVisit] = useState<RetailerVisit | undefined>();
  const [error, setError] = useState<FirestoreError>();

  useEffect(() => {
    let unsubscribe;
    if (id) {
      setLoading(true);
      const ref = doc(firestore, 'visits', id);
      unsubscribe = onSnapshot(
        ref,
        (doc) => {
          if (doc.exists()) {
            setVisit(docToJSON(doc) as RetailerVisit);
          } else {
            setVisit(undefined);
          }
          setLoading(false);
        },
        (error: FirestoreError) => {
          setLoading(false);
          setError(error);
        }
      );
    } else {
      setVisit(undefined);
    }
    return unsubscribe;
  }, [id]);

  /*
  const update = async (data: any) => {
    if (id) {
      try {
        setLoading(true);
        await firestore.collection('visits').doc(id).update(data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error(err);
        setError(err.message);
      }
    }
  };
*/

  const add = async (data: CreateVisitData) => {
    setLoading(true);
    await addVisit(data);
    setLoading(false);
  };

  useEffect(() => {
    console.log(error);
  }, [error]);

  return {
    visit,
    error,
    loading,
    //update,
    add,
  };
};

const prepareVisit = (visit: Visit): Visit => {
  return {
    ...visit,
  };
};
