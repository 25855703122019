import { Link } from 'react-router-dom';

type NewProps = {
  to: string;
};

const New = ({ to }: NewProps) => {
  return (
    <Link
      to={to}
      className={
        'rounded-full h-20 w-20 flex items-center justify-center bg-green-500 text-white fixed text-5xl bottom-5 left-1/2 -ml-10 shadow-md drop-shadow-md'
      }
    >
      +
    </Link>
  );
};

export default New;
