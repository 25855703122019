import { useLeads } from '../../lib/hooks/use-leads';
import Loader from '../../components/ui/Loader';
import SearchAndSort from '../../components/ui/SearchAndSort';
import React, { useEffect, useState } from 'react';
import useStore from '../../lib/hooks/use-store';
import Content from '../../components/layout/Content';
import TopBar from '../../components/layout/TopBar';
import Reload from '../../components/ui/Reload';
import Lead from '../../components/leads/Lead';
import List from '../../components/ui/list/List';
import Alert from '../../components/ui/Alert';
import { useCheckAccess } from '../../lib/auth/use-checkAccess';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDoubleDown,
  faAngleDoubleUp,
} from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../lib/hooks/use-auth';

const Leads = React.memo(() => {
  const checkAccess = useCheckAccess();
  const roles = useAuth();
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc');
  const [state, dispatch] = useStore();
  const { error, leads, loading, load, hasMore, reset } = useLeads({
    status: state.statusLead,
    direction,
    archiveStatus: state.archiveStatusLead,
  });
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (error) {
      setShowAlert(true);
    } else if (showAlert) {
      setShowAlert(false);
    }
    return () => {
      setShowAlert(false);
    };
  }, [error]);

  useEffect(() => {
    if (roles && checkAccess(['distributor'])) {
      dispatch('SET_STATUS_LEAD', ['assigned']);
    }
  }, [roles]);

  useEffect(() => {
    if (
      state.statusLead.includes('fulfilled') ||
      state.statusLead.includes('rejected') ||
      state.statusLead.includes('canceled')
    ) {
      setDirection('desc');
    } else {
      setDirection('asc');
    }
  }, [state.statusLead]);

  const onCategoryChange = (event) => {
    if (event.target.value === 'all') {
      dispatch('SET_ARCHIVE_STATUS_LEAD', 'all');
    } else {
      dispatch('SET_ARCHIVE_STATUS_LEAD', event.target.value);
    }
  };

  const handleStatusChange = (newStatus) => () => {
    dispatch('SET_STATUS_LEAD', newStatus);
  };

  const handleDirectionChange = () => {
    if (direction === 'asc') {
      setDirection('desc');
    } else {
      setDirection('asc');
    }
  };

  return (
    <Content topBar>
      <Reload onClick={reset} blocked={loading} />
      <button
        onClick={handleDirectionChange}
        className="rounded-full flex justify-center items-center h-8 w-8 fixed top-5 right-20 text-xl z-30"
      >
        <FontAwesomeIcon
          icon={direction === 'asc' ? faAngleDoubleUp : faAngleDoubleDown}
        />
      </button>
      <Loader show={loading && leads.length === 0} />
      <Alert
        message={error && error.message}
        open={showAlert}
        setOpen={(open) => setShowAlert(open)}
        title="Error"
      />
      <TopBar>
        {!checkAccess(['distributor']) && (
          <div
            className={
              'flex justify-center content-center flex-col text-center flex-grow cursor-pointer text-lh-dark-blue text-xl font-medium border-b-2 border-lh-dark-blue h-full' +
              (state.statusLead.includes('pending')
                ? ' border-b-4 border-lh-dark-blue font-semibold'
                : '')
            }
            onClick={handleStatusChange(['pending'])}
          >
            Pending
          </div>
        )}
        <div
          className={
            'flex justify-center content-center flex-col text-center flex-grow cursor-pointer text-lh-dark-blue text-xl font-medium border-b-2 border-lh-dark-blue h-full' +
            (state.statusLead.includes('accepted') ||
            state.statusLead.includes('assigned')
              ? ' border-b-4 border-lh-dark-blue font-semibold'
              : '')
          }
          onClick={
            !checkAccess(['distributor'])
              ? handleStatusChange(['accepted', 'assigned'])
              : handleStatusChange(['assigned'])
          }
        >
          {!checkAccess(['distributor']) ? 'Accepted / Assigned' : 'Assigned'}
        </div>
        <div
          className={
            'flex justify-center content-center flex-col text-center flex-grow cursor-pointer text-lh-dark-blue text-xl font-medium border-b-2 border-lh-dark-blue h-full' +
            (state.statusLead.includes('fulfilled') ||
            state.statusLead.includes('rejected') ||
            state.statusLead.includes('canceled')
              ? ' border-b-4 border-lh-dark-blue font-semibold'
              : '')
          }
          onClick={handleStatusChange(['fulfilled', 'rejected', 'canceled'])}
        >
          Archive
        </div>
      </TopBar>
      {(state.statusLead.includes('rejected') ||
        state.statusLead.includes('fulfilled') ||
        state.statusLead.includes('canceled')) && (
        <SearchAndSort
          onCategoryChange={onCategoryChange}
          selectedCategory={state.archiveStatusLead}
          categories={{
            all: 'All',
            distributor: 'Distributor',
            outlet: 'Outlet',
          }}
          displayCategory={
            state.statusLead.includes('rejected') ||
            state.statusLead.includes('fulfilled') ||
            state.statusLead.includes('canceled')
              ? true
              : false
          }
          className="mt-4"
        />
      )}
      <List load={load} hasMore={hasMore} loading={loading}>
        {leads && leads.length > 0 ? (
          leads.map((lead) => <Lead lead={lead} key={lead.id} />)
        ) : (
          <p className="justify-between mx-2 mb-2 h-18 list-none shadow-md rounded-lg pb-4 px-2 pt-2 bg-white text-center">
            No {state.statusLead.join(' / ')} leads.
          </p>
        )}
      </List>
    </Content>
  );
});

export default Leads;
