import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../utils/firebase';

export const confirmDelivery = (id: string) => {
  const ref = doc(firestore, `deliveries/${id}`);
  return updateDoc(ref, { status: 'confirmed' });
};

export const rejectDelivery = (id: string, comment: string) => {
  const ref = doc(firestore, `deliveries/${id}`);
  return updateDoc(ref, { status: 'rejected', rejectionComment: comment });
};
