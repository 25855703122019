import dayjs from 'dayjs';
import { FirebaseError } from 'firebase/app';
import {
  collection,
  doc,
  FirestoreError,
  onSnapshot,
  orderBy,
  Query,
  query,
  where,
} from 'firebase/firestore';
import { useEffect, useMemo, useState } from 'react';
import {
  OrderStatus,
  UpdateOrderData,
  CreateSaleData,
  Sale,
} from '../../../../global';
import { createSale, updateSale } from '../db/sales';
import { firestore, docToJSON } from '../utils/firebase';
import { usePagination } from '../utils/usePagination';
import { useAuth } from './use-auth';

type SalesHook = {
  error: FirebaseError;
  hasMore: boolean;
  load: () => void;
  loading: boolean;
  reset: () => void;
  sales: Sale[];
};

type SalesHookOptions = {
  status: OrderStatus;
  pageSize?: number;
  direction?: 'asc' | 'desc';
};

export const useSales = ({
  status,
  pageSize = 10,
  direction = 'asc',
}: SalesHookOptions): SalesHook => {
  const { id } = useAuth();
  const [salesQuery, setSalesQuery] = useState<Query>();
  const { error, hasMore, load, loading, reset, values } = usePagination(
    salesQuery,
    pageSize
  );

  useEffect(() => {
    if (id) {
      setSalesQuery(
        query(
          collection(firestore, 'sales'),
          where('userIds', 'array-contains', id),
          where('status', '==', status),
          orderBy('createdAt', direction)
        )
      );
    } else if (salesQuery) {
      setSalesQuery(undefined);
    }
  }, [id, status, direction]);

  useEffect(() => {
    console.log(error);
  }, [error]);

  return useMemo(
    () => ({
      error,
      hasMore,
      load,
      loading,
      reset,
      sales: values
        ? values.map((v) => prepareSale(docToJSON(v) as Sale))
        : values,
    }),
    [error, hasMore, load, loading, reset, values]
  );
};

export const useSale = (id?: string) => {
  const [loading, setLoading] = useState(false);
  const [sale, setSale] = useState<Sale | undefined>();
  const [error, setError] = useState<FirestoreError>();

  useEffect(() => {
    let unsubscribe;
    if (id) {
      setLoading(true);
      const ref = doc(firestore, 'sales', id);
      unsubscribe = onSnapshot(
        ref,
        (doc) => {
          if (doc.exists()) {
            setSale(prepareSale(docToJSON(doc) as Sale));
          } else {
            setSale(undefined);
          }
          setLoading(false);
        },
        (error: FirestoreError) => {
          setLoading(false);
          setError(error);
        }
      );
    }
    return unsubscribe;
  }, [id]);

  const create = async (data: CreateSaleData) => {
    try {
      setLoading(true);
      await createSale(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
      setError(err);
    }
  };

  const update = async (data: UpdateOrderData) => {
    if (!id) throw new Error('No sale selected');
    try {
      setLoading(true);
      await updateSale(id, data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
      setError(err);
    }
  };

  useEffect(() => {
    console.log(error);
  }, [error]);

  return {
    sale,
    error,
    loading,
    create,
    update,
  };
};

const prepareSale = (sale: Sale): Sale => {
  let totalOrdered = 0;
  Object.keys(sale.products).forEach(
    (key) =>
      (totalOrdered += !isNaN(+sale.products[key]) ? +sale.products[key] : 0)
  );
  let totalDelivered = 0;
  if (sale.productsDelivered)
    Object.keys(sale.productsDelivered).forEach(
      (key) =>
        (totalDelivered += !isNaN(+sale.productsDelivered[key])
          ? +sale.productsDelivered[key]
          : 0)
    );
  let color = '';
  if (sale.status === 'pending' || sale.status === 'accepted') {
    const timeDiff = dayjs().diff(sale.createdAt, 'hours');
    if (sale.status === 'pending' && timeDiff > 12) {
      color = 'red';
    } else if (sale.status === 'accepted' && timeDiff > 48) {
      color = 'red';
    }
  }

  let name = '';

  if (sale.buyer) {
    name = sale.buyer.name;
  } else if (sale.outlet) {
    name = sale.outlet.name;
  }

  return {
    ...sale,
    totalOrdered,
    totalDelivered,
    color,
    name,
  };
};
