import { faMapMarked } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Status, Wrapper } from '@googlemaps/react-wrapper';
import { useState } from 'react';
import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Button from '../../../components/ui/Button';
import { useVisit } from '../../../lib/hooks/use-visits';
import Map from '../../../components/map/Map';
import Marker from '../../../components/map/Marker';
import { useLocation } from '../../../lib/hooks/use-location';
import Alert from '../../../components/ui/Alert';

type VisitParams = 'id';

//Months
const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const render = (status: Status) => {
  return <h1>{status}</h1>;
};

const SiteVisitStep0 = (props) => {
  const { id } = useParams<VisitParams>();
  const navigate = useNavigate();
  const { visit, error, loading } = useVisit(id);

  useEffect(() => {}, []);

  const d = new Date();
  const [month, setMonth] = useState(d.getMonth());
  const [year, setYear] = useState(d.getFullYear());
  const [zoom, setZoom] = useState(13); // initial zoom
  const [center, setCenter] = useState<google.maps.LatLngLiteral>({
    lat: 0,
    lng: 0,
  });
  const [marker, setMarker] = useState<google.maps.LatLngLiteral>();

  const onIdle = (m: google.maps.Map) => {
    setZoom(m.getZoom()!);
    setCenter(m.getCenter()!.toJSON());
  };

  //TODO we can work with real dates here and add days !! same in targets view
  const onMonthChange = (direction) => {
    if (direction === 'add') {
      if (month === 11) {
        setMonth(0);
        setYear(year + 1);
      } else {
        setMonth(month + 1);
      }
    } else {
      if (month === 0) {
        setMonth(11);
        setYear(year - 1);
      } else {
        setMonth(month - 1);
      }
    }
  };

  useEffect(() => {
    if (props.site && props.site?.location) {
      setCenter({
        lat: props.site?.location.lat,
        lng: props.site?.location.lng,
      });
      setMarker({
        lat: props.site?.location.lat,
        lng: props.site?.location.lng,
      });
    }
  }, [props.site]);

  if (props.currentStep !== 0) return null;
  return (
    <>
      <div className="grid grid-cols-9 col-span-9 w-full ">
        <div className="grid grid-cols-9 col-span-9 content-center ">
          <hr className={'col-span-9'} />
          <p className={'col-span-4 pl-4 text-left mt-4 mb-2 font-semibold'}>
            Leads Status
          </p>
          <p className={'col-span-2 pl-4 text-center mt-4 mb-2 font-semibold'}>
            Count
          </p>
          <p className={'col-span-3 pl-4 text-center mt-4 mb-2 font-semibold'}>
            Volume
          </p>
          <div className={'grid grid-cols-9 col-span-9'}>
            <p className={'col-span-4 pl-4 text-left mb-2'}>Leads pending:</p>
            <p className={'col-span-2 pl-4 font-bold text-center mb-2'}>
              {props.site && props.site.leadsAmountPending}
            </p>
            <p className={'col-span-3 pl-4 font-bold text-center mb-2'}>
              {props.site && props.site.pendingVolume
                ? props.site.pendingVolume
                : 0}
            </p>
            <p className={'col-span-4 pl-4 text-left mb-2'}>Leads accepted:</p>
            <p className={'col-span-2 pl-4 font-bold text-center mb-2'}>
              {props.site && props.site.leadsAmountAccepted}
            </p>
            <p className={'col-span-3 pl-4 font-bold text-center mb-2'}>
              {props.site && props.site.acceptedVolume
                ? props.site.acceptedVolume
                : 0}
            </p>
            <p className={'col-span-4 pl-4 text-left mb-2'}>
              Orders fulfilled:
            </p>
            <p className={'col-span-2 pl-4 font-bold text-center mb-2'}>
              {props.site && props.site.leadsAmountFulfilled}
            </p>
            <p className={'col-span-3 pl-4 font-bold text-center mb-2'}>
              {props.site && props.site.fulfilledVolume
                ? props.site.fulfilledVolume
                : 0}
            </p>
          </div>
          <hr className={'col-span-9'} />
          <p className={'col-span-9 pl-4 text-left mt-2 mb-2 font-semibold'}>
            Location
          </p>
          {props.site?.location && (
            <p className="col-span-9 pl-4 text-left mb-2">
              <FontAwesomeIcon icon={faMapMarked} />{' '}
              <a
                href={`geo:${props?.site?.location?.lat},${
                  props?.site?.location?.lng
                }?q=${props?.site?.location?.lat},${
                  props?.site?.location?.lng
                }(${props.site && props.site.name})`}
              >
                Open in Google Maps
              </a>
            </p>
          )}
          {props.site?.location && (
            <div className="col-span-full h-56 flex mt-4">
              <Wrapper
                apiKey={process.env.REACT_APP_MAPS_API_KEY}
                render={render}
              >
                <Map
                  center={center}
                  onIdle={onIdle}
                  zoom={zoom}
                  className="flex-grow h-full rounded-lg"
                  disableDefaultUI
                >
                  ={marker && <Marker position={marker} />}
                </Map>
              </Wrapper>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SiteVisitStep0;
