import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router';
import { Associate } from '../../../../global';
import { useCheckAccess } from '../../lib/auth/use-checkAccess';
import clsx from 'clsx';

type RetailerProps = {
  retailer: Associate;
};

const Retailer = ({ retailer }: RetailerProps) => {
  const navigate = useNavigate();
  const checkAccess = useCheckAccess();

  return (
    <li
      className={
        'flex justify-between mx-2 mb-2 h-18 list-none shadow-md rounded-lg pb-4 px-2 pt-2 cursor-pointer bretailer-lh-red bg-white ' +
        clsx(
          (checkAccess(['sop', 'fsa']) &&
            retailer.status === 'unconfirmed' &&
            'border-2 border-yellow-500') ||
            (checkAccess(['sop', 'fsa']) &&
              retailer.status === 'changes' &&
              'border-2 border-yellow-500') ||
            (checkAccess(['sop', 'fsa']) &&
              retailer.outletChanged &&
              'border-2 border-yellow-500') ||
            ('hasWhatsApp' in retailer &&
              !retailer.hasWhatsApp &&
              'border-2 border-red-500')
        )
      }
      onClick={() => {
        navigate(`/retailers/${retailer.id}`);
      }}
    >
      <div className="grid grid-cols-12 w-full">
        <div className="col-span-6">
          <p className="text-lh-head-black align-middle col-span-4 font-bold">
            <FontAwesomeIcon
              icon={faUserCircle}
              className="text-lh-head-black"
            />
            {` ${retailer.name}`}
          </p>
          <p className="mt-1 text-lh-head-black">{` ${retailer.phone}`}</p>
        </div>
        <p className="col-span-5 text-right self-center mr-2 text-lh-head-black font-semibold">
          {(retailer.outletChanged &&
            checkAccess(['sop']) &&
            'Action required!') ||
            (retailer.status !== 'confirmed' &&
              retailer.status !== 'changesRejected' &&
              retailer.status !== 'rejected' &&
              checkAccess(['sop']) &&
              'Action required!')}
          {(retailer.outletChanged &&
            checkAccess(['fsa']) &&
            'Waiting for approval!') ||
            (retailer.status !== 'confirmed' &&
              retailer.status !== 'changesRejected' &&
              checkAccess(['fsa']) &&
              'Waiting for approval!')}
          {'hasWhatsApp' in retailer &&
            !retailer.hasWhatsApp &&
            retailer.status === 'confirmed' &&
            checkAccess(['fsa', 'sop']) &&
            'No WhatsApp!'}
        </p>
        <p className="col-span-1 text-right self-center  text-lh-head-black font-bold mr-4">
          <FontAwesomeIcon
            icon={faAngleRight}
            className="text-lh-head-black font-bold align-middle"
          />
        </p>
      </div>
    </li>
  );
};

export default Retailer;
