import { useEffect, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { RouteOutlet } from '../../../../global';
import { useOutlets } from '../../lib/hooks/use-outlets';
import { Confirm } from '../forms/Confirm';
import MutliSelect from 'react-select';
import Alert from '../ui/Alert';
import Button from '../ui/Button';
import Loader from '../ui/Loader';
import Modal from '../ui/Modal';

type SelectOutletForm = {
  outletId: string;
  name: string;
  retailerId: string;
};

type SelectOutletProps = {
  open: boolean;
  //onReject: SubmitHandler<SelectOutletForm>;
  setOpen: (boolean) => void;
  onAddOutlet: SubmitHandler<SelectOutletForm[]>;
  confirmText: string;
  buttonText: string;
  routeOutlets?: RouteOutlet[];
};

export type Option = {
  key: string | number;
  label: React.ReactNode;
  value: string | number | string[] | null | Object;
};

export type SearchableSeletProps = {
  dropdownName: string;
  items: Option[];
  className?: string;
  placeholder?: string;
  isOpen: boolean;
  toggleOpen: (open: boolean) => void;
  buttonName: string;
  value: Option;
  updateValue: (value: Option) => void;
};

type SelectOption = { value: string; label: string };

const SelectOutlet = ({
  open,
  //onReject,
  setOpen,
  onAddOutlet,
  confirmText,
  buttonText,
  routeOutlets,
}: SelectOutletProps) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [block, setBlock] = useState(false);
  const [outletSelected, setOutletSelected] = useState<any>(null);
  const [showOutletsAlert, setShowOutletsAlert] = useState(false);

  const {
    error: outletsError,
    loading: outletsLoading,
    outlets,
  } = useOutlets({
    active: true,
    pageSize: 0,
  });

  useEffect(() => {
    if (outletsError) {
      setShowOutletsAlert(true);
    } else if (showOutletsAlert) {
      setShowOutletsAlert(false);
    }
    return () => {
      setShowOutletsAlert(false);
    };
  }, [outletsError]);

  const handleOnConfirm = () => {
    setBlock(false);
    setShowConfirmation(false);
    let outletList = [];
    for (const outletOption of outletSelected) {
      let outlet = outlets.find((d) => d.id === outletOption.value);
      outletList.push({
        outletId: outlet.id,
        name: outlet.name,
        retailerId: outlet.retailer.id,
      });
    }

    onAddOutlet(outletList);

    setOutletSelected(null);
  };

  const handleOnCancel = () => {
    setBlock(false);
    setShowConfirmation(false);
  };

  return (
    <Modal open={open} setOpen={setOpen} block={block}>
      <Loader show={outletsLoading} />
      <Alert
        message={outletsError && outletsError.message}
        open={showOutletsAlert}
        setOpen={(open) => setShowOutletsAlert(open)}
        title="Error"
      />
      <Confirm
        title={confirmText}
        open={showConfirmation}
        onConfirm={handleOnConfirm}
        onCancel={handleOnCancel}
        setOpen={(open) => {
          setShowConfirmation(open);
          setBlock(open);
        }}
      />
      <form name="selectOutletForm" className="flex flex-col">
        <MutliSelect
          options={
            outlets
              ? outlets
                  .filter(
                    (el1) => !routeOutlets.find((el2) => el1.id === el2.id)
                  )
                  .map((outlet) => ({ value: outlet.id, label: outlet.name }))
              : []
          }
          placeholder={'Select Outlets...'}
          isMulti={true}
          onChange={setOutletSelected}
          value={outletSelected}
          defaultValue={
            outlets &&
            outlets
              .filter((el1) => !routeOutlets.find((el2) => el1.id === el2.id))
              .map((outlet) => ({ value: outlet.id, lable: outlet.name }))
              .filter((option) => outletSelected?.value === option.value)
          }
        />
      </form>
      <Button
        text={buttonText}
        color="green"
        className="w-full"
        onClick={() => {
          setBlock(true);
          setShowConfirmation(true);
        }}
      />
    </Modal>
  );
};

export default SelectOutlet;
