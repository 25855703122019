import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faComment } from '@fortawesome/free-regular-svg-icons';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router';
import { Order as OrderType, Sale as SaleTyp } from '../../../../global';

type SaleProps = {
  sale: SaleTyp;
};

const Sale = ({ sale }: SaleProps) => {
  const navigate = useNavigate();
  return (
    <li
      className={
        'flex justify-between mx-2 mb-2 h-18 list-none shadow-md rounded-lg pb-4 px-2 pt-2 cursor-pointer border-lh-red bg-white ' +
        ((sale.status === 'pending' && sale.color === 'red') ||
        (sale.status === 'accepted' && sale.color === 'red')
          ? 'border-2'
          : 'border-0')
      }
      onClick={() => {
        navigate(`/sales/${sale.id}`);
      }}
    >
      <div className="grid grid-cols-10 w-full">
        <div className="col-span-6">
          <p className="text-lh-head-black align-middle col-span-4 font-medium">
            <FontAwesomeIcon
              icon={sale.createdOn === 'chatbot' ? faComment : faUserCircle}
              className="text-lh-head-black"
            />{' '}
            {sale.name}
          </p>
          <p className="text-sm text-lh-text-black">
            {dayjs(sale.createdAt).format('DD.MM.YYYY HH:mm')}
          </p>
        </div>
        <p className="col-span-3 text-right self-center mt-1 text-lh-head-black font-semibold">
          {(sale.status === 'accepted' && sale.totalDelivered > 0) ||
          sale.status === 'fulfilled'
            ? sale.totalDelivered + ' / ' + sale.totalOrdered
            : sale.totalOrdered}{' '}
          <span className="text-gray-500 font-light">bags</span>
        </p>
        <p className="col-span-1 text-right self-center mt-1 text-lh-head-black font-bold mr-4">
          <FontAwesomeIcon
            icon={faAngleRight}
            className="text-lh-head-black font-bold align-middle"
          />
        </p>
      </div>
    </li>
  );
};

export default Sale;
