import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router';
import { Site as SiteType } from '../../../../global';
import { useCheckAccess } from '../../lib/auth/use-checkAccess';
import clsx from 'clsx';

type SiteProps = {
  site: SiteType;
};

const Site = ({ site }: SiteProps) => {
  const navigate = useNavigate();
  const checkAccess = useCheckAccess();

  return (
    <li
      className={
        'flex justify-between mx-2 mb-2 h-18 list-none shadow-md rounded-lg pb-4 px-2 pt-2 cursor-pointer bsite-lh-red bg-white ' +
        clsx(
          (checkAccess(['sop', 'fsa']) &&
            site.status === 'unconfirmed' &&
            'border-2 border-yellow-500') ||
            (checkAccess(['sop', 'fsa']) &&
              site.status === 'changes' &&
              'border-2 border-yellow-500') ||
            (checkAccess(['sop', 'fsa']) &&
              site.siteChanged &&
              'border-2 border-yellow-500')
        )
      }
      onClick={() => {
        navigate(`/siteOwners/${site.siteOwner.id}`);
      }}
    >
      <div className="grid grid-cols-12 w-full">
        <div className="col-span-6">
          <p className="text-lh-head-black align-middle col-span-4 font-bold">
            <FontAwesomeIcon
              icon={faUserCircle}
              className="text-lh-head-black"
            />
            {` ${site.name}`}
          </p>
          <p className="mt-1 text-lh-head-black">{` ${site.siteOwner.phone}`}</p>
        </div>
        <p className="col-span-5 text-right self-center mr-2 text-lh-head-black font-semibold">
          {(site.siteChanged && checkAccess(['sop']) && 'Action required!') ||
            (site.status !== 'confirmed' &&
              site.status !== 'changesRejected' &&
              site.status !== 'rejected' &&
              checkAccess(['sop']) &&
              'Action required!')}
          {(site.siteChanged &&
            checkAccess(['fsa']) &&
            'Waiting for approval!') ||
            (site.status !== 'confirmed' &&
              site.status !== 'changesRejected' &&
              checkAccess(['fsa']) &&
              'Waiting for approval!')}
        </p>
        <p className="col-span-1 text-right self-center  text-lh-head-black font-bold mr-4">
          <FontAwesomeIcon
            icon={faAngleRight}
            className="text-lh-head-black font-bold align-middle"
          />
        </p>
      </div>
    </li>
  );
};

export default Site;
