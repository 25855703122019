import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Layout from './components/layout/Layout';
import Protected from './components/routing/Protected';
import { AuthProvider } from './lib/context/auth.context';
import Home from './pages/home';
import Enter from './pages/enter';
import GoogleSignIn from './pages/enter/google';
import PhoneSignIn from './pages/enter/phone';
import Orders from './pages/orders';
import NewOrder from './pages/orders/new';
import Order from './pages/orders/[orderId]';
import EditOrder from './pages/orders/[orderId]/edit';
import Retailers from './pages/retailers';
import Retailer from './pages/retailers/[retailerId]';
import Targets from './pages/targets';
import Target from './pages/targets/[targetId]';
import ErrorBoundary from './components/error/ErrorBoundary';
import Profile from './pages/profile';
import Denied from './pages/denied';
import Inventories from './pages/inventory';
import RoutePage from './pages/routes/[routeId]';
import RoutesPage from './pages/routes';
import Visits from './pages/visits';
import Visit from './pages/visits/[visitsId]';
import RoutesPlanningPage from './pages/route_planning';
import NewRoute from './pages/routes/new';
import Users from './pages/users';
import { User } from './pages/users/[userId]';
import NewRetailer from './pages/retailers/new';
import Content from './components/layout/Content';
import Card from './components/ui/Card';
import EditRetailer from './pages/retailers/[retailerId]/edit';
import Sales from './pages/sales';
import Sale from './pages/sales/[saleId]';
import EditSale from './pages/sales/[saleId]/edit';
import NewSale from './pages/sales/new';
import { EditOutlet } from './pages/outlets/[outletId]/edit';
import { ToastContainer } from 'react-toastify';
import Notifications from './pages/notifications';
import EditRoutePage from './pages/routes/[routeId]/edit';
import Deliveries from './pages/deliveries';
import Delivery from './pages/deliveries/[delivery]';
import Sites from './pages/siteOwners';
import NewSite from './pages/siteOwners/new';
import SiteVisit from './pages/siteVisits/[visitsId]';
import SiteVisits from './pages/siteVisits';
import Leads from './pages/leads';
import Lead from './pages/leads/[leadId]';
import SiteOwner from './pages/siteOwners/[siteOwnerId]';
import EditSiteOwner from './pages/siteOwners/[siteOwnerId]/edit';
import { EditSite } from './pages/sites/[siteId]/edit';
import Inventory from './pages/inventory/[inventoryId]';

const App = () => {
  return (
    <ErrorBoundary>
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Navigate to="home" />} />
              {/* Enter */}
              <Route path="enter" element={<Enter />} />
              <Route path="enter/google" element={<GoogleSignIn />} />
              <Route path="enter/phone" element={<PhoneSignIn />} />
              {/* Home */}
              <Route
                path="home"
                element={
                  <Protected>
                    <Home />
                  </Protected>
                }
              />
              {/* Orders */}
              <Route
                path="orders"
                element={
                  <Protected roles={['sop', 'fsa', 'distributor']}>
                    <Orders />
                  </Protected>
                }
              />
              <Route
                path="orders/:id"
                element={
                  <Protected roles={['sop', 'fsa', 'distributor']}>
                    <Order />
                  </Protected>
                }
              />
              <Route
                path="orders/:id/edit"
                element={
                  <Protected roles={['sop', 'fsa']}>
                    <EditOrder />
                  </Protected>
                }
              />
              <Route
                path="orders/new"
                element={
                  <Protected roles={['fsa', 'sop']}>
                    <NewOrder />
                  </Protected>
                }
              />
              {/* Sales */}
              <Route
                path="sales"
                element={
                  <Protected roles={['distributor']}>
                    <Sales />
                  </Protected>
                }
              />
              <Route
                path="sales/:id"
                element={
                  <Protected roles={['distributor']}>
                    <Sale />
                  </Protected>
                }
              />
              <Route
                path="sales/:id/edit"
                element={
                  <Protected roles={['distributor']}>
                    <EditSale />
                  </Protected>
                }
              />
              <Route
                path="sales/new"
                element={
                  <Protected roles={['distributor']}>
                    <NewSale />
                  </Protected>
                }
              />
              {/* Retailers */}
              <Route
                path="retailers"
                element={
                  <Protected roles={['sop', 'fsa', 'distributor']}>
                    <Retailers />
                  </Protected>
                }
              />
              <Route
                path="retailers/new"
                element={
                  <Protected roles={['sop', 'fsa']}>
                    <NewRetailer />
                  </Protected>
                }
              />
              <Route
                path="retailers/:id"
                element={
                  <Protected roles={['sop', 'fsa', 'distributor']}>
                    <Retailer />
                  </Protected>
                }
              />
              <Route
                path="retailers/:id/edit"
                element={
                  <Protected roles={['sop', 'fsa']}>
                    <EditRetailer />
                  </Protected>
                }
              />
              <Route
                path="outlets/:id/edit"
                element={
                  <Protected roles={['sop', 'fsa']}>
                    <EditOutlet />
                  </Protected>
                }
              />
              {/* Visits */}
              <Route
                path="visits"
                element={
                  <Protected roles={['fsa']}>
                    <Visits />
                  </Protected>
                }
              />
              <Route
                path="visits/:id"
                element={
                  <Protected roles={['fsa']}>
                    <Visit />
                  </Protected>
                }
              />
              {/* Routes */}
              <Route
                path="routes"
                element={
                  <Protected roles={['sop', 'fsa']}>
                    <RoutesPage />
                  </Protected>
                }
              />
              <Route
                path="routes/:id"
                element={
                  <Protected roles={['sop', 'fsa']}>
                    <RoutePage />
                  </Protected>
                }
              />
              <Route
                path="routes/:id/edit"
                element={
                  <Protected roles={['sop', 'fsa']}>
                    <EditRoutePage />
                  </Protected>
                }
              />
              <Route
                path="routes/new/:id"
                element={
                  <Protected roles={['sop', 'fsa']}>
                    <NewRoute />
                  </Protected>
                }
              />
              {/* Routes planning */}
              <Route
                path="routes-planning"
                element={
                  <Protected roles={['sop']}>
                    <RoutesPlanningPage />
                  </Protected>
                }
              />
              {/* Users */}
              <Route
                path="users"
                element={
                  <Protected roles={['sop', 'fsa']}>
                    <Users />
                  </Protected>
                }
              />
              <Route
                path="users/:id"
                element={
                  <Protected roles={['sop', 'fsa']}>
                    <User />
                  </Protected>
                }
              />
              {/* Targets */}
              <Route
                path="targets"
                element={
                  <Protected roles={['sop']}>
                    <Targets />
                  </Protected>
                }
              />
              <Route
                path="targets/:id"
                element={
                  <Protected roles={['sop']}>
                    <Target />
                  </Protected>
                }
              />
              {/* Inventories */}
              <Route
                path="inventories"
                element={
                  <Protected roles={['sop', 'distributor']}>
                    <Inventories />
                  </Protected>
                }
              />
              <Route
                path="inventories/:id"
                element={
                  <Protected roles={['sop', 'distributor']}>
                    <Inventory />
                  </Protected>
                }
              />
              {/* Routes */}
              <Route
                path="routes"
                element={
                  <Protected roles={['sop', 'fsa']}>
                    <RoutesPage />
                  </Protected>
                }
              />
              <Route
                path="routes/:id"
                element={
                  <Protected roles={['sop', 'fsa']}>
                    <RoutePage />
                  </Protected>
                }
              />

              {/* Visits */}
              <Route
                path="visits"
                element={
                  <Protected roles={['fsa']}>
                    <Visits />
                  </Protected>
                }
              />
              <Route
                path="visits/:id"
                element={
                  <Protected roles={['fsa']}>
                    <Visit />
                  </Protected>
                }
              />

              {/* Deliveries */}
              <Route
                path="deliveries"
                element={
                  <Protected roles={['sop', 'distributor']}>
                    <Deliveries />
                  </Protected>
                }
              />
              <Route
                path="deliveries/:id"
                element={
                  <Protected roles={['sop', 'distributor']}>
                    <Delivery />
                  </Protected>
                }
              />
              {/* Profile */}
              <Route
                path="profile"
                element={
                  <Protected roles={['sop', 'fsa', 'distributor', 'tae']}>
                    <Profile />
                  </Protected>
                }
              />
              {/* Notifications */}
              <Route
                path="notifications"
                element={
                  <Protected>
                    <Notifications />
                  </Protected>
                }
              />
              {/* Sites */}
              <Route
                path="siteOwners"
                element={
                  <Protected roles={['tae', 'sop']}>
                    <Sites />
                  </Protected>
                }
              />
              <Route
                path="siteOwners/new"
                element={
                  <Protected roles={['tae', 'sop']}>
                    <NewSite />
                  </Protected>
                }
              />
              <Route
                path="siteOwners/:id"
                element={
                  <Protected roles={['tae', 'sop']}>
                    <SiteOwner />
                  </Protected>
                }
              />
              <Route
                path="siteOwners/:id/edit"
                element={
                  <Protected roles={['tae', 'sop']}>
                    <EditSiteOwner />
                  </Protected>
                }
              />
              <Route
                path="sites/:id/edit"
                element={
                  <Protected roles={['tae', 'sop']}>
                    <EditSite />
                  </Protected>
                }
              />
              {/* Site Visits */}
              <Route
                path="siteVisits"
                element={
                  <Protected roles={['tae']}>
                    <SiteVisits />
                  </Protected>
                }
              />
              <Route
                path="siteVisits/:id"
                element={
                  <Protected roles={['tae']}>
                    <SiteVisit />
                  </Protected>
                }
              />
              {/* Leads */}
              <Route
                path="leads"
                element={
                  <Protected roles={['sop', 'fsa', 'distributor', 'tae']}>
                    <Leads />
                  </Protected>
                }
              />
              <Route
                path="leads/:id"
                element={
                  <Protected roles={['sop', 'fsa', 'distributor', 'tae']}>
                    <Lead />
                  </Protected>
                }
              />

              {/* Fallback */}
              <Route path="/denied" element={<Denied />} />
              <Route
                path="*"
                element={
                  <Content>
                    <Card className="flex justify-center">Page not found!</Card>
                  </Content>
                }
              />
            </Route>
          </Routes>
          <ToastContainer position="top-center" closeOnClick />
        </AuthProvider>
      </BrowserRouter>
    </ErrorBoundary>
  );
};

export default App;
