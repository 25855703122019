import { Path, RegisterOptions, UseFormReturn } from 'react-hook-form';

export interface TextareaProps<T>
  extends Partial<Pick<UseFormReturn<T>, 'register' | 'formState'>> {
  name: Path<T>;
  options?: RegisterOptions;
  rows?: number;
  cols?: number;
  className?: string;
}

const Textarea = <T,>({
  register,
  name,
  options,
  rows = 30,
  cols = 10,
  className,
  ...rest
}: TextareaProps<T>) => {
  return (
    <textarea
      {...register(name, options)}
      className={`bg-gray-200 py-1 px-3 rounded-lg ${className}`}
      cols={cols}
      rows={rows}
    />
  );
};

export default Textarea;
