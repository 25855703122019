import { Status, Wrapper } from '@googlemaps/react-wrapper';
import { DevTool } from '@hookform/devtools';
import { useEffect, useState } from 'react';
import { RegisterOptions, useForm } from 'react-hook-form';
import { To } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router-dom';
import { Confirm } from '../../../components/forms/Confirm';
import Input from '../../../components/forms/Input';
import Select from '../../../components/forms/Select';
import Content from '../../../components/layout/Content';
import Marker from '../../../components/map/Marker';
import Alert from '../../../components/ui/Alert';
import Back from '../../../components/ui/Back';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import Loader from '../../../components/ui/Loader';
import { useCheckAccess } from '../../../lib/auth/use-checkAccess';
import { useSite } from '../../../lib/hooks/use-sites';
import { useSiteOwner } from '../../../lib/hooks/use-siteOwners';
import Map from '../../../components/map/Map';
import { useLocation } from '../../../lib/hooks/use-location';

const render = (status: Status) => {
  return <h1>{status}</h1>;
};

type EditSiteParams = 'id';

type EditSiteForm = {
  type: string;
  geohash: string;
  name: string;
  lat: number;
  lng: number;
  accuracy: number;
};

const SITE_TYPES = [
  { key: 'commercial', label: 'Commercial', value: 'commercial' },
  { key: 'private', label: 'Private', value: 'private' },
];

export const EditSite = () => {
  const { id } = useParams<EditSiteParams>();
  const navigate = useNavigate();
  const checkAccess = useCheckAccess();
  const [siteOwnerId, setSiteOwnerId] = useState('');
  const {
    error: siteOwnerError,
    loading: siteOwnerLoading,
    update: siteOwnerUpdate,
  } = useSiteOwner(siteOwnerId);
  const {
    site,
    error: siteError,
    loading: siteLoading,
    update: siteUpdate,
  } = useSite(id);
  const {
    register,
    formState: { isValid, isDirty, errors, dirtyFields },
    reset,
    handleSubmit,
    control,
    setValue,
    trigger,
  } = useForm<EditSiteForm>();
  const [showSiteAlert, setShowSiteAlert] = useState(false);
  const [showSiteOwnerAlert, setShowSiteOwnerAlert] = useState(false);
  const [showLocationAlert, setShowLocationAlert] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [backString, setBackString] = useState<To>('/siteOwners');
  const [geohashLoading, setGeohashLoading] = useState<boolean>(false);
  const [geohashCaptured, setGeoHashCaptured] = useState<boolean>(false);
  const [zoom, setZoom] = useState(13); // initial zoom
  const [center, setCenter] = useState<google.maps.LatLngLiteral>({
    lat: 0,
    lng: 0,
  });
  const [marker, setMarker] = useState<google.maps.LatLngLiteral>();
  const [location, accuracy, locationError] = useLocation(
    geohashLoading,
    100,
    60
  );

  const [locationErrorMessage, setLocationErrorMessage] = useState<string>();

  const onIdle = (m: google.maps.Map) => {
    setZoom(m.getZoom()!);
    setCenter(m.getCenter()!.toJSON());
  };

  const nameOptions: RegisterOptions = {
    required: 'Please enter a name!',
    minLength: {
      value: 3,
      message: 'Please enter a name with at least 3 characters!',
    },
    maxLength: {
      value: 50,
      message: 'The name cannot exceed 50 characters!',
    },
  };

  const channelOptions: RegisterOptions = {
    required: 'Please select a site type!',
  };

  useEffect(() => {
    if (site) {
      setSiteOwnerId(site.siteOwner.id);
    }
  }, [site]);

  useEffect(() => {
    if (site) {
      const values = {
        name: site.name,
      };
      if (site.location) {
        values['lat'] = site.location.lat;
        values['lng'] = site.location.lng;
        values['geohash'] = site.location.geohash;
      }
      if ('type' in site) {
        values['type'] = site.type;
      }
      reset(values);
    }
  }, [site]);

  useEffect(() => {
    if (siteError) {
      setShowSiteAlert(true);
    } else if (showSiteAlert) {
      setShowSiteAlert(false);
    }
  }, [siteError]);

  useEffect(() => {
    if (site) {
      setBackString(`/siteOwners/${site.siteOwner.id}`);
    }
  }, [site, id]);

  const submitHandler = (data: EditSiteForm) => {
    if (isDirty) {
      const updateObj = {};
      if (dirtyFields.name) updateObj['name'] = data.name;
      if (dirtyFields.type) updateObj['type'] = data.type;
      if (dirtyFields.geohash)
        updateObj['location'] = {
          geohash: data.geohash,
          lat: data.lat,
          lng: data.lng,
        };
      siteUpdate(updateObj);
      if (!checkAccess(['sop'])) siteOwnerUpdate({ siteChanged: true });
      navigate(backString, { replace: true });
    }
  };

  const handleOnConfirm = () => {
    setShowConfirmation(false);
    handleSubmit(submitHandler)();
  };

  const handleOnCancel = () => {
    setShowConfirmation(false);
  };

  useEffect(() => {
    if (location) {
      const { lat, lng, geohash } = location;
      setValue('lat', lat, { shouldDirty: true });
      setValue('lng', lng, { shouldDirty: true });
      setValue('geohash', geohash, { shouldDirty: true });
      setValue('accuracy', accuracy, { shouldDirty: true });
      setMarker({ lat, lng });
      setCenter({ lat, lng });
      setGeohashLoading(false);
      setGeoHashCaptured(true);
      trigger(['lat', 'lng', 'geohash', 'accuracy']);
    }

    if (locationError) {
      setLocationErrorMessage(locationError.message);
      setShowLocationAlert(true);
      setGeohashLoading(false);
    }

    if (accuracy) {
      console.log(`Accuracy: ${accuracy}`);
    }
  }, [location, locationError, accuracy]);

  return (
    <Content>
      <Loader show={siteLoading || geohashLoading || siteOwnerLoading} />
      <Alert
        message={siteOwnerError && siteOwnerError.message}
        open={showSiteOwnerAlert}
        setOpen={(open) => setShowSiteOwnerAlert(open)}
        title="Error"
      />
      <Alert
        message={siteError && siteError.message}
        open={showSiteAlert}
        setOpen={(open) => setShowSiteAlert(open)}
        title="Error"
      />
      <Alert
        message={locationErrorMessage}
        open={showLocationAlert}
        setOpen={(open) => setShowLocationAlert(open)}
        title="Error"
      />
      <Confirm
        title="Are you sure you want to save these changes?"
        open={showConfirmation}
        onConfirm={handleOnConfirm}
        onCancel={handleOnCancel}
        setOpen={setShowConfirmation}
      />
      <Card>
        <Back to={backString} />
        <form
          onSubmit={(event) => event.preventDefault()}
          className="col-span-full flex flex-col"
        >
          <label htmlFor="name">Name</label>
          <Input
            register={register}
            name="name"
            placeholder="Name..."
            error={errors.name}
            options={nameOptions}
          />
          <label htmlFor="channel">Site Type</label>
          <Select
            register={register}
            name="type"
            items={SITE_TYPES}
            placeholder="Select type..."
            error={errors.type}
            options={channelOptions}
            className="mb-5"
          />
          <Button
            onClick={() => setGeohashLoading(true)}
            text={
              geohashCaptured ? 'Re-Capture Location' : 'Capture Site Location'
            }
            color="green"
          />
          <input {...register('lat')} className="hidden" />
          <input {...register('lng')} className="hidden" />
          <input {...register('geohash')} className="hidden" />
          {geohashCaptured && (
            <>
              <p className="text-green-500 font-thin text-sm">
                The location has ben captured!
              </p>
              <div className="col-span-full h-56 flex mt-4">
                <Wrapper
                  apiKey={process.env.REACT_APP_MAPS_API_KEY}
                  render={render}
                >
                  <Map
                    center={center}
                    onIdle={onIdle}
                    zoom={zoom}
                    className="flex-grow h-full rounded-lg"
                    disableDefaultUI
                  >
                    ={marker && <Marker position={marker} />}
                  </Map>
                </Wrapper>
              </div>
            </>
          )}
          <Button
            buttonDisabled={!isValid || !isDirty}
            text="Save"
            color="green"
            onClick={() => {
              setShowConfirmation(true);
            }}
          />
        </form>
        <DevTool control={control} />
      </Card>
    </Content>
  );
};
