import {
  Dispatch,
  forwardRef,
  SetStateAction,
  useEffect,
  useImperativeHandle,
} from 'react';
import { RegisterOptions, useForm, useWatch } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';
import { useVisit } from '../../../lib/hooks/use-visits';
import { Location } from '../../../../../global';
import { useState } from 'react';
import Select from '../../../components/forms/Select';
import { useLocation } from '../../../lib/hooks/use-location';

type VisitParams = 'id';

const VISIT_PURPOSE = [
  {
    key: 'cement_engagement',
    label: 'Cement engagement',
    value: 'cement_engagement',
  },
  {
    key: 'mortar_engagement',
    label: 'Mortar engagement',
    value: 'mortar_engagement',
  },
  {
    key: 'cement_mortar_engagement',
    label: 'Cement & Mortar engagement',
    value: 'cement_mortar_engagement',
  },
  {
    key: 'follow_up',
    label: 'Follow Up',
    value: 'follow_up',
  },
];

const ACTIVITY_LEVEL = [
  {
    key: 'foundation',
    label: 'Foundation',
    value: 'foundation',
  },
  {
    key: 'casting',
    label: 'Casting',
    value: 'casting',
  },
  {
    key: 'plastering',
    label: 'Plastering',
    value: 'plastering',
  },
  {
    key: 'block_setting',
    label: 'Block setting',
    value: 'block_setting',
  },
  {
    key: 'tiling_stage',
    label: 'Tiling stage',
    value: 'tiling_stage',
  },
];

const REASON_ACTIVITY_LEVEL = [
  {
    key: 'budget_constraint',
    label: 'Budget constraint',
    value: 'budget_constraint',
  },
  {
    key: 'holiday',
    label: 'Holiday',
    value: 'holiday',
  },
  {
    key: 'high_cost_of_cement',
    label: 'High cost of cement',
    value: 'high_cost_of_cement',
  },
  {
    key: 'high_cost_of_building_materials',
    label: 'High cost of building materials',
    value: 'high_cost_of_building_materials',
  },
  {
    key: 'government_restriction',
    label: 'Government restrictions',
    value: 'government_restriction',
  },
  {
    key: 'shortage_of_labour',
    label: 'Shortage of labour',
    value: 'shortage_of_labour',
  },
  {
    key: 'project_finalized',
    label: 'Project finalized',
    value: 'project_finalized',
  },
  {
    key: 'other',
    label: 'Other',
    value: 'other',
  },
];

type Props = {
  editable: boolean;
  setStep1: any;
  setStep1IsDirty: any;
  setStep1IsValid: any;
  setLocation: Dispatch<SetStateAction<Location>>;
  geohashLoading: boolean;
  setGeohashLoading: Dispatch<SetStateAction<boolean>>;
  step1: {
    visitPurpose: string;
    activeSite: string;
    reasonActivityLevel: string;
    activityLevel: string;
  };
};

const SiteVisitStep1 = forwardRef((props: Props, ref) => {
  const { id } = useParams<VisitParams>();
  const navigate = useNavigate();
  const { visit, error, loading } = useVisit(id);
  const [formValid, setFormValid] = useState(null);
  const {
    control,
    register,
    getValues,
    reset,
    handleSubmit,
    formState: { isValid, isDirty, errors, dirtyFields },
  } = useForm({
    defaultValues: { ...props.step1 },
    mode: 'all',
  });
  const [accuracyThreshold, setAccuracyThreshold] = useState<number>(100);
  const [location, accuracy, locationError] = useLocation(
    props.geohashLoading,
    accuracyThreshold,
    60
  );

  const visitPurpose = useWatch({
    control,
    name: 'visitPurpose',
  });
  const activeSite = useWatch({
    control,
    name: 'activeSite',
  });
  const reasonActivityLevel = useWatch({
    control,
    name: 'reasonActivityLevel',
  });
  const activityLevel = useWatch({
    control,
    name: 'activityLevel',
  });

  const visitPurposeOptions: RegisterOptions = {
    required: 'Please select a purpose type!',
  };

  const activeSiteOptions: RegisterOptions = {
    required: 'Please select if this site is still active!',
  };

  const reasonActivityLevelOptions: RegisterOptions = {
    required: 'Please select why this site is inactive!',
  };

  const activityLevelOptions: RegisterOptions = {
    required: 'Please select the activity level of this site!',
  };

  useEffect(() => {
    props.setStep1IsDirty(true);
  }, [isDirty]);

  useEffect(() => {
    if (
      (visitPurpose &&
        visitPurpose !== 'placeholder' &&
        activeSite === 'active' &&
        activityLevel &&
        activityLevel !== 'placeholder') ||
      (visitPurpose !== 'placeholder' &&
        activeSite === 'notActive' &&
        activityLevel &&
        activityLevel !== 'placeholder' &&
        reasonActivityLevel &&
        reasonActivityLevel !== 'placeholder')
    ) {
      props.setStep1IsValid(true);
      setFormValid(true);
    } else {
      props.setStep1IsValid(false);
      setFormValid(false);
    }
  }, [visitPurpose, activeSite, reasonActivityLevel, activityLevel]);

  useImperativeHandle(ref, () => ({
    saveStep1() {
      doSaveStep1();
    },
  }));

  const doSaveStep1 = () => {
    handleSubmit(props.setStep1)();
  };

  useEffect(() => props.setGeohashLoading(true), []);

  useEffect(() => {
    if (location) {
      const { lat, lng, geohash } = location;
      props.setLocation({ geohash, lat, lng, accuracy });
      props.setGeohashLoading(false);
    } else if (locationError) {
      setAccuracyThreshold(0);
    }
  }, [location, locationError, accuracy]);

  return (
    <>
      <div className="grid grid-cols-9 col-span-9 ">
        <div className="grid grid-cols-12 col-span-9 content-center ">
          <label
            htmlFor="visitPurpose"
            className="font-bold col-span-12 mb-2 text-lh-head-black"
          >
            Visit Purpose
          </label>
          <Select
            register={register}
            name="visitPurpose"
            items={VISIT_PURPOSE}
            placeholder="Select visit purpose..."
            error={errors.visitPurpose}
            options={visitPurposeOptions}
            className="col-span-12"
          />
          <label
            htmlFor="activeSite"
            className="font-bold col-span-12 mb-2 text-lh-head-black"
          >
            Active Site
          </label>
          <Select
            register={register}
            name="activeSite"
            items={[
              {
                key: 'active',
                label: 'Active',
                value: 'active',
              },
              {
                key: 'notActive',
                label: 'Not Active',
                value: 'notActive',
              },
            ]}
            placeholder="Is this site still active?"
            error={errors.activeSite}
            options={activeSiteOptions}
            className="col-span-12"
          />
          {activeSite === 'notActive' && (
            <>
              <label
                htmlFor="reasonActivityLevel"
                className="font-bold col-span-12 mb-2 text-lh-head-black"
              >
                Reason for Site inactive
              </label>
              <Select
                register={register}
                name="reasonActivityLevel"
                items={REASON_ACTIVITY_LEVEL}
                placeholder="Select reason for site inactive..."
                error={errors.reasonActivityLevel}
                options={reasonActivityLevelOptions}
                className="col-span-12"
              />
            </>
          )}
          <label
            htmlFor="activityLevel"
            className="font-bold col-span-12 mb-2 text-lh-head-black"
          >
            Activity level
          </label>
          <Select
            register={register}
            name="activityLevel"
            items={ACTIVITY_LEVEL}
            placeholder="Select activity level..."
            error={errors.activityLevel}
            options={activityLevelOptions}
            className="col-span-12"
          />
        </div>
      </div>
    </>
  );
});

export default SiteVisitStep1;
