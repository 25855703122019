import { doc, GeoPoint, getDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { RegisterOptions, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { OutletChannel, OutletStatus } from '../../../../global';
import { Confirm } from '../../components/forms/Confirm';
import Input from '../../components/forms/Input';
import SearchableSelect from '../../components/forms/SearchableSelect';
import Select from '../../components/forms/Select';
import Content from '../../components/layout/Content';
import Alert from '../../components/ui/Alert';
import Back from '../../components/ui/Back';
import Button from '../../components/ui/Button';
import Card from '../../components/ui/Card';
import Loader from '../../components/ui/Loader';
import { useCheckAccess } from '../../lib/auth/use-checkAccess';
import { useAssociates } from '../../lib/hooks/use-associates';
import { useOutlet } from '../../lib/hooks/use-outlets';
import { useRetailer } from '../../lib/hooks/use-retailers';
import { useUser } from '../../lib/hooks/use-user';
import { firestore } from '../../lib/utils/firebase';
import { DevTool } from '@hookform/devtools';
import Marker from '../../components/map/Marker';
import { Status, Wrapper } from '@googlemaps/react-wrapper';
import Map from '../../components/map/Map';
import { useLocation } from '../../lib/hooks/use-location';

const render = (status: Status) => {
  return <h1>{status}</h1>;
};

type Option = {
  value: string;
  label: string;
};

type NewRetailerForm = {
  clusterId: string;
  businessName: string;
  name: string;
  phone: string;
  primaryDistributorId: Option;
  primaryFSAId: Option;
  channel: OutletChannel;
  lat: number;
  lng: number;
  geohash: string;
  accuracy: number;
};

const CHANNEL_TYPES = [
  {
    key: 'blockmaker',
    label: 'Blockmaker',
    value: 'blockmaker',
  },
  {
    key: 'blockmaker_retailer',
    label: 'Blockmaker / Retailer',
    value: 'blockmaker_retailer',
  },
  {
    key: 'container',
    label: 'Container',
    value: 'container',
  },
  {
    key: 'neighborhood_shop',
    label: 'Neighborhood Shop',
    value: 'neighborhood_shop',
  },
  {
    key: 'palleter',
    label: 'Palleter',
    value: 'palleter',
  },
  {
    key: 'tiel_seller',
    label: 'Tile Seller',
    value: 'tile_seller',
  },
];

const NewRetailer = () => {
  const {
    associates: distributors,
    error: distributorsError,
    loading: distributorsLoading,
  } = useAssociates({
    roles: 'distributor',
  });
  const {
    associates: fsas,
    error: fsasError,
    loading: fsasLoading,
  } = useAssociates({
    roles: 'fsa',
  });
  const { user, loading: userLoading, error: userError } = useUser();
  const checkAccess = useCheckAccess();
  const [retailerId, setRetailerId] = useState<string>();
  const {
    create: retailerCreate,
    update: retailerUpdate,
    retailer,
    loading: retailerLoading,
    error: retailerError,
  } = useRetailer(retailerId);
  const { create: outletCreate } = useOutlet();
  const navigate = useNavigate();
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [showCreateOutlet, setShowCreateOutlet] = useState<boolean>(false);

  const {
    register,
    formState: { errors, isValid },
    setValue,
    getValues,
    handleSubmit,
    control,
    reset,
    trigger,
  } = useForm<NewRetailerForm>({ mode: 'all' });
  const [showDistributorAlert, setShowDistributorAlert] =
    useState<boolean>(false);
  const [showFSAsAlert, setShowFSAsAlert] = useState(false);
  const [showUserAlert, setShowUserAlert] = useState<boolean>(false);
  const [showRetailerAlert, setShowRetailerAlert] = useState<boolean>(false);
  const [showLocationAlert, setShowLocationAlert] = useState<boolean>(false);
  const [geohashLoading, setGeohashLoading] = useState<boolean>(false);
  const [geohashCaptured, setGeoHashCaptured] = useState<boolean>(false);
  const [accuracyThreshold, setAccuracyThreshold] = useState<number>(100);
  const [zoom, setZoom] = useState(13); // initial zoom
  const [center, setCenter] = useState<google.maps.LatLngLiteral>({
    lat: 0,
    lng: 0,
  });
  const [marker, setMarker] = useState<google.maps.LatLngLiteral>();
  const [cannotUsePhoneMessage, setCannotUsePhoneMessage] = useState<string>();
  const [showCannotUsePhone, setShowCannotUsePhone] = useState<boolean>(false);
  const [location, accuracy, locationError] = useLocation(
    geohashLoading,
    accuracyThreshold,
    60
  );
  const [locationErrorMessage, setLocationErrorMessage] = useState<string>();

  const phoneOptions: RegisterOptions = {
    required: 'Please enter a phone number!',
    pattern: {
      value: /^\+[0-9]{11,13}$/,
      message: 'Please enter a valid phone number!',
    },
  };

  const nameOptions: RegisterOptions = {
    required: 'Please enter a name!',
    minLength: {
      value: 3,
      message: 'Please enter a name with at least 3 characters!',
    },
    maxLength: {
      value: 50,
      message: 'The name cannot exceed 50 characters!',
    },
  };

  const businessNameOptions: RegisterOptions = {
    required: 'Please enter a business name!',
    minLength: {
      value: 3,
      message: 'Please enter a business name with at least 3 characters!',
    },
    maxLength: {
      value: 50,
      message: 'The business name cannot exceed 50 characters!',
    },
  };

  const channelOptions: RegisterOptions = {
    required: 'Please select a channel type!',
  };

  const locationOptions: RegisterOptions = {
    required: 'Please register your current location!',
  };

  const onIdle = (m: google.maps.Map) => {
    setZoom(m.getZoom()!);
    setCenter(m.getCenter()!.toJSON());
  };

  const handleOnConfirm = () => {
    setShowConfirmation(false);
    handleSubmit(submitHandler)();
  };

  const handleOnCancel = () => {
    setShowConfirmation(false);
  };

  const handleOutletOnConfirm = async () => {
    setShowCreateOutlet(false);
    const data = getValues();

    const primaryFSA = user.roles.includes('fsa')
      ? user
      : fsas.find((ass) => ass.id === data.primaryFSAId.value);
    const primaryDistributor = distributors.find(
      (ass) => ass.id === data.primaryDistributorId.value
    );
    const sop = user.roles.includes('sop') ? user : user.sop;

    const cluster =
      'clusters' in user
        ? user.clusters.find((cluster) => cluster.id === data.clusterId)
        : user.cluster;
    if (!cluster) return;

    let status = checkAccess(['sop']) ? 'confirmed' : 'unconfirmed';
    const active = checkAccess(['sop']) ? true : false;

    await outletCreate({
      active,
      channel: data.channel,
      cluster,
      location: {
        geohash: data.geohash,
        lat: data.lat,
        lng: data.lng,
        accuracy: data.accuracy,
      },
      name: data.businessName,
      primaryDistributor: {
        id: primaryDistributor.id,
        name: primaryDistributor.name,
        phone: primaryDistributor.phone,
      },
      primaryFSA: {
        id: primaryFSA.id,
        name: primaryFSA.name,
        phone: primaryFSA.phone,
      },
      retailer: {
        id: retailer.id,
        name: retailer.name,
        phone: retailer.phone,
      },
      sop: {
        email: sop.email,
        id: sop.id,
        name: sop.name,
        phone: sop.phone,
      },
      status: status as OutletStatus,
      userIds: [primaryDistributor.id, primaryFSA.id, sop.id, retailer.id],
    });

    if (!checkAccess(['sop'])) retailerUpdate({ outletChanged: true });
    if (!retailerError) navigate('/retailers', { replace: true });
  };

  const handleOutletOnCancel = () => {
    setShowCreateOutlet(false);
  };

  const submitHandler = async (data: NewRetailerForm) => {
    if (!data.geohash || !data.lat || !data.lng) return;
    const phoneRef = doc(firestore, 'phones', data.phone);
    const phoneSnap = await getDoc(phoneRef);
    if (phoneSnap.exists()) {
      if (!phoneSnap.data()!.roles.includes('retailer')) {
        setCannotUsePhoneMessage('This phone number cannot be used!');
        setShowCannotUsePhone(true);
        return;
      }
      setRetailerId(phoneSnap.data().id);
      setShowCreateOutlet(true);
      return;
    }

    const primaryDistributor = distributors.find(
      (ass) => ass.id === data.primaryDistributorId.value
    );

    const primaryFSA = user.roles.includes('fsa')
      ? user
      : fsas.find((ass) => ass.id === data.primaryFSAId.value);
    const sop = user.roles.includes('sop') ? user : user.sop;

    const cluster =
      'clusters' in user
        ? user.clusters.find((cluster) => cluster.id === data.clusterId)
        : user.cluster;
    if (!cluster) return;

    await retailerCreate({
      businessName: data.businessName,
      channel: data.channel,
      cluster,
      location: {
        geohash: data.geohash,
        lat: data.lat,
        lng: data.lng,
        accuracy: data.accuracy,
      },
      name: data.name,
      phone: data.phone,
      primaryDistributor: {
        id: primaryDistributor.id,
        name: primaryDistributor.name,
        phone: primaryDistributor.phone,
      },
      primaryFSA: {
        id: primaryFSA.id,
        name: primaryFSA.name,
        phone: primaryFSA.phone,
      },
      sop: {
        email: sop.email,
        id: sop.id,
        name: sop.name,
        phone: sop.phone,
      },
    });
    if (!retailerError) navigate('/retailers', { replace: true });
  };

  useEffect(() => {
    if (user) {
      const clusterId =
        'clusters' in user ? user.clusters[0].id : user.cluster.id;
      reset({ clusterId });
    }
  }, [user]);

  useEffect(() => {
    if (location) {
      setGeohashLoading(true);
      const { lat, lng, geohash } = location;
      setValue('lat', lat, { shouldDirty: true });
      setValue('lng', lng, { shouldDirty: true });
      setValue('geohash', geohash, { shouldDirty: true });
      setValue('accuracy', accuracy, { shouldDirty: true });
      setMarker({ lat, lng });
      setCenter({ lat, lng });
      setGeoHashCaptured(true);
      setGeohashLoading(false);
      trigger(['lat', 'lng', 'geohash', 'accuracy']);
    } else if (locationError) {
      if (locationError.code === 5) {
        setLocationErrorMessage(locationError.message);
        setShowLocationAlert(true);
        setAccuracyThreshold(null);
      } else {
        setLocationErrorMessage(locationError.message);
        setShowLocationAlert(true);
        setGeohashLoading(false);
      }
    }
  }, [location, locationError, accuracy]);

  return (
    <Content>
      <Loader
        show={
          distributorsLoading ||
          fsasLoading ||
          userLoading ||
          retailerLoading ||
          geohashLoading
        }
      />
      <Alert
        message={distributorsError && distributorsError.message}
        open={showDistributorAlert}
        setOpen={(open) => setShowDistributorAlert(open)}
        title="Error"
      />
      <Alert
        message={fsasError && fsasError.message}
        open={showFSAsAlert}
        setOpen={(open) => setShowFSAsAlert(open)}
        title="Error"
      />
      <Alert
        message={userError && userError.message}
        open={showUserAlert}
        setOpen={(open) => setShowUserAlert(open)}
        title="Error"
      />
      <Alert
        message={retailerError && retailerError.message}
        open={showRetailerAlert}
        setOpen={(open) => setShowRetailerAlert(open)}
        title="Error"
      />
      <Alert
        message={cannotUsePhoneMessage}
        open={showCannotUsePhone}
        setOpen={(open) => setShowCannotUsePhone(open)}
        title="Error"
      />
      <Alert
        message={locationErrorMessage}
        open={showLocationAlert}
        setOpen={(open) => setShowLocationAlert(open)}
        title="Error"
      />
      <Confirm
        title="Are you sure you want to register a new Retailer? This may take a few minutes."
        open={showConfirmation}
        onConfirm={handleOnConfirm}
        onCancel={handleOnCancel}
        setOpen={setShowConfirmation}
      />
      <Confirm
        title={`A Retailer with the phone number ${
          getValues().phone
        } is already registered. Would you like to add an Outlet instead?`}
        open={showCreateOutlet}
        onConfirm={handleOutletOnConfirm}
        onCancel={handleOutletOnCancel}
        setOpen={setShowCreateOutlet}
      />
      <Card>
        <Back to="/retailers" className="col-span-4"></Back>
        <form
          className="col-span-full flex justify-center flex-col"
          onSubmit={(event) => event.preventDefault()}
        >
          {user && 'clusters' in user && user.clusters.length > 1 && (
            <>
              <label
                htmlFor="clusterId"
                className="font-bold col-span-12 mb-2 text-lh-head-black"
              >
                Cluster
              </label>
              <Select
                name="clusterId"
                register={register}
                items={user.clusters.map((cluster) => ({
                  key: cluster.id,
                  value: cluster.id,
                  label: cluster.name,
                }))}
                className="mb-6"
              />
            </>
          )}
          <label
            htmlFor="phone"
            className="font-bold col-span-12 mb-2 text-lh-head-black"
          >
            Phone
          </label>
          <Input
            register={register}
            name="phone"
            type="tel"
            placeholder="+234XXXXXXXXXX"
            error={errors.phone}
            options={phoneOptions}
          ></Input>
          <label
            htmlFor="name"
            className="font-bold col-span-12 mb-2 text-lh-head-black"
          >
            Name
          </label>
          <Input
            register={register}
            name="name"
            type="text"
            placeholder="Name..."
            error={errors.name}
            options={nameOptions}
          ></Input>
          <label
            htmlFor="businessName"
            className="font-bold col-span-12 mb-2 text-lh-head-black"
          >
            Business Name
          </label>
          <Input
            register={register}
            name="businessName"
            type="text"
            placeholder="Business Name..."
            error={errors.businessName}
            options={businessNameOptions}
          ></Input>
          <label
            htmlFor="businessName"
            className="font-bold col-span-12 mb-2 text-lh-head-black"
          >
            Channel
          </label>
          <Select
            register={register}
            name="channel"
            items={CHANNEL_TYPES}
            placeholder="Select channel..."
            error={errors.channel}
            options={channelOptions}
            className="mb-5"
          />
          {checkAccess(['sop']) && (
            <SearchableSelect
              control={control}
              name="primaryFSAId"
              rules={{ required: true }}
              label="FSA"
              placeholder="Select FSA..."
              values={
                fsas
                  ? fsas
                      .filter(
                        (fsa) => fsa.cluster.id === getValues('clusterId')
                      )
                      .map((fsa) => ({
                        value: fsa.id,
                        key: fsa.id,
                        label: fsa.name,
                      }))
                  : []
              }
              className="col-span-12 text-lh-text-black"
            />
          )}

          {!checkAccess(['distributor']) && (
            <SearchableSelect
              control={control}
              name="primaryDistributorId"
              rules={{ required: true }}
              label="Distributor"
              placeholder="Select Distributor..."
              values={
                distributors
                  ? distributors
                      .filter(
                        (distributor) =>
                          distributor.cluster.id === getValues('clusterId')
                      )
                      .map((distributor) => ({
                        value: distributor.id,
                        key: distributor.id,
                        label: distributor.name,
                      }))
                  : []
              }
              className="col-span-12 text-lh-text-black"
            />
          )}
          <Button
            onClick={() => setGeohashLoading(true)}
            text={
              geohashCaptured
                ? 'Re-Capture Location'
                : 'Capture Outlet Location'
            }
            color="green"
            className="mt-4"
          />
          <input {...register('geohash', locationOptions)} hidden />
          <input {...register('lat', locationOptions)} hidden />
          <input {...register('lng', locationOptions)} hidden />
          <input {...register('accuracy', locationOptions)} hidden />
          {geohashCaptured && (
            <p className="text-green-500 font-thin text-sm">
              The location has ben captured!
            </p>
          )}
          {geohashCaptured && (
            <div className="col-span-full h-56 flex mt-4">
              <Wrapper
                apiKey={process.env.REACT_APP_MAPS_API_KEY}
                render={render}
              >
                <Map
                  center={center}
                  onIdle={onIdle}
                  zoom={zoom}
                  className="flex-grow h-full rounded-lg"
                  disableDefaultUI
                >
                  ={marker && <Marker position={marker} />}
                </Map>
              </Wrapper>
            </div>
          )}
          <Button
            buttonDisabled={!isValid}
            text="Register Retailer"
            color="blue"
            onClick={() => setShowConfirmation(true)}
            className="mt-4"
          />
        </form>
        <DevTool control={control} />
      </Card>
    </Content>
  );
};

export default NewRetailer;
