import { Timestamp } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { RegisterOptions, SubmitHandler, useForm } from 'react-hook-form';
import { FulfillOrderData, Order } from '../../../../global';
import { createTier2Fulfillment } from '../../lib/db/tier2fulfillments';
import { useOutlets } from '../../lib/hooks/use-outlets';
import { Confirm } from '../forms/Confirm';
import Input from '../forms/Input';
import SearchableSelect from '../forms/SearchableSelect';
import Alert from '../ui/Alert';
import Button from '../ui/Button';
import Loader from '../ui/Loader';
import Modal from '../ui/Modal';

type FulfillOrderProps = {
  open: boolean;
  order: Order;
  onFulfill?: SubmitHandler<FulfillOrderForm>;
  onFulfillTier2?: SubmitHandler<FulfillOrderForm>;
  setOpen: (boolean) => void;
  confirmText: string;
  buttonText: string;
  tier2?: boolean;
};

type FulfillOrderForm = {
  classic: number;
  supaset: number;
  supafixc0: number;
  supafixc1: number;
};

const FulfillOrder = ({
  open,
  order,
  onFulfill,
  onFulfillTier2,
  setOpen,
  confirmText,
  buttonText,
  tier2,
}: FulfillOrderProps) => {
  const { register, handleSubmit, reset, getValues, control } =
    useForm<FulfillOrderForm>({
      defaultValues: { classic: 0, supaset: 0, supafixc0: 0, supafixc1: 0 },
    });
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [block, setBlock] = useState(false);
  const [showOutletsAlert, setShowOutletsAlert] = useState(false);

  const {
    error: outletsError,
    loading: outletsLoading,
    outlets,
  } = useOutlets({
    pageSize: 0,
    active: true,
  });

  const moreThanZero = (): boolean => {
    const classic = getValues('classic');
    const supafixc0 = getValues('supafixc0');
    const supafixc1 = getValues('supafixc1');
    const supaset = getValues('supaset');
    return (
      (isNaN(classic) ? 0 : classic) +
        (isNaN(supafixc0) ? 0 : supafixc0) +
        (isNaN(supafixc1) ? 0 : supafixc1) +
        (isNaN(supaset) ? 0 : supaset) >=
      0
    );
  };

  const productOptions: RegisterOptions = {
    valueAsNumber: true,
    min: {
      value: 0,
      message: 'Product cannot be negative!',
    },
    max: {
      value: 10000,
      message: "You can only order 10'000 bags at a time!",
    },
    validate: {
      moreThanZero,
    },
  };

  const handleOnConfirm = () => {
    setBlock(false);
    setShowConfirmation(false);

    if (tier2) {
      handleSubmit(handleFulfillTier2)();
    } else {
      handleSubmit(onFulfill)();
    }
  };

  const handleOnCancel = () => {
    setBlock(false);
    setShowConfirmation(false);
  };

  useEffect(() => {
    if (order)
      reset({
        classic: order.productsDelivered
          ? order.products.classic - order.productsDelivered.classic >= 0
            ? order.products.classic - order.productsDelivered.classic
            : 0
          : order.products.classic,
        supaset: order.productsDelivered
          ? order.products.supaset - order.productsDelivered.supaset >= 0
            ? order.products.supaset - order.productsDelivered.supaset
            : 0
          : order.products.supaset,
        supafixc0: order.productsDelivered
          ? order.products.supafixc0 - order.productsDelivered.supafixc0 >= 0
            ? order.products.supafixc0 - order.productsDelivered.supafixc0
            : 0
          : order.products.supafixc0,
        supafixc1: order.productsDelivered
          ? order.products.supafixc1 - order.productsDelivered.supafixc1 >= 0
            ? order.products.supafixc1 - order.productsDelivered.supafixc1
            : 0
          : order.products.supafixc1,
      });
  }, [order, reset]);

  const handleFulfillTier2 = async (data) => {
    const productsDeliveredTier2 = {
      classic: isNaN(data.classic) ? 0 : data.classic,
      supaset: isNaN(data.supaset) ? 0 : data.supaset,
      supafixc0: isNaN(data.supafixc0) ? 0 : data.supafixc0,
      supafixc1: isNaN(data.supafixc1) ? 0 : data.supafixc1,
    };

    await createTier2Fulfillment({
      outletId: data.outlet.value,
      fulfilledProducts: productsDeliveredTier2,
      outletIdOrdered: order.outlet.id,
      fulfilledAt: Timestamp.now(),
      orderId: order.id,
    });

    handleSubmit(onFulfillTier2)();
  };

  return (
    <Modal open={open} setOpen={setOpen} block={block}>
      <Loader show={outletsLoading} />
      <Alert
        message={outletsError && outletsError.message}
        open={showOutletsAlert}
        setOpen={(open) => setShowOutletsAlert(open)}
        title="Error"
      />
      <Confirm
        title={confirmText}
        open={showConfirmation}
        onConfirm={handleOnConfirm}
        onCancel={handleOnCancel}
        setOpen={(open) => {
          setShowConfirmation(open);
          setBlock(open);
        }}
      />
      <form
        name="fulfillOrderForm"
        onSubmit={handleSubmit(onFulfill)}
        className="grid grid-cols-12"
      >
        <h2 className="col-span-full font-bold">
          Products fulfilled / ordered
        </h2>
        <h3 className="col-span-4 py-1">Classic</h3>
        <p className="col-span-4 col-start-8 mb-4 mr-2 text-right py-1 px-3">
          {order &&
            (order.productsDelivered
              ? order.productsDelivered.classic
              : 0)}{' '}
          {' / '}
          {order && order.products.classic}
        </p>
        <p className="col-span-1 py-1">bags</p>
        <h3 className="col-span-4 py-1">Supaset</h3>
        <p className="col-span-4 col-start-8 mb-4 mr-2 text-right py-1 px-3">
          {order &&
            (order.productsDelivered
              ? order.productsDelivered.supaset
              : 0)}{' '}
          {' / '}
          {order && order.products.supaset}
        </p>
        <p className="col-span-1 py-1">bags</p>
        <h3 className="col-span-4 py-1">SupafixC0</h3>
        <p className="col-span-4 col-start-8 mb-4 mr-2 text-right py-1 px-3">
          {order &&
            (order.productsDelivered
              ? order.productsDelivered.supafixc0
              : 0)}{' '}
          {' / '}
          {order && order.products.supafixc0}
        </p>
        <p className="col-span-1 py-1">bags</p>
        <h3 className="col-span-4 py-1">SupafixC1</h3>
        <p className="col-span-4 col-start-8 mb-4 mr-2 text-right py-1 px-3">
          {order &&
            (order.productsDelivered
              ? order.productsDelivered.supafixc1
              : 0)}{' '}
          {' / '}
          {order && order.products.supafixc1}
        </p>
        <p className="col-span-1 py-1">bags</p>
        {tier2 && (
          <SearchableSelect
            control={control}
            name="outlet"
            rules={{ required: true }}
            label="Tier 2 outlet"
            placeholder="Select Outlet..."
            values={
              outlets
                ? outlets
                    .filter((outlet) => outlet.id !== order.outlet.id)
                    .map((outlet) => {
                      if (outlet.id !== order.outlet.id) {
                        return {
                          value: outlet.id,
                          label: outlet.name,
                        };
                      } else return null;
                    })
                : []
            }
            className="col-span-12 text-lh-text-black"
          />
        )}
        <h2 className="col-span-full font-bold">Products Delivered</h2>
        <label htmlFor="classic" className="col-span-4 pt-1">
          Classic
        </label>
        <Input
          register={register}
          placeholder="0"
          name="classic"
          type="number"
          options={productOptions}
          className="col-span-4 col-start-8 text-right"
        />
        <label htmlFor="classic" className="col-spa-1 ml-2 pt-1">
          bags
        </label>
        <label htmlFor="supaset" className="col-span-4 pt-1">
          Supaset
        </label>
        <Input
          register={register}
          placeholder="0"
          name="supaset"
          type="number"
          options={productOptions}
          className="col-span-4 col-start-8 text-right"
        />
        <label htmlFor="classic" className="col-spa-1 ml-2 pt-1">
          bags
        </label>
        <label htmlFor="supafixc0" className="col-span-4 pt-1">
          Supafix C0
        </label>
        <Input
          register={register}
          placeholder="0"
          name="supafixc0"
          type="number"
          options={productOptions}
          className="col-span-4 col-start-8 text-right"
        />
        <label htmlFor="classic" className="col-spa-1 ml-2 pt-1">
          bags
        </label>
        <label htmlFor="supafixc1" className="col-span-4 pt-1">
          Supafix C1
        </label>
        <Input
          register={register}
          placeholder="0"
          name="supafixc1"
          type="number"
          options={productOptions}
          className="col-span-4 col-start-8 text-right"
        />
        <label htmlFor="classic" className="col-spa-1 ml-2 pt-1">
          bags
        </label>
      </form>
      <Button
        text={buttonText}
        color="green"
        className="w-full"
        onClick={() => {
          setBlock(true);
          setShowConfirmation(true);
        }}
      />
    </Modal>
  );
};

export default FulfillOrder;
