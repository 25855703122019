import { FirebaseError } from 'firebase/app';
import {
  collection,
  doc,
  FirestoreError,
  onSnapshot,
  query,
  Query,
  Unsubscribe,
  updateDoc,
  where,
} from 'firebase/firestore';
import { useEffect, useMemo, useState } from 'react';
import { FSATarget, TAETarget } from '../../../../global';
import { docToJSON, firestore } from '../utils/firebase';
import { usePagination } from '../utils/usePagination';
import { useAuth } from './use-auth';

type TargetsHook = {
  error: FirebaseError;
  hasMore: boolean;
  load: () => void;
  loading: boolean;
  reset: () => void;
  targets: FSATarget[];
};

type TargetsHookOptions = {
  month: number;
  year: number;
  pageSize?: number;
  locked?: boolean;
  targetType?: string;
  cluster?: string;
};

export const useTargets = ({
  month,
  year,
  pageSize = 10,
  targetType,
  cluster,
}: TargetsHookOptions): TargetsHook => {
  const { id } = useAuth();
  const [targetsQuery, setTargetsQuery] = useState<Query>();
  const { error, hasMore, load, loading, reset, values } = usePagination(
    targetsQuery,
    pageSize
  );

  useEffect(() => {
    if (id && month && year && targetType && cluster) {
      setTargetsQuery(
        query(
          collection(firestore, 'targets'),
          where('userIds', 'array-contains', id),
          where('year', '==', year),
          where('month', '==', month),
          where('targetType', '==', targetType),
          where('cluster.id', '==', cluster)
        )
      );
    } else if (targetsQuery) {
      setTargetsQuery(undefined);
    }
  }, [id, year, month, targetType, cluster]);

  useEffect(() => {
    console.log(error);
  }, [error]);

  return useMemo(
    () => ({
      error,
      hasMore,
      load,
      loading,
      reset,
      targets: values ? values.map((v) => docToJSON(v) as FSATarget) : values,
    }),
    [error, hasMore, load, loading, reset, values]
  );
};

export const useTarget = (id?: string) => {
  const [loading, setLoading] = useState(false);
  const [target, setTarget] = useState<FSATarget | undefined>();
  const [error, setError] = useState<FirestoreError>();

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    if (id) {
      setLoading(true);
      const ref = doc(firestore, 'targets', id);
      unsubscribe = onSnapshot(
        ref,
        (doc) => {
          if (doc.exists()) {
            setTarget(docToJSON(doc) as FSATarget);
          } else {
            setTarget(undefined);
          }
          setLoading(false);
        },
        (error: FirestoreError) => {
          setLoading(false);
          setError(error);
        }
      );
    }
    return unsubscribe;
  }, [id]);

  const update = async (data: any) => {
    if (id) {
      try {
        setLoading(true);
        const ref = doc(firestore, 'targets', id);
        await updateDoc(ref, data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error(err);
        setError(err);
      }
    }
  };

  useEffect(() => {
    console.log(error);
  }, [error]);

  return {
    target,
    error,
    loading,
    update,
  };
};
