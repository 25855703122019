import {
  Auth,
  AuthError,
  signInWithPopup,
  browserPopupRedirectResolver,
} from 'firebase/auth';
import { useMemo, useState } from 'react';
import { googleAuthProvider } from '../utils/firebase';
import { GoogleActionHook } from './types';

export const useSignInWithGoogle = (auth: Auth): GoogleActionHook => {
  const [error, setError] = useState<AuthError>();
  const [loading, setLoading] = useState<boolean>(false);

  const signInWithGoogle = async () => {
    try {
      setLoading(true);
      await signInWithPopup(
        auth,
        googleAuthProvider,
        browserPopupRedirectResolver
      );
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err.message);
    }
  };

  const resArray: GoogleActionHook = [signInWithGoogle, loading, error];
  return useMemo<GoogleActionHook>(() => resArray, resArray);
};
