import { FirebaseError } from 'firebase/app';
import {
  collection,
  doc,
  DocumentSnapshot,
  onSnapshot,
  Query,
  query,
  Unsubscribe,
  where,
} from 'firebase/firestore';
import { useEffect, useMemo, useState } from 'react';
import {
  Site,
  CreateSiteData,
  UserChanges,
} from '../../../../global';
import { useCheckAccess } from '../auth/use-checkAccess';
import { createSite, registerSiteChanges, confirmSiteRegistration, rejectSiteRegistration, confirmSiteChanges, rejectSiteChanges, confirmSiteRejection, confirmSiteChangesRejection } from '../db/sites';

import { useLoadingValue } from '../utils';
import { docToJSON, firestore } from '../utils/firebase';
import { getTotalVolume } from '../utils/helpers';
import { usePagination } from '../utils/usePagination';
import { useAuth } from './use-auth';

export const useSites = (selectedCategory?: string, siteOwnerId?: string, pageSize = 20,) => {
  const { id } = useAuth();
  const [sitesQuery, setSitesQuery] = useState<Query>();
  const { error, hasMore, load, loading, reset, values } = usePagination(
    sitesQuery,
    pageSize
  );

  useEffect(() => {
    let siteWhere = [where('userIds', 'array-contains', id)];
    if (selectedCategory) {
      switch (selectedCategory) {
        case 'actionRequired': {
          siteWhere.push(where('status', 'in', ['changes', 'unconfirmed']));
          break;
        }
        case 'numberWrong': {
          siteWhere.push(where('siteOwner.phoneCorrect', '==', false));
          break;
        }
        case 'noWhatsApp': {
          siteWhere.push(where('siteOwner.hasWhatsApp', '==', false));
          break;
        }
        case 'enabledWhatsApp': {
          siteWhere.push(where('siteOwner.enabledWhatsApp', '==', true));
          break;
        }
        case 'phoneChanged': {
          siteWhere.push(where('siteOwner.phoneChanged', '==', true));
          break;
        }
        default: {
          break;
        }
      }
    }

    if (id && siteOwnerId) {
      siteWhere.push(where('siteOwner.id', '==', siteOwnerId));

      setSitesQuery(
        query(
          collection(firestore, 'sites'),

          ...siteWhere
        )
      );
    } else if (id) {
      setSitesQuery(
        query(
          collection(firestore, 'sites'),
          ...siteWhere
        )
      );
    } else if (sitesQuery) {
      setSitesQuery(undefined);
    }
  }, [id, selectedCategory]);

  useEffect(() => {
    console.log(error);
  }, [error]);

  return useMemo(
    () => ({
      error,
      hasMore,
      load,
      loading,
      reset,
      sites: values
        ? values.map((v) => prepareSite(docToJSON(v) as Site))
        : values,
    }),
    [error, hasMore, load, loading, reset, values]
  );
};

export const useSite = (id?: string) => {
  const checkAccess = useCheckAccess();
  const { error, loading, reset, setError, setValue, value, setLoading } =
    useLoadingValue<DocumentSnapshot, FirebaseError>();

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    if (id) {
      if (!loading) setLoading(true);
      const ref = doc(firestore, 'sites', id);
      unsubscribe = onSnapshot(ref, setValue, setError);
    }
    setValue(undefined);
    return unsubscribe;
  }, [id]);

  const create = async (data: CreateSiteData) => {
    try {
      setLoading(true);
      console.log(data);
      await createSite(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const update = async (changes: UserChanges) => {
    if (!id) return;
    try {
      setLoading(true);
      await registerSiteChanges(id, checkAccess(['sop']), changes);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
      setError(err);
    }
  };

  const confirmRegistration = async (confirmed: boolean, comment?: string) => {
    if (!id || !checkAccess(['sop'])) return;
    try {
      setLoading(true);
      confirmed
        ? await confirmSiteRegistration(id)
        : await rejectSiteRegistration(id, comment);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  };

  const confirmChanges = async (
    confirmed: boolean,
    changes: UserChanges,
    comment?: string
  ) => {
    if (!id || !checkAccess(['sop'])) return;
    try {
      setLoading(true);
      confirmed
        ? await confirmSiteChanges(id, changes)
        : await rejectSiteChanges(id, changes, comment);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  };

  const confirmRejection = async () => {
    if (!id || !checkAccess(['fsa'])) return;
    try {
      setLoading(true);
      confirmSiteRejection(id);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  };

  const confirmChangesRejection = async () => {
    if (!id || !checkAccess(['tae'])) return;
    try {
      setLoading(true);
      confirmSiteChangesRejection(id);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  };

  useEffect(() => {
    console.log(error);
  }, [error]);

  return useMemo(
    () => ({
      site: value ? prepareSite(docToJSON(value) as Site) : null,
      error,
      loading,
      create,
      update,
      confirmChanges,
      confirmRegistration,
      confirmRejection,
      confirmChangesRejection,
    }),
    [error, loading, value]
  );
};

const prepareSite = (site: Site) => {
  let fulfilledVolumeLeads = getTotalVolume(site.leadsVolumeFulfilled);
  let pendingVolume = getTotalVolume(site.leadsVolumePending);
  let acceptedVolume = getTotalVolume(site.leadsVolumeAccepted);
  let fulfilledVolume = getTotalVolume(site.leadsVolumeFulfilled);
  return {
    fulfilledVolumeLeads,
    pendingVolume,
    acceptedVolume,
    fulfilledVolume,
    ...site,
  };
};
