import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import configureStatusStore from './lib/store/status';
import configureRetailerStatusStore from './lib/store/retailerStatus';
import configureDateStore from './lib/store/date';
import configureTargetStatusStore from './lib/store/statusTarget';
import configureDateInventoryStore from './lib/store/inventoryDate';
import configureDateRoutePlanningStore from './lib/store/routePlanningDate';
import configureRolesStore from './lib/store/roles';
import configureRouteFSAStore from './lib/store/routeFSA';
import configureVisitsDateStore from './lib/store/visitsDate';
import configureSelectedClusterStore from './lib/store/selectedCluster';
import configureStatusDeliveriesStore from './lib/store/statusDeliveries';
import configureLeadStatusStore from './lib/store/statusLeads';
import configureLeadArchiveStatusStore from './lib/store/archiveStatusLeads';
import configureSelectedFSAOrdersStore from './lib/store/selectedFSAOrders';

configureStatusStore();
configureRetailerStatusStore();
configureTargetStatusStore();
configureDateStore();
configureDateInventoryStore();
configureDateRoutePlanningStore();
configureRolesStore();
configureRouteFSAStore();
configureVisitsDateStore();
configureSelectedClusterStore();
configureStatusDeliveriesStore();
configureLeadStatusStore();
configureVisitsDateStore();
configureLeadArchiveStatusStore();
configureSelectedFSAOrdersStore();

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorkerRegistration.register();
