import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Confirm } from '../forms/Confirm';
import Textarea from '../forms/Textarea';
import Button from '../ui/Button';
import Modal from '../ui/Modal';

type AssignLeadProps = {
  open: boolean;
  onAssign: Function;
  setOpen: (boolean) => void;
  distributorName: string;
};

const AssignLead = ({
  open,
  onAssign,
  setOpen,
  distributorName,
}: AssignLeadProps) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [block, setBlock] = useState(false);

  const handleOnConfirm = () => {
    setBlock(false);
    setShowConfirmation(false);
    onAssign();
  };

  const handleOnCancel = () => {
    setBlock(false);
    setShowConfirmation(false);
  };

  return (
    <Modal open={open} setOpen={setOpen} block={block}>
      <Confirm
        title="Are you sure you want to assign the lead?"
        open={showConfirmation}
        onConfirm={handleOnConfirm}
        onCancel={handleOnCancel}
        setOpen={(open) => {
          setShowConfirmation(open);
          setBlock(open);
        }}
      />
      <label className="text-center">
        {'Do you want to assign this lead to ' + distributorName + '?'}
      </label>

      <Button
        text="Assign"
        color="green"
        className="w-full"
        onClick={() => {
          setBlock(true);
          setShowConfirmation(true);
        }}
      />
    </Modal>
  );
};

export default AssignLead;
