import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRef, useState } from 'react';
import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Alert from '../../../components/ui/Alert';
import Loader from '../../../components/ui/Loader';
import { useVisit } from '../../../lib/hooks/use-visits';
import VisitStep0 from './VisitStep0';
import VisitStep1 from './VisitStep1';
import VisitStep2 from './VisitStep2';
import VisitStep3 from './VisitStep3';
import VisitStep4 from './VisitStep4';
import Content from '../../../components/layout/Content';
import Card from '../../../components/ui/Card';
import Back from '../../../components/ui/Back';
import Button from '../../../components/ui/Button';
import { useOutlet } from '../../../lib/hooks/use-outlets';
import { Timestamp } from 'firebase/firestore';
import { useUser } from '../../../lib/hooks/use-user';
import dayjs from 'dayjs';
import { getDistance } from 'geolib';
import { useLocation } from '../../../lib/hooks/use-location';
import Reload from '../../../components/ui/Reload';
import { Location } from '../../../../../global';
import numeral from 'numeral';

type VisitParams = 'id';

interface CustomHTMDIVElement extends HTMLDivElement {
  saveStock?: Function;
  savePOSM?: Function;
}

const Visit = () => {
  const RANGE = 100;
  const FINAL_STEP = 4;

  const { id } = useParams<VisitParams>();
  const { user } = useUser();
  const navigate = useNavigate();
  const [visitStep, setVisitStep] = useState<number>(0);
  const [outletId, setOutletId] = useState<string>(null);
  const [checkInError, setCheckInError] = useState<string | undefined>();
  const [showCheckInAlert, setCheckInShowAlert] = useState<boolean>(false);
  const [showAlertVisit, setShowAlertVisit] = useState<boolean>(false);
  const [showAlertOutlet, setShowAlertOutlet] = useState<boolean>(false);
  const [showAlertLocation, setShowAlertLocation] = useState<boolean>(false);
  const [stockLevel, setStockLevel] = useState(null);
  const [posm, setPOSM] = useState(null);
  const [orderPlaced, setOrderPlaced] = useState(false);
  const visitStep1Ref = useRef<CustomHTMDIVElement>(null);
  const visitStep2Ref = useRef<CustomHTMDIVElement>(null);
  const [startedAt, setStartedAt] = useState(null);
  const [visitId, setVisitId] = useState('');
  const [stockIsDirty, setStockIsDirty] = useState<boolean>(false);
  const [stockIsValid, setStockIsValid] = useState<boolean>(false);
  const [isInRange, setIsInRange] = useState<boolean>(false);
  const [locationLoading, setLocationLoading] = useState<boolean>(true);
  const [accuracyThreshold, setAccuracyThreshold] = useState<number>(20);
  const [outletLocationLoading, setOutletLocationLoading] =
    useState<boolean>(false);
  const [outletLocationCaptured, setOutletLocationCaptured] =
    useState<boolean>(false);
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [locationErrorMessage, setLocationErrorMessage] = useState<string>();
  const [visitLocation, setVisitLocation] = useState<Location>();

  const [location, accuracy, locationError, geolocationEnabled] = useLocation(
    locationLoading,
    accuracyThreshold,
    10,
    {
      enableHighAccuracy: true,
      maximumAge: 2000,
      timeout: 15000,
    }
  );

  const {
    outlet,
    loading: outletLoading,
    error: outletError,
    update: outletUpdate,
  } = useOutlet(outletId);

  const {
    visit,
    loading: visitLoading,
    error: visitError,
    add,
  } = useVisit(visitId);

  useEffect(() => {
    setShowLoader(
      !locationLoading &&
        (visitLoading || outletLoading || outletLocationLoading)
    );
  }, [locationLoading, outletLoading, outletLocationLoading, visitLoading]);

  useEffect(() => {
    if (id && id.includes('_')) {
      setOutletId(id.split('_')[1]);
    } else if (id) {
      setVisitId(id);
      setVisitStep(1);
    }
  }, [id]);

  useEffect(() => {
    if (visit) {
      setOutletId(visit.outlet.id);
      setStockLevel(visit.stockLevel);
      setPOSM(visit.posm);
    }
  }, [visit]);

  useEffect(() => {
    if (location) {
      setVisitLocation(location);
      setLocationLoading(false);
    }
  }, [location]);

  useEffect(() => {
    if (locationError) {
      if (locationError.code === 5) {
        if (accuracyThreshold < 100) {
          setAccuracyThreshold(accuracyThreshold + 20);
        } else {
          setAccuracyThreshold(null);
        }
      } else {
        setShowAlertLocation(true);
        setLocationErrorMessage(locationError.message);
        setLocationLoading(false);
      }
    }
  }, [locationError]);

  const handleNext = () => {
    if (visitStep !== FINAL_STEP) {
      setVisitStep(visitStep + 1);
    }

    if (visitStep === 1) {
      if (visitStep1Ref && visitStep1Ref.current) {
        visitStep1Ref.current.saveStock();
      }
    }

    if (visitStep === 2) {
      if (visitStep2Ref && visitStep2Ref.current) {
        visitStep2Ref.current.savePOSM();
      }
    }
  };

  const handlePrevious = () => {
    if (visitStep !== 0) {
      setVisitStep(visitStep - 1);
    }

    if (visitStep === 1) {
      if (visitStep1Ref && visitStep1Ref.current && !visit) {
        visitStep1Ref.current.saveStock();
      }
    }

    if (visitStep === 2) {
      if (visitStep2Ref && visitStep2Ref.current && !visit) {
        visitStep2Ref.current.savePOSM();
      }
    }
  };

  const visitStep1Props = {
    setStockLevel: setStockLevel,
    stockLevel: stockLevel,
  };

  const visitStep2Props = {
    setPOSM: setPOSM,
    posm: posm,
  };

  const addVisit = async () => {
    const outletLocation = outlet.location;

    await add({
      stockLevel: stockLevel,
      posm: posm,
      location: visitLocation,
      distance: getDistance(visitLocation, outletLocation),
      orderPlaced: orderPlaced,
      createdBy: {
        id: user.id,
        name: user.name,
        phone: user.phone,
      },
      sop: {
        email: user.sop.email,
        id: user.sop.id,
        name: user.sop.name,
        phone: user.sop.phone,
      },
      createdAt: Timestamp.now(),
      outlet: outlet,
      startedAt: startedAt,
      finishedAt: Timestamp.now(),
      day: dayjs().date(),
      month: dayjs().month() + 1,
      year: dayjs().year(),
    });

    navigate('/visits');
  };

  const saveNewLocation = () => {
    setOutletLocationLoading(true);
    if (visitLocation) {
      outletUpdate({ location: visitLocation, firstCapture: true });
      setOutletLocationCaptured(true);
    }
    setOutletLocationLoading(false);
  };

  const reload = () => {
    setAccuracyThreshold(10);
    setLocationLoading(true);
  };

  const checkIn = () => {
    const distance = getDistance(visitLocation, outlet.location);
    if (distance > RANGE) {
      const distanceString =
        distance > 1000
          ? numeral(distance).divide(1000).format('0.0') + ' kilometers'
          : distance + ' meters';
      setIsInRange(false);
      setCheckInError(
        `You are ${distanceString} away from the outlet. You have to be within ${RANGE} meters to check in.`
      );
      setCheckInShowAlert(true);
      return;
    }
    setStartedAt(Timestamp.now());
    setCheckInError(undefined);
    setIsInRange(true);
    handleNext();
  };

  return (
    <Content>
      <Loader show={showLoader} />
      <Loader
        show={locationLoading}
        text="Loading location, please wait..."
        transparent={false}
      />
      {!isInRange && <Reload onClick={reload} blocked={false} color="red" />}
      <Alert
        message={visitError && 'Error while loading visit!'}
        open={showAlertVisit}
        setOpen={(open) => setShowAlertVisit(open)}
        title="Error"
      />
      <Alert
        message={outletError && 'Error while loading outlet!'}
        open={showAlertOutlet}
        setOpen={(open) => setShowAlertOutlet(open)}
        title="Error"
      />
      <Alert
        message={locationErrorMessage}
        open={showAlertLocation}
        setOpen={(open) => setShowAlertLocation(open)}
        title="Location Error"
      />
      <Alert
        message={checkInError}
        open={showCheckInAlert}
        setOpen={(open) => setCheckInShowAlert}
        title="Check-in Error"
      />
      {visitLoading || outlet ? (
        <Card>
          <div className="grid grid-cols-12">
            {(visitStep === 0 || visit) && (
              <Back to="/visits" className=" col-span-3" />
            )}
            <div className="grid grid-cols-9 col-span-12 gap-2">
              <div className="grid grid-rows-2 col-span-9 text-center">
                <h2 className="font-bold row-span-1 -mt-4">
                  {outlet && outlet.name}
                </h2>
              </div>
              {!visit && (
                <VisitStep0
                  currentStep={visitStep}
                  captureOutletLocation={saveNewLocation}
                  location={location}
                  outlet={outlet}
                  outletLocationCaptured={outletLocationCaptured}
                  outletLocationLoading={false}
                />
              )}
              {visitStep === 1 && (
                <VisitStep1
                  ref={visitStep1Ref}
                  {...visitStep1Props}
                  editable={visit ? true : false}
                  setStockIsDirty={setStockIsDirty}
                  setStockIsValid={setStockIsValid}
                />
              )}
              {visitStep === 2 && (
                <VisitStep2
                  ref={visitStep2Ref}
                  {...visitStep2Props}
                  editable={visit ? true : false}
                />
              )}
              <VisitStep3
                currentStep={visitStep}
                outlet={outlet}
                setOrderPlaced={setOrderPlaced}
              />
              <VisitStep4
                currentStep={visitStep}
                changeStep={(step) => setVisitStep(step)}
                step1Done={stockLevel ? true : false}
                step2Done={
                  posm && !Object.values(posm).every((v) => v === false)
                    ? true
                    : false
                }
                step3Done={orderPlaced}
              />
              {visitStep === 0 &&
                (outlet?.location || outlet?.changes?.location) && (
                  <Button
                    buttonDisabled={locationLoading || !geolocationEnabled}
                    className="col-span-7 col-start-2"
                    onClick={checkIn}
                    text={'Click to Check-In'}
                  />
                )}
              {visitStep === FINAL_STEP && (
                <Button
                  buttonDisabled={
                    !(
                      (stockLevel ? true : false) &&
                      (posm && !Object.values(posm).every((v) => v === false)
                        ? true
                        : false)
                    )
                  }
                  className="col-span-7 col-start-2"
                  onClick={() => addVisit()}
                  text={'Click to Check-Out'}
                />
              )}
              {((visitStep !== 1 && visitStep !== 0 && !visit) ||
                (visit &&
                  visit.stockLevel &&
                  visitStep !== 0 &&
                  visitStep !== 1)) && (
                <button
                  className="col-span-4 mt-12 font-semibold"
                  onClick={() => handlePrevious()}
                >
                  <FontAwesomeIcon icon={faAngleLeft} /> Go back
                </button>
              )}
              {((visitStep !== 0 &&
                visitStep !== FINAL_STEP &&
                visitStep !== 1 &&
                !visit) ||
                (visitStep !== 0 &&
                  visitStep !== 3 &&
                  visitStep !== 2 &&
                  visit) ||
                (visitStep === 1 && stockIsDirty && stockIsValid)) && (
                <button
                  className={
                    'col-span-4 col-start-5 text-right mt-12 font-semibold'
                  }
                  onClick={() => {
                    handleNext();
                  }}
                >
                  Next step <FontAwesomeIcon icon={faAngleRight} />
                </button>
              )}
              {visitStep === 1 &&
                !visit &&
                ((!stockIsDirty && !stockIsValid && stockLevel == null) ||
                  (!stockIsDirty &&
                  stockLevel &&
                  !Object.values(stockLevel).every((v) => !isNaN(v as number))
                    ? true
                    : false)) && (
                  <button
                    className={
                      'col-span-4 col-start-5 text-right mt-12 font-semibold'
                    }
                    onClick={() => {
                      handleNext();
                    }}
                  >
                    No stock <FontAwesomeIcon icon={faAngleRight} />
                  </button>
                )}
            </div>
          </div>
        </Card>
      ) : (
        <Card>Outlet not found!</Card>
      )}
    </Content>
  );
};

export default Visit;
