import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { PageDirection } from '../../../../global';
import Delivery from '../../components/deliveries/Delivery';
import Content from '../../components/layout/Content';
import TopBar from '../../components/layout/TopBar';
import Alert from '../../components/ui/Alert';
import List from '../../components/ui/list/List';
import Loader from '../../components/ui/Loader';
import Reload from '../../components/ui/Reload';
import { useDeliveries } from '../../lib/hooks/use-deliveries';
import useStore from '../../lib/hooks/use-store';

const Deliveries = React.memo(() => {
  const [direction, setDirection] = useState<PageDirection>('asc');
  const [state, dispatch] = useStore();
  const { error, deliveries, loading, load, hasMore, reset } = useDeliveries({
    status: state.statusDeliveries,
    direction,
  });
  const [showAlert, setShowAlert] = useState(false);

  const handleStatusChange = (newStatus) => () => {
    if (newStatus === 'archived') {
      dispatch('SET_STATUS_DELIVERIES', ['confirmed', 'rejected']);
    } else {
      dispatch('SET_STATUS_DELIVERIES', [newStatus]);
    }
  };

  const handleDirectionChange = () => {
    if (direction === 'asc') {
      setDirection('desc');
    } else {
      setDirection('asc');
    }
  };

  useEffect(() => {
    if (error) {
      setShowAlert(true);
    }

    return () => {
      setShowAlert(false);
    };
  }, [error]);

  return (
    <Content topBar>
      <Reload onClick={reset} blocked={loading} />
      <button
        onClick={handleDirectionChange}
        className="rounded-full flex justify-center items-center h-8 w-8 fixed top-5 right-20 text-xl z-30"
      ></button>
      <Loader show={loading && deliveries.length === 0} />
      <Alert
        message={error && error.message}
        open={showAlert}
        setOpen={(open) => setShowAlert(open)}
        title="Error"
      />
      <TopBar>
        <div
          className={clsx(
            'flex justify-center content-center flex-col text-center flex-grow cursor-pointer text-lh-dark-blue text-xl font-medium border-b-2 border-lh-dark-blue h-full',
            state.statusDeliveries.includes('open') &&
              'border-b-4 border-lh-dark-blue font-semibold'
          )}
          onClick={handleStatusChange('open')}
        >
          Open
        </div>
        <div
          className={clsx(
            'flex justify-center content-center flex-col text-center flex-grow cursor-pointer text-lh-dark-blue text-xl font-medium border-b-2 border-lh-dark-blue h-full',
            state.statusDeliveries.includes('confirmed') &&
              'border-b-4 border-lh-dark-blue font-semibold'
          )}
          onClick={handleStatusChange('archived')}
        >
          Archive
        </div>
      </TopBar>
      <List load={load} hasMore={hasMore} loading={loading}>
        {deliveries && deliveries.length > 0 ? (
          deliveries.map((delivery) => (
            <Delivery delivery={delivery} key={delivery.id} />
          ))
        ) : (
          <p className="justify-between mx-2 mb-2 h-18 list-none shadow-md rounded-lg pb-4 px-2 pt-2 bg-white text-center">
            No {state.status.join(' / ')} deliveries.
          </p>
        )}
      </List>
    </Content>
  );
});

export default Deliveries;
