import { useState } from 'react';
import { Route } from '../../../../global';
import { useRoutes } from '../../lib/hooks/use-routes';
import { Confirm } from '../forms/Confirm';
import Select from 'react-select';
import Button from '../ui/Button';
import Modal from '../ui/Modal';

type AssignRouteProps = {
  open: boolean;
  onAssign: (route: Route) => void;
  setOpen: (boolean) => void;
  fsaId: string;
};

const AssingRoute = ({ open, onAssign, setOpen, fsaId }: AssignRouteProps) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [block, setBlock] = useState(false);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const { error, loading, routes } = useRoutes({ fsaId });

  const handleOnAssign = () => {
    setBlock(false);
    setShowConfirmation(false);
    if (selectedRoute) {
      onAssign(routes.find((route) => route.id === selectedRoute.value));
    }
  };

  const handleOnCancel = () => {
    setBlock(false);
    setShowConfirmation(false);
  };

  return (
    <Modal open={open} setOpen={setOpen} block={block}>
      <Confirm
        title="Are you sure you want to accept the order?"
        open={showConfirmation}
        onConfirm={handleOnAssign}
        onCancel={handleOnCancel}
        setOpen={(open) => {
          setShowConfirmation(open);
          setBlock(open);
        }}
      />
      <form name="acceptOrderForm" className="flex flex-col">
        <label className="font-bold mb-2">Route: </label>
        <Select
          options={
            routes
              ? routes.map((route) => ({
                  value: route.id,
                  label: route.name,
                }))
              : []
          }
          placeholder={'Select Route...'}
          onChange={(route) => setSelectedRoute(route)}
          value={selectedRoute}
          defaultValue={selectedRoute}
          className="col-span-12 text-lh-text-black"
        />
      </form>
      <Button
        text="Assign"
        color="green"
        className="w-full"
        onClick={() => {
          if (selectedRoute) {
            setBlock(true);
            setShowConfirmation(true);
          }
        }}
      />
    </Modal>
  );
};

export default AssingRoute;
