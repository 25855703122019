import React from 'react';

type ListItemProps = {
  onClick?: () => void;
  children: React.ReactNode | React.ReactNode[];
};

const ListItem = ({ onClick, children }: ListItemProps) => {
  return (
    <li
      className="grid grid-cols-10 mx-2 mb-2 list-none shadow-md rounded-lg pb-4 px-2 pt-2 cursor-pointer"
      onClick={onClick}
    >
      {children}
    </li>
  );
};

export default ListItem;
