import { addDoc, collection, doc, updateDoc } from 'firebase/firestore';
import { CreateOrderData, UpdateOrderData } from '../../../../global';
import { firestore } from '../utils/firebase';

export const createOrder = async (data: CreateOrderData): Promise<void> => {
  const ref = collection(firestore, 'orders');
  await addDoc(ref, data);
};

export const updateOrder = async (
  id: string,
  data: UpdateOrderData
): Promise<void> => {
  const ref = doc(firestore, 'orders', id);
  await updateDoc(ref, data);
};
