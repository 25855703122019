import {
  faCheckCircle,
  faClock,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ConfirmDelivery from '../../../components/deliveries/ConfirmDelivery';
import RejectDelivery from '../../../components/deliveries/RejectDelivery';
import Content from '../../../components/layout/Content';
import Alert from '../../../components/ui/Alert';
import Back from '../../../components/ui/Back';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import Loader from '../../../components/ui/Loader';
import { useCheckAccess } from '../../../lib/auth/use-checkAccess';
import { useDelivery } from '../../../lib/hooks/use-deliveries';

type DeliveryDetailsParams = 'id';

const Delivery = () => {
  const checkAccess = useCheckAccess();
  const { id } = useParams<DeliveryDetailsParams>();
  const navigate = useNavigate();
  const { confirm, delivery, error, loading, reject } = useDelivery(id);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [showReject, setShowReject] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);

  useEffect(() => {
    if (error) {
      setShowAlert(true);
    }
  }, [error]);

  const handleConfirm = () => {
    confirm();
    navigate('/deliveries', { replace: true });
  };

  const handleReject = (data) => {
    reject(data.comment);
    navigate('/deliveries', { replace: true });
  };

  return (
    <Content>
      <Alert
        message={error && error.message}
        open={showAlert}
        setOpen={(open) => setShowAlert(open)}
        title="Error"
      />
      {checkAccess(['sop']) && (
        <ConfirmDelivery
          open={showConfirm}
          onConfirm={handleConfirm}
          setOpen={(open) => setShowConfirm(open)}
        />
      )}
      {checkAccess(['sop']) && (
        <RejectDelivery
          open={showReject}
          onReject={handleReject}
          setOpen={(open) => setShowConfirm(open)}
        />
      )}
      <Card>
        <Back to="/deliveries" />
        <Loader show={loading} />
        {delivery ? (
          <>
            <div className="flex justify-between">
              <div>
                <h2 className="font-bold text-lh-head-black">
                  {delivery.distributor.name}
                </h2>
                <p>{dayjs(delivery.deliveryDate).format('DD.MM.YYYY HH:mm')}</p>
              </div>
              <div className="col-span-2 flex flex-col items-center font-bold text-lg text-lh-head-black">
                <p>Status</p>
                {delivery.status === 'open' && (
                  <FontAwesomeIcon icon={faClock} className="text-2xl mt-0" />
                )}
                {delivery.status === 'confirmed' && (
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="text-2xl mt-0 text-green-600"
                  />
                )}
                {delivery.status === 'rejected' && (
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    className="text-2xl mt-0 text-red-600"
                  />
                )}
              </div>
            </div>
            <hr className="mt-2 mb-2" />
            <div className="flex justify-between mb-2">
              <h2 className="font-bold text-lh-head-black">Products</h2>
              <h2 className="font-bold text-lh-head-black">Quantity</h2>
            </div>
            <div className="flex justify-between">
              <h3 className="text-lh-text-black">
                {delivery.productNameInternal}
              </h3>
              <p>
                {delivery.quantity}{' '}
                <span className="text-gray-500 font-thin">tons</span>
              </p>
            </div>
            <hr className="mb-2 mt-2" />
            <div className="flex justify-between mb-2">
              <h3 className="text-lh-head-black font-bold">LOGON Number</h3>
              <p className="text-lh-text-black">{delivery.LOGONOrderNumber}</p>
            </div>
            <div className="flex justify-between mb-2">
              <h3 className="text-lh-head-black font-bold">
                Truck Plate Number
              </h3>
              <p className="text-lh-text-black">{delivery.truckPlateNumber}</p>
            </div>
            <h2 className="font-bold mb-2 text-lh-head-black">Driver Name</h2>
            <p className="mb-2 text-lh-text-black">{delivery.driver.name}</p>
            <h2 className="font-bold mb-2 text-lh-head-black">Driver Phone</h2>
            <p className="mb-2 text-lh-text-black">{delivery.driver.phone}</p>
            {delivery.status === 'open' && checkAccess(['sop']) && (
              <>
                <Button
                  onClick={() => setShowConfirm(true)}
                  className="w-full mt-2 py-2"
                  color="green"
                  type="button"
                >
                  Confirm Delivery
                </Button>
                <Button
                  onClick={() => setShowReject(true)}
                  className="w-full mt-2 py-2"
                  color="red"
                  type="button"
                >
                  Reject Delivery
                </Button>
              </>
            )}
          </>
        ) : (
          <div className="flex justify-center">
            <p className="text-lh-text-black">No Delivery Found</p>
          </div>
        )}
      </Card>
    </Content>
  );
};

export default Delivery;
