import { geohashForLocation } from 'geofire-common';
import { useEffect, useState } from 'react';
import { Location } from '../../../../global';

type LocationError = {
  code: number;
  message: string;
};

export const useLocation = (
  enabled: boolean,
  accuracyThreshold?: number,
  accuracyThresholdWaitTime?: number,
  options?: PositionOptions
): [
  Location | undefined,
  number | undefined,
  LocationError | undefined,
  boolean
] => {
  const [accuracy, setAccuracy] = useState<number>();
  const [location, setLocation] = useState<Location>();
  const [error, setError] = useState<LocationError | undefined>();
  const [geolocationEnabled, setGeolocationEnabled] = useState<boolean>(true);

  useEffect(() => {
    if (!enabled) {
      setAccuracy(undefined);
      setError(undefined);
      setLocation(undefined);
      return;
    }
    if (navigator.geolocation) {
      let timeout: NodeJS.Timeout | undefined;
      const geoId = navigator.geolocation.watchPosition(
        (position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          const locationAccuracy = position.coords.accuracy;
          setAccuracy(locationAccuracy);

          if (
            accuracyThreshold == null ||
            position.coords.accuracy <= accuracyThreshold
          ) {
            const geohash = geohashForLocation([lat, lng]);
            setLocation({ lat, lng, geohash, accuracy: locationAccuracy });
          }
        },
        (e: GeolocationPositionError) => {
          const code = e.code;

          switch (code) {
            case 1:
              setError({
                code: 1,
                message:
                  'You have not allowed the REAL app to track your geolocation. Please allow the REAL app to track your geolocation to perform your requested action.',
              });
              setGeolocationEnabled(false);
              break;
            case 2:
              setError({
                code: 2,
                message: 'Location information is unavailable.',
              });
              break;
            case 3:
              setError({
                code: 3,
                message: 'The request to get user location timed out.',
              });
              break;
            default:
              setError({
                code: 4,
                message: e.message,
              });
              break;
          }
        },
        options ?? {
          enableHighAccuracy: true,
          maximumAge: 2000,
          timeout: 10000,
        }
      );
      if (accuracyThreshold && accuracyThresholdWaitTime) {
        timeout = setTimeout(() => {
          if (!accuracy || accuracy > accuracyThreshold) {
            setError({ code: 5, message: 'Failed to reach desired accuracy' });
          }
        }, accuracyThresholdWaitTime * 1000);
      }
      return () => {
        window.navigator.geolocation.clearWatch(geoId);
        if (timeout) {
          clearTimeout(timeout);
        }
      };
    }

    setError({ code: 0, message: 'Geolocation API not available' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled, accuracyThresholdWaitTime, accuracyThreshold, options]);

  if (!enabled) {
    return [undefined, undefined, undefined, geolocationEnabled];
  }

  return [location, accuracy, error, geolocationEnabled];
};
