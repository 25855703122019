import { initStore } from '../hooks/use-store';

type StatusDeliveries = 'open' | 'archived';

const configureStatusDeliveriesStore = () => {
  const actions = {
    SET_STATUS_DELIVERIES: (curState, statusDeliveries: StatusDeliveries[]) => {
      return { ...curState, statusDeliveries };
    },
  };
  initStore(actions, { statusDeliveries: ['open'] });
};

export default configureStatusDeliveriesStore;
