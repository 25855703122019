import { useParams } from 'react-router-dom';
import Alert from '../../../components/ui/Alert';
import Card from '../../../components/ui/Card';
import { useTarget } from '../../../lib/hooks/use-targets';
import Back from '../../../components/ui/Back';
import Loader from '../../../components/ui/Loader';
import Content from '../../../components/layout/Content';
import { faBullseye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';

//Months
const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

type TargetDetailsParams = 'id';

const Target = () => {
  const { id } = useParams<TargetDetailsParams>();
  const { target, error, loading } = useTarget(id);
  const [showAlert, setShowAlert] = useState<boolean>(false);

  useEffect(() => {
    if (error) {
      setShowAlert(true);
    } else if (showAlert) {
      setShowAlert(false);
    }
    return () => {
      setShowAlert(false);
    };
  }, [error]);

  return (
    <Content>
      <Alert
        title="Error"
        message={error && error.message}
        open={showAlert}
        setOpen={(open) => setShowAlert(open)}
      />
      <Loader show={loading} />
      {target ? (
        <Card>
          <div className="grid grid-cols-12">
            <Back to="/targets" className="col-span-3" />
            <div className="col-span-6 flex justify-center items-center text-center text-xl font-bold text-lh-head-black">
              {target && (
                <FontAwesomeIcon
                  icon={faBullseye}
                  className="mr-2 text-lh-head-black"
                />
              )}
              {monthNames[target.month - 1]} {target.year}
            </div>

            <h2 className="col-span-full text-left text-lh-head-black text-lg">
              {target && target.fsa
                ? target.fsa.name
                : target.distributor
                ? target.distributor.name
                : ''}
            </h2>
            <hr className="col-span-full mt-2 mb-4" />
            {target.targetType === 'fsa' && (
              <>
                <h2 className="col-span-6 text-lh-text-black">Target visit:</h2>
                <h2 className="col-span-6 text-lh-head-black text-right font-semibold">
                  {isNaN(target.visitTarget) ? 0 : target.visitTarget}{' '}
                  <span className="text-gray-500 font-light">tons</span>
                </h2>
              </>
            )}
            <h2 className="col-span-6 text-lh-text-black">Target cement:</h2>
            <h2 className="col-span-6 text-lh-head-black text-right font-semibold">
              {isNaN(target.cementTarget) ? 0 : target.cementTarget}{' '}
              <span className="text-gray-500 font-light">tons</span>
            </h2>
            <h2 className="col-span-6 text-lh-text-black">Target mortar:</h2>
            <h2 className="col-span-6 text-lh-head-black text-right font-semibold">
              {isNaN(target.mortarTarget) ? 0 : target.mortarTarget}{' '}
              <span className="text-gray-500 font-light">tons</span>
            </h2>
          </div>
        </Card>
      ) : (
        <Card>Target not found!</Card>
      )}
    </Content>
  );
};

export default Target;
