import { doc, GeoPoint, getDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { RegisterOptions, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { OutletStatus } from '../../../../global';
import { Confirm } from '../../components/forms/Confirm';
import Input from '../../components/forms/Input';
import Select from '../../components/forms/Select';
import Content from '../../components/layout/Content';
import Alert from '../../components/ui/Alert';
import Back from '../../components/ui/Back';
import Button from '../../components/ui/Button';
import Card from '../../components/ui/Card';
import Loader from '../../components/ui/Loader';
import { useCheckAccess } from '../../lib/auth/use-checkAccess';
import { useSiteOwner } from '../../lib/hooks/use-siteOwners';
import { useUser } from '../../lib/hooks/use-user';
import { firestore } from '../../lib/utils/firebase';
import { DevTool } from '@hookform/devtools';
import Marker from '../../components/map/Marker';
import { Status, Wrapper } from '@googlemaps/react-wrapper';
import Map from '../../components/map/Map';
import { useSite } from '../../lib/hooks/use-sites';
import { useAssociates } from '../../lib/hooks/use-associates';
import SearchableSelect from '../../components/forms/SearchableSelect';
import { useLocation } from '../../lib/hooks/use-location';

const render = (status: Status) => {
  return <h1>{status}</h1>;
};

type Option = {
  value: string;
  label: string;
};

type NewSiteForm = {
  clusterId: string;
  name: string;
  phone: string;
  primaryTAEId: Option;
  category: string;
  siteName: string;
  siteType: string;
  lat: number;
  lng: number;
  geohash: string;
  accuracy: number;
};

const CATEGORY_TYPES = [
  {
    key: 'ihb',
    label: 'IHB',
    value: 'ihb',
  },
  {
    key: 'site_engineer',
    label: 'Site Engineer',
    value: 'site_engineer',
  },
  {
    key: 'developer',
    label: 'Developer',
    value: 'developer',
  },
  {
    key: 'block_maker',
    label: 'Block Maker',
    value: 'block_maker',
  },
  {
    key: 'tilers',
    label: 'Tilers (Artisan)',
    value: 'tilers',
  },
];

const SITE_TYPES = [
  { key: 'commercial', label: 'Commercial', value: 'commercial' },
  { key: 'private', label: 'Private', value: 'private' },
];

const NewSite = () => {
  const { user, loading: userLoading, error: userError } = useUser();
  const checkAccess = useCheckAccess();
  const [siteOwnerId, setSiteOwnerId] = useState<string>();
  const {
    create: siteOwnerCreate,
    update: siteOwnerUpdate,
    siteOwner,
    loading: siteOwnerLoading,
    error: siteOwnerError,
  } = useSiteOwner(siteOwnerId);
  const {
    associates: taes,
    error: taesError,
    loading: taesLoading,
  } = useAssociates({
    roles: 'tae',
  });
  const { create: siteCreate } = useSite();
  const navigate = useNavigate();
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [showCreateSite, setShowCreateSite] = useState<boolean>(false);

  const {
    register,
    formState: { errors, isValid },
    setValue,
    getValues,
    handleSubmit,
    control,
    reset,
    trigger,
  } = useForm<NewSiteForm>({ mode: 'all' });

  const [showUserAlert, setShowUserAlert] = useState<boolean>(false);
  const [showRetailerAlert, setShowRetailerAlert] = useState<boolean>(false);
  const [showLocationAlert, setShowLocationAlert] = useState<boolean>(false);
  const [geohashLoading, setGeohashLoading] = useState<boolean>(false);
  const [geohashCaptured, setGeoHashCaptured] = useState<boolean>(false);
  const [zoom, setZoom] = useState(13); // initial zoom
  const [center, setCenter] = useState<google.maps.LatLngLiteral>({
    lat: 0,
    lng: 0,
  });
  const [marker, setMarker] = useState<google.maps.LatLngLiteral>();
  const [cannotUsePhoneMessage, setCannotUsePhoneMessage] = useState<string>();
  const [showCannotUsePhone, setShowCannotUsePhone] = useState<boolean>(false);
  const [location, accuracy, locationError] = useLocation(
    geohashLoading,
    100,
    60
  );

  const [locationErrorMessage, setLocationErrorMessage] = useState<string>();

  const phoneOptions: RegisterOptions = {
    required: 'Please enter a phone number!',
    pattern: {
      value: /^\+[0-9]{11,13}$/,
      message: 'Please enter a valid phone number!',
    },
  };

  const nameOptions: RegisterOptions = {
    required: 'Please enter a name!',
    minLength: {
      value: 3,
      message: 'Please enter a name with at least 3 characters!',
    },
    maxLength: {
      value: 50,
      message: 'The name cannot exceed 50 characters!',
    },
  };

  const siteNameOptions: RegisterOptions = {
    required: 'Please enter a site name!',
    minLength: {
      value: 3,
      message: 'Please enter a site name with at least 3 characters!',
    },
    maxLength: {
      value: 50,
      message: 'The site name cannot exceed 50 characters!',
    },
  };

  const categoryOptions: RegisterOptions = {
    required: 'Please select a category type!',
  };

  const siteTypeOptions: RegisterOptions = {
    required: 'Please select a site type!',
  };

  const locationOptions: RegisterOptions = {
    required: 'Please register your current location!',
  };

  const onIdle = (m: google.maps.Map) => {
    setZoom(m.getZoom()!);
    setCenter(m.getCenter()!.toJSON());
  };

  const handleOnConfirm = () => {
    setShowConfirmation(false);
    handleSubmit(submitHandler)();
  };

  const handleOnCancel = () => {
    setShowConfirmation(false);
  };

  const handleSiteOnConfirm = async () => {
    setShowCreateSite(false);
    const data = getValues();

    let status = checkAccess(['sop']) ? 'confirmed' : 'unconfirmed';
    const active = checkAccess(['sop']) ? true : false;
    const sop = user.roles.includes('sop') ? user : user.sop;

    const primaryTAE = user.roles.includes('tae')
      ? user
      : taes.find((ass) => ass.id === data.primaryTAEId.value);

    await siteCreate({
      active,
      type: data.siteType,
      location: {
        geohash: data.geohash,
        lat: data.lat,
        lng: data.lng,
        accuracy: data.accuracy,
      },
      primaryTAE: {
        id: primaryTAE.id,
        name: primaryTAE.name,
        phone: primaryTAE.phone,
      },
      name: data.siteName,
      siteOwner: {
        id: siteOwner.id,
        name: siteOwner.name,
        phone: siteOwner.phone,
        siteOwnerCategory: siteOwner.category,
      },
      sop: {
        email: sop.email,
        id: sop.id,
        name: sop.name,
        phone: sop.phone,
      },
      status: status as OutletStatus,
      userIds: [siteOwner.id],
    });

    if (!checkAccess(['sop'])) siteOwnerUpdate({ siteChanged: true });
    if (!siteOwnerError) navigate('/siteOwners', { replace: true });
  };

  const handleSiteOnCancel = () => {
    setShowCreateSite(false);
  };

  const submitHandler = async (data: NewSiteForm) => {
    if (!data.geohash || !data.lat || !data.lng) return;
    const phoneRef = doc(firestore, 'phones', data.phone);
    const phoneSnap = await getDoc(phoneRef);
    if (phoneSnap.exists()) {
      if (!phoneSnap.data()!.roles.includes('siteOwner')) {
        setCannotUsePhoneMessage('This phone number cannot be used!');
        setShowCannotUsePhone(true);
        return;
      }
      setSiteOwnerId(phoneSnap.data().id);
      setShowCreateSite(true);
      return;
    }

    const cluster =
      'clusters' in user
        ? user.clusters.find((cluster) => cluster.id === data.clusterId)
        : user.cluster;
    if (!cluster) return;

    const primaryTAE = user.roles.includes('tae')
      ? user
      : taes.find((ass) => ass.id === data.primaryTAEId.value);

    const sop = user.roles.includes('sop') ? user : user.sop;

    await siteOwnerCreate({
      siteName: data.siteName,
      siteType: data.siteType,
      primaryTAE: {
        id: primaryTAE.id,
        phone: primaryTAE.phone,
        name: primaryTAE.name,
      },
      sop: {
        email: sop.email,
        id: sop.id,
        name: sop.name,
        phone: sop.phone,
      },
      category: data.category,
      location: {
        geohash: data.geohash,
        lat: data.lat,
        lng: data.lng,
      },
      name: data.name,
      phone: data.phone,
    });

    if (!siteOwnerError) navigate('/siteOwners', { replace: true });
  };

  useEffect(() => {
    if (user) {
      const clusterId =
        'clusters' in user ? user.clusters[0].id : user.cluster.id;
      reset({ clusterId });
    }
  }, [user]);

  useEffect(() => {
    if (location) {
      const { lat, lng, geohash } = location;
      setValue('lat', lat, { shouldDirty: true });
      setValue('lng', lng, { shouldDirty: true });
      setValue('geohash', geohash, { shouldDirty: true });
      setValue('accuracy', accuracy, { shouldDirty: true });
      setMarker({ lat, lng });
      setCenter({ lat, lng });
      setGeohashLoading(false);
      setGeoHashCaptured(true);
      trigger(['lat', 'lng', 'geohash', 'accuracy']);
    }

    if (locationError) {
      setLocationErrorMessage(locationError.message);
      setShowLocationAlert(true);
      setGeohashLoading(false);
    }
    if (accuracy) {
      console.log(`Accuracy: ${accuracy}`);
    }
  }, [location, locationError, accuracy]);

  return (
    <Content>
      <Loader show={userLoading || siteOwnerLoading || geohashLoading} />
      <Alert
        message={userError && userError.message}
        open={showUserAlert}
        setOpen={(open) => setShowUserAlert(open)}
        title="Error"
      />
      <Alert
        message={siteOwnerError && siteOwnerError.message}
        open={showRetailerAlert}
        setOpen={(open) => setShowRetailerAlert(open)}
        title="Error"
      />
      <Alert
        message={cannotUsePhoneMessage}
        open={showCannotUsePhone}
        setOpen={(open) => setShowCannotUsePhone(open)}
        title="Error"
      />
      <Alert
        message={locationErrorMessage}
        open={showLocationAlert}
        setOpen={(open) => setShowLocationAlert(open)}
        title="Error"
      />
      <Confirm
        title="Are you sure you want to create this construction site? This may take a few minutes."
        open={showConfirmation}
        onConfirm={handleOnConfirm}
        onCancel={handleOnCancel}
        setOpen={setShowConfirmation}
      />
      <Confirm
        title={`A customer with the phone number ${
          getValues().phone
        } is already registered. Would you like to add a construction site instead?`}
        open={showCreateSite}
        onConfirm={handleSiteOnConfirm}
        onCancel={handleSiteOnCancel}
        setOpen={setShowCreateSite}
      />
      <Card>
        <Back to="/siteOwners" className="col-span-4"></Back>
        <form
          className="col-span-full flex justify-center flex-col"
          onSubmit={(event) => event.preventDefault()}
        >
          <label
            htmlFor="phone"
            className="font-bold col-span-12 mb-2 text-lh-head-black"
          >
            Phone
          </label>
          <Input
            register={register}
            name="phone"
            type="tel"
            placeholder="+234XXXXXXXXXX"
            error={errors.phone}
            options={phoneOptions}
          ></Input>
          <label
            htmlFor="name"
            className="font-bold col-span-12 mb-2 text-lh-head-black"
          >
            Customer name
          </label>
          <Input
            register={register}
            name="name"
            type="text"
            placeholder="Name..."
            error={errors.name}
            options={nameOptions}
          ></Input>
          <label
            htmlFor="siteName"
            className="font-bold col-span-12 mb-2 text-lh-head-black"
          >
            Category
          </label>
          <Select
            register={register}
            name="category"
            items={CATEGORY_TYPES}
            placeholder="Select category..."
            error={errors.category}
            options={categoryOptions}
            className="mb-5"
          />
          <label
            htmlFor="siteName"
            className="font-bold col-span-12 mb-2 text-lh-head-black"
          >
            Site Name
          </label>
          <Input
            register={register}
            name="siteName"
            type="text"
            placeholder="Site Name..."
            error={errors.siteName}
            options={siteNameOptions}
          ></Input>
          <label
            htmlFor="siteName"
            className="font-bold col-span-12 mb-2 text-lh-head-black"
          >
            Site type
          </label>
          <Select
            register={register}
            name="siteType"
            items={SITE_TYPES}
            placeholder="Select type..."
            error={errors.siteType}
            options={siteTypeOptions}
            className="mb-5"
          />
          {checkAccess(['sop']) && (
            <SearchableSelect
              control={control}
              name="primaryTAEId"
              rules={{ required: true }}
              label="TAE"
              placeholder="Select TAE..."
              values={
                taes
                  ? taes
                      .filter(
                        (tae) => tae.cluster.id === getValues('clusterId')
                      )
                      .map((tae) => ({
                        value: tae.id,
                        key: tae.id,
                        label: tae.name,
                      }))
                  : []
              }
              className="col-span-12 text-lh-text-black"
            />
          )}

          <Button
            onClick={() => setGeohashLoading(true)}
            text={
              geohashCaptured ? 'Re-Capture Location' : 'Capture Site Location'
            }
            color="green"
            className="mt-4"
          />
          <input {...register('geohash', locationOptions)} hidden />
          <input {...register('lat', locationOptions)} hidden />
          <input {...register('lng', locationOptions)} hidden />
          <input {...register('accuracy', locationOptions)} hidden />
          {geohashCaptured && (
            <p className="text-green-500 font-thin text-sm">
              The location has ben captured!
            </p>
          )}
          {geohashCaptured && (
            <div className="col-span-full h-56 flex mt-4">
              <Wrapper
                apiKey={process.env.REACT_APP_MAPS_API_KEY}
                render={render}
              >
                <Map
                  center={center}
                  onIdle={onIdle}
                  zoom={zoom}
                  className="flex-grow h-full rounded-lg"
                  disableDefaultUI
                >
                  ={marker && <Marker position={marker} />}
                </Map>
              </Wrapper>
            </div>
          )}
          <Button
            buttonDisabled={!isValid}
            text="Register Site"
            color="blue"
            onClick={() => setShowConfirmation(true)}
            className="mt-4"
          />
        </form>
        <DevTool control={control} />
      </Card>
    </Content>
  );
};

export default NewSite;
