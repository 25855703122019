import { useState } from 'react';
import Content from '../../components/layout/Content';
import TopBar from '../../components/layout/TopBar';
import List from '../../components/ui/list/List';
import SearchAndSort from '../../components/ui/SearchAndSort';
import User from '../../components/users/User';
import { useAssociates } from '../../lib/hooks/use-associates';
import useStore from '../../lib/hooks/use-store';

const Users = () => {
  const [state, dispatch] = useStore();
  const { associates, error, loading, load, hasMore } = useAssociates({
    roles: state.roles,
  });

  const onRoleChange = (event) => {
    if (event.target.value === 'all') {
      dispatch('SET_ROLES', ['fsa', 'distributor']);
    } else {
      dispatch('SET_ROLES', [event.target.value]);
    }
  };
  const handleSelectedRole = () => {
    if (!state.roles.includes('fsa')) return 'distributor';
    if (!state.roles.includes('distributor')) return 'fsa';
    return '';
  };

  return (
    <Content>
      <SearchAndSort
        onCategoryChange={onRoleChange}
        selectedCategory={handleSelectedRole()}
        categories={{
          all: 'All',
          fsa: 'FSA',
          distributor: 'Distributor',
        }}
        className="mt-4"
        displayCategory={true}
      />
      <List load={load} hasMore={hasMore} loading={loading}>
        {associates &&
          associates.map((associate) => (
            <User user={associate} key={associate.id} />
          ))}
      </List>
    </Content>
  );
};

export default Users;
