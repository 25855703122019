import {
  doc,
  FirestoreError,
  onSnapshot,
  Unsubscribe,
} from 'firebase/firestore';
import { useEffect, useMemo } from 'react';
import { Product, User, UserChanges } from '../../../../global';
import { updateUser } from '../db/users';
import { LoadingHook, useLoadingValue } from '../utils';
import { docToJSON, firestore } from '../utils/firebase';
import { useAuth } from './use-auth';

export type UserHook = LoadingHook<User | null, FirestoreError>;

export const useUser = (userId?: string) => {
  const { id } = useAuth();
  const { error, loading, setLoading, setError, setValue, value } = useLoadingValue<
    User | null,
    FirestoreError
  >(null);

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    if (id) {
      const ref = doc(firestore, 'users', userId ? userId : id);
      unsubscribe = onSnapshot(
        ref,
        (doc) => {
          if (doc.exists()) {
            setValue(prepareUser(docToJSON(doc) as User));
          } else {
            setValue(null);
          }
        },
        setError
      );
    } else {
      setValue(null);
    }
    return unsubscribe;
  }, [id, userId]);

  const update = async (changes: UserChanges) => {
    if (!id) return;
    try {
      setLoading(true);
      await updateUser(id, changes);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  };

  useEffect(() => {
    console.log(error);
  }, [error]);

  return useMemo(
    () => ({
      user: value,
      error,
      loading,
      update,
    }),
    [value, loading, error]
  );

};

const prepareUser = (user: User): User => {
  const ordersAccepted =
    user.ordersAmountAppAccepted + user.ordersAmountChatbotAccepted;
  const ordersFulfilled =
    user.ordersAmountAppFulfilled + user.ordersAmountChatbotFulfilled;
  const ordersPending =
    user.ordersAmountAppPending + user.ordersAmountChatbotPending;
  const ordersRejected =
    user.ordersAmountAppRejected + user.ordersAmountChatbotRejected;

  const appFulfilled = calcVolume(user.ordersVolumeAppFulfilled);
  const chatbotFulfilled = calcVolume(user.ordersVolumeChatbotFulfilled);

  const lastOrderPlacedAt =
    user.lastOrderPlacedAtApp > user.lastOrderPlacedAtChatbot
      ? user.lastOrderPlacedAtApp
      : user.lastOrderPlacedAtChatbot;

  return {
    ...user,
    appFulfilled,
    chatbotFulfilled,
    ordersAccepted,
    ordersFulfilled,
    ordersPending,
    ordersRejected,
    lastOrderPlacedAt,
  };
};

const calcVolume = (products: Product): number => {
  let volume = 0;
  volume += products.classic * 0.05;
  volume += products.supaset * 0.05;
  volume += products.supafixc0 * 0.02;
  volume += products.supafixc1 * 0.02;
  return volume;
};
