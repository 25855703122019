import { faUserCircle, faEye } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ListItem from '../ui/list/ListItem';
import Input from '../forms/Input';
import { RegisterOptions, useForm } from 'react-hook-form';
import { Associate, FSATarget, UpdateTargetData } from '../../../../global';
import Button from '../ui/Button';
import { useCheckAccess } from '../../lib/auth/use-checkAccess';
import { useState } from 'react';
import { Confirm } from '../forms/Confirm';
import { useEffect } from 'react';

type MissingTargetProps = {
  fsa: Associate;
  target: FSATarget;
  type: String;
  updateTarget: (id: string, data: UpdateTargetData) => void;
  currentSetTargets: {
    visitTarget: number;
    cementTarget: number;
    mortarTarget: number;
  };
  clusterTarget?: FSATarget;
};

const MissingTarget = ({
  fsa,
  target,
  type,
  updateTarget,
  currentSetTargets,
  clusterTarget,
}: MissingTargetProps) => {
  const checkAccess = useCheckAccess();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [allowEditing, setAllowEditing] = useState(false);

  const { register, getValues } = useForm({
    mode: 'all',
    defaultValues: {
      visits:
        type === 'fsa' && target && target.visitTarget
          ? target.visitTarget
          : '',
      cement: target && target.cementTarget ? target.cementTarget : '',
      mortar: target && target.mortarTarget ? target.mortarTarget : '',
    },
  });

  const checkValid = () => {
    if (currentSetTargets && target) {
      if (
        clusterTarget.visitTarget <
          +currentSetTargets.visitTarget -
            target.visitTarget +
            +getValues('visits') ||
        clusterTarget.cementTarget <
          +currentSetTargets.cementTarget -
            target.cementTarget +
            +getValues('cement') ||
        clusterTarget.mortarTarget <
          +currentSetTargets.mortarTarget -
            target.mortarTarget +
            +getValues('mortar')
      ) {
        setButtonDisabled(true);
      } else {
        setButtonDisabled(false);
      }
    }
  };

  const targetOptions: RegisterOptions = {
    valueAsNumber: true,
    min: {
      value: 0,
      message: 'Targets cannot be negative!',
    },
    onBlur: checkValid,
  };

  const updateFSATarget = () => {
    if (type === 'fsa') {
      updateTarget(target.id, {
        visitTarget: isNaN(getValues('visits') as number)
          ? 0
          : (getValues('visits') as number),
        cementTarget: isNaN(getValues('cement') as number)
          ? 0
          : (getValues('cement') as number),
        mortarTarget: isNaN(getValues('mortar') as number)
          ? 0
          : (getValues('mortar') as number),
      });
    } else {
      updateTarget(target.id, {
        cementTarget: isNaN(getValues('cement') as number)
          ? 0
          : (getValues('cement') as number),
        mortarTarget: isNaN(getValues('mortar') as number)
          ? 0
          : (getValues('mortar') as number),
      });
    }
  };

  const handleOnCancel = () => {
    setShowConfirmation(false);
  };

  return (
    <ListItem>
      <Confirm
        title="Are you sure you want to save these targets?"
        open={showConfirmation}
        onConfirm={updateFSATarget}
        onCancel={handleOnCancel}
        setOpen={(open) => {
          setShowConfirmation(open);
        }}
      />
      <div className="self-center col-span-5 text-lh-head-black">
        <p className="truncate overflow-hidden h-6">
          <FontAwesomeIcon icon={faUserCircle} /> {fsa.name}
        </p>
        {checkAccess(['sop']) && target && allowEditing && (
          <p className="mt-2">
            <Button
              type="button"
              onClick={() => setShowConfirmation(true)}
              text="Save targets"
              color="green"
              className="text-xs px-2 "
              buttonDisabled={buttonDisabled}
            />
          </p>
        )}
        {checkAccess(['sop']) &&
          target &&
          !allowEditing &&
          target.lastEdit &&
          clusterTarget.lastEdit && (
            <p className="mt-2">
              <Button
                type="button"
                onClick={() => setAllowEditing(true)}
                text="Edit targets"
                color="green"
                className="text-xs px-2 "
              />
            </p>
          )}
        {checkAccess(['sop']) &&
          target &&
          !allowEditing &&
          !target.lastEdit &&
          clusterTarget.lastEdit && (
            <p className="mt-2">
              <Button
                type="button"
                onClick={() => setAllowEditing(true)}
                text="Set targets"
                color="green"
                className="text-xs px-2 "
              />
            </p>
          )}
      </div>
      <div className="grid grid-cols-12 col-span-5 h-48">
        <form className="grid grid-cols-12 self-center text-right col-span-12 text-lh-head-black mr-2">
          {type === 'fsa' && allowEditing ? (
            <p className="grid grid-cols-12 col-span-10 text-right">
              <FontAwesomeIcon icon={faEye} className="col-span-2 mt-2" />

              <Input
                register={register}
                options={targetOptions}
                type="number"
                name="visits"
                placeholder="0"
                className="col-span-10 bg-gray-200 border-2 rounded-lg text-lh-text-black text-right pr-2"
              />
            </p>
          ) : type === 'fsa' && !allowEditing ? (
            <p className="grid grid-cols-12 col-span-10 text-right  h-8">
              <FontAwesomeIcon icon={faEye} className="col-span-2 " />
              {target.visitTarget}
            </p>
          ) : (
            ''
          )}

          {allowEditing ? (
            <p className="grid grid-cols-12 col-span-10 text-right">
              <svg
                id="Icons"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 22 28"
                className="col-span-2 h-6 -mt-4 self-center"
              >
                <defs></defs>
                <path
                  className="fill-transparent	stroke-black stroke-2"
                  d="M6,16a31.62,31.62,0,0,0,1.2,9H24.8A35.63,35.63,0,0,0,26,16a31.62,31.62,0,0,0-1.2-9H7.2A31.62,31.62,0,0,0,6,16Z"
                  transform="translate(-5 -2)"
                />
                <polygon
                  className="fill-transparent	stroke-black stroke-2"
                  points="21 27 1 27 2 23 20 23 21 27"
                />
                <polygon
                  className="fill-transparent	stroke-black stroke-2"
                  points="1 1 21 1 20 5 2 5 1 1"
                />
                <path
                  d="M13.68,22a3.77,3.77,0,0,1-1.58-1.63,5.65,5.65,0,0,1-.54-2.58V14.23a5.71,5.71,0,0,1,.54-2.58A3.77,3.77,0,0,1,13.68,10a5.27,5.27,0,0,1,2.5-.56,4.36,4.36,0,0,1,2.15.52,4.13,4.13,0,0,1,1.57,1.51,6.4,6.4,0,0,1,.86,2.38h-2.5a3.3,3.3,0,0,0-.43-1.08,2.08,2.08,0,0,0-.71-.68,1.85,1.85,0,0,0-.94-.24,2.18,2.18,0,0,0-1.15.28,1.78,1.78,0,0,0-.73.8,3.11,3.11,0,0,0-.25,1.28v3.51A3,3,0,0,0,14.3,19a1.8,1.8,0,0,0,.73.81,2.28,2.28,0,0,0,1.15.27,1.88,1.88,0,0,0,.95-.23,1.92,1.92,0,0,0,.71-.68,3.26,3.26,0,0,0,.42-1.09h2.5a6.63,6.63,0,0,1-.87,2.39A4.14,4.14,0,0,1,18.33,22a4.36,4.36,0,0,1-2.15.52A5.27,5.27,0,0,1,13.68,22Z"
                  transform="translate(-5 -2)"
                />
              </svg>
              <Input
                register={register}
                options={targetOptions}
                type="number"
                name="cement"
                placeholder="0"
                className="col-span-10 bg-gray-200 border-2 rounded-lg text-lh-text-black text-right pr-2"
              />
            </p>
          ) : (
            <p className="grid grid-cols-12 col-span-10 text-right h-8">
              <svg
                id="Icons"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 22 28"
                className="col-span-2 h-6 -mt-2 self-center"
              >
                <defs></defs>
                <path
                  className="fill-transparent	stroke-black stroke-2"
                  d="M6,16a31.62,31.62,0,0,0,1.2,9H24.8A35.63,35.63,0,0,0,26,16a31.62,31.62,0,0,0-1.2-9H7.2A31.62,31.62,0,0,0,6,16Z"
                  transform="translate(-5 -2)"
                />
                <polygon
                  className="fill-transparent	stroke-black stroke-2"
                  points="21 27 1 27 2 23 20 23 21 27"
                />
                <polygon
                  className="fill-transparent	stroke-black stroke-2"
                  points="1 1 21 1 20 5 2 5 1 1"
                />
                <path
                  d="M13.68,22a3.77,3.77,0,0,1-1.58-1.63,5.65,5.65,0,0,1-.54-2.58V14.23a5.71,5.71,0,0,1,.54-2.58A3.77,3.77,0,0,1,13.68,10a5.27,5.27,0,0,1,2.5-.56,4.36,4.36,0,0,1,2.15.52,4.13,4.13,0,0,1,1.57,1.51,6.4,6.4,0,0,1,.86,2.38h-2.5a3.3,3.3,0,0,0-.43-1.08,2.08,2.08,0,0,0-.71-.68,1.85,1.85,0,0,0-.94-.24,2.18,2.18,0,0,0-1.15.28,1.78,1.78,0,0,0-.73.8,3.11,3.11,0,0,0-.25,1.28v3.51A3,3,0,0,0,14.3,19a1.8,1.8,0,0,0,.73.81,2.28,2.28,0,0,0,1.15.27,1.88,1.88,0,0,0,.95-.23,1.92,1.92,0,0,0,.71-.68,3.26,3.26,0,0,0,.42-1.09h2.5a6.63,6.63,0,0,1-.87,2.39A4.14,4.14,0,0,1,18.33,22a4.36,4.36,0,0,1-2.15.52A5.27,5.27,0,0,1,13.68,22Z"
                  transform="translate(-5 -2)"
                />
              </svg>
              {target.cementTarget}
            </p>
          )}
          {allowEditing ? (
            <p className="grid grid-cols-12 col-span-10 text-right">
              <svg
                id="Icons"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 22 28"
                className="col-span-2 h-6 -mt-4 self-center"
              >
                <defs></defs>
                <path
                  className="fill-transparent	stroke-black stroke-2"
                  d="M6,16a31.62,31.62,0,0,0,1.2,9H24.8A35.63,35.63,0,0,0,26,16a31.62,31.62,0,0,0-1.2-9H7.2A31.62,31.62,0,0,0,6,16Z"
                  transform="translate(-5 -2)"
                />
                <polygon
                  className="fill-transparent	stroke-black stroke-2"
                  points="21 27 1 27 2 23 20 23 21 27"
                />
                <polygon
                  className="fill-transparent	stroke-black stroke-2"
                  points="1 1 21 1 20 5 2 5 1 1"
                />
                <path
                  d="M18.45,9.59h3.17V22.37H19.43V12.45l.09.56L17.08,21H15l-2.44-7.76.08-.74v9.92H10.41V9.59h3.17L16,18.21Z"
                  transform="translate(-5 -2)"
                />
              </svg>
              <Input
                register={register}
                options={targetOptions}
                type="number"
                name="mortar"
                placeholder="0"
                className="col-span-10 bg-gray-200 border-2 rounded-lg text-lh-text-black text-right pr-2"
              />
            </p>
          ) : (
            <p className="grid grid-cols-12 col-span-10 text-right h-8">
              <svg
                id="Icons"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 22 28"
                className="col-span-2 h-6 -mt-1 self-center"
              >
                <defs></defs>
                <path
                  className="fill-transparent	stroke-black stroke-2"
                  d="M6,16a31.62,31.62,0,0,0,1.2,9H24.8A35.63,35.63,0,0,0,26,16a31.62,31.62,0,0,0-1.2-9H7.2A31.62,31.62,0,0,0,6,16Z"
                  transform="translate(-5 -2)"
                />
                <polygon
                  className="fill-transparent	stroke-black stroke-2"
                  points="21 27 1 27 2 23 20 23 21 27"
                />
                <polygon
                  className="fill-transparent	stroke-black stroke-2"
                  points="1 1 21 1 20 5 2 5 1 1"
                />
                <path
                  d="M18.45,9.59h3.17V22.37H19.43V12.45l.09.56L17.08,21H15l-2.44-7.76.08-.74v9.92H10.41V9.59h3.17L16,18.21Z"
                  transform="translate(-5 -2)"
                />
              </svg>
              {target.mortarTarget}
            </p>
          )}
        </form>
      </div>
    </ListItem>
  );
};

export default MissingTarget;
