import { ProductsType } from '../../../../global';

type ProductsProps = {
  title: string;
  products: ProductsType;
  className?: string;
};

const Products = ({ title, products, className }: ProductsProps) => {
  return (
    <div className={`grid grid-cols-12 ${className}`}>
      <h2 className="col-span-full font-bold text-lh-head-black">{title}</h2>
      <hr className="col-span-full" />
      <p className="col-span-6 text-left pt-3 truncate overflow-hidden">
        Classic
      </p>
      <p className="col-span-6 text-lh-text-black text-right pt-3 truncate overflow-hidden">
        {products.classic}
        <span className="text-gray-500 font-light ml-1">tons</span>
      </p>
      <p className="col-span-6 text-left pt-3 truncate overflow-hidden">
        Supaset
      </p>
      <p className="col-span-6 text-lh-text-black text-right pt-3 truncate overflow-hidden">
        {products.supaset}
        <span className="text-gray-500 font-light ml-1">tons</span>
      </p>
      <p className="col-span-6 text-left pt-3 truncate overflow-hidden">
        Supafix C0
      </p>
      <p className="col-span-6 text-lh-text-black text-right pt-3 truncate overflow-hidden">
        {products.supafixc0}
        <span className="text-gray-500 font-light ml-1">tons</span>
      </p>
      <p className="col-span-6 text-left pt-3 truncate overflow-hidden">
        Supafix C1
      </p>
      <p className="col-span-6 text-lh-text-black text-right pt-3 truncate overflow-hidden">
        {products.supafixc1}
        <span className="text-gray-500 font-light ml-1">tons</span>
      </p>
    </div>
  );
};

export default Products;
