import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import { Outlet, RouteOutlet } from '../../../../global';
import { firestore } from '../utils/firebase';
import { RouteCreateData, UpdateRouteData } from './types';

export const createRoute = async (data: RouteCreateData): Promise<void> => {
  const ref = collection(firestore, 'routes');
  await addDoc(ref, data);
};

export const updateRoute = async (
  id: string,
  data: UpdateRouteData
): Promise<void> => {
  const ref = doc(firestore, 'routes', id);
  await updateDoc(ref, data);
};

export const setOutlet = async (id: string, data: RouteOutlet) => {
  const ref = doc(firestore, `routes/${id}/routeOutlets`, data.id);
  await setDoc(ref, data);
};

export const deleteOutlet = async (id: string, outletId: string) => {
  const ref = doc(firestore, `routes/${id}/routeOutlets`, outletId);
  await deleteDoc(ref);
};

export const updateOutlet = async (id: string, outletId: string, data: any) => {
  const ref = doc(firestore, `routes/${id}/routeOutlets`, outletId);
  await updateDoc(ref, data);
};
