import { FirebaseError } from 'firebase/app';
import {
  collection,
  deleteDoc,
  doc,
  FirestoreError,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  Query,
  updateDoc,
  where,
} from 'firebase/firestore';
import { useEffect, useMemo, useState } from 'react';
import { Outlet, Route, RouteOutlet, TestRoute } from '../../../../global';
import {
  createTempRoute,
  deleteOutletTempRoute,
  setOutletTempRoute,
  updateOutletTempRoute,
} from '../db/tempRoute';
import { RouteCreateData } from '../db/types';
import { docToJSON, firestore } from '../utils/firebase';
import { usePagination } from '../utils/usePagination';
import { useAuth } from './use-auth';

type RoutesHook = {
  error: FirebaseError;
  hasMore: boolean;
  load: () => void;
  loading: boolean;
  reset: () => void;
  routes: Route[];
};

type RoutesHookOptions = {
  fsaId?: string;
  pageSize?: number;
};

type RouteUpdateData = {};

export const useTempRoutes = ({
  fsaId,
  pageSize = 10,
}: RoutesHookOptions): RoutesHook => {
  const { id } = useAuth();
  const [routesQuery, setRoutesQuery] = useState<Query>();
  const { error, hasMore, load, loading, reset, values } = usePagination(
    routesQuery,
    pageSize
  );

  useEffect(() => {
    let newQuery: Query;
    if (id) {
      newQuery = query(
        collection(firestore, 'routes'),
        where('userIds', 'array-contains', id),
        orderBy('defaultDayNumber')
      );
      if (fsaId) {
        newQuery = query(newQuery, where('fsa.id', '==', fsaId));
      }
      setRoutesQuery(newQuery);
    } else if (routesQuery) {
      setRoutesQuery(undefined);
    }
  }, [id, fsaId]);

  useEffect(() => {
    console.log(error);
  }, [error]);

  return useMemo(
    () => ({
      error,
      hasMore,
      load,
      loading,
      reset,
      routes: values ? values.map((v) => docToJSON(v) as TestRoute) : values,
    }),
    [error, hasMore, load, loading, reset, values]
  );
};

export const useTempRoute = (id?: string) => {
  const [loading, setLoading] = useState(false);
  const [tempRoute, setTempRoute] = useState<Route | undefined>();
  const [tempOutlets, setTempOutlets] = useState<RouteOutlet[]>([]);
  const [error, setError] = useState<FirestoreError>();

  useEffect(() => {
    let unsubscribe;
    if (id) {
      setLoading(true);
      const ref = doc(firestore, 'routeChanges', id);
      unsubscribe = onSnapshot(
        ref,
        (doc) => {
          if (doc.exists()) {
            setTempRoute(docToJSON(doc) as Route);
          } else {
            setTempRoute(undefined);
          }
          setLoading(false);
        },
        (error: FirestoreError) => {
          setLoading(false);
          setError(error);
        }
      );
    } else {
      setTempRoute(undefined);
    }
    return unsubscribe;
  }, [id]);

  useEffect(() => {
    loadOutlets();
  }, [id]);

  useEffect(() => {
    console.count('Outlets changed');
  }, [tempOutlets]);

  const create = async (data: RouteCreateData, id?: string) => {
    try {
      setLoading(true);
      if (id) {
        await createTempRoute(data, id);
      } else {
        await createTempRoute(data);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(error);
    }
  };

  const update = async (data: RouteUpdateData) => {
    if (id) {
      try {
        setLoading(true);
        const ref = doc(firestore, 'routeChanges', id);
        await updateDoc(ref, data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setError(err);
      }
    }
  };

  const deleteTempRoute = async () => {
    if (id) {
      try {
        setLoading(true);
        const ref = doc(firestore, 'routeChanges', id);
        await deleteDoc(ref);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setError(err);
      }
    }
  };

  const addTempOutlet = async (data: RouteOutlet) => {
    if (id) {
      try {
        setLoading(true);
        await setOutletTempRoute(id, data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setError(err);
      }
    }
  };

  const removeOutlet = async (outletId: string) => {
    if (id) {
      try {
        setLoading(true);
        await deleteOutletTempRoute(id, outletId);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setError(err);
      }
    }
  };

  const updateWeight = async (outletId: string, weight: number) => {
    if (id) {
      try {
        setLoading(true);
        await updateOutletTempRoute(id, outletId, { weight });
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setError(error);
      }
    }
  };

  const loadOutlets = () => {
    if (id) {
      const ref = query(
        collection(firestore, `routeChanges/${id}/routeChangesOutlets`),
        orderBy('weight')
      );
      getDocs(ref)
        .then((snap) =>
          setTempOutlets(
            snap.docs.map(
              (doc) => ({ id: doc.id, ...doc.data() } as RouteOutlet)
            )
          )
        )
        .catch(setError);
    } else {
      setTempOutlets([]);
    }
  };

  useEffect(() => {
    console.log(error);
  }, [error]);

  return {
    tempRoute,
    error,
    loading,
    create,
    update,
    deleteTempRoute,
    tempOutlets,
    addTempOutlet,
    removeOutlet,
    updateWeight,
    loadOutlets,
  };
};
