import dayjs from 'dayjs';
import { FirebaseError } from 'firebase/app';
import {
  collection,
  doc,
  FirestoreError,
  onSnapshot,
  orderBy,
  Query,
  query,
  Unsubscribe,
  where,
} from 'firebase/firestore';
import { useEffect, useMemo, useState } from 'react';
import {
  Order,
  OrderStatus,
  CreateOrderData,
  UpdateOrderData,
} from '../../../../global';
import { createOrder, updateOrder } from '../db/orders';
import { firestore, docToJSON } from '../utils/firebase';
import { usePagination } from '../utils/usePagination';
import { SearchQuery, usePaginationTypesense } from '../utils/usePaginationTypesense';
import { useAuth } from './use-auth';

type OrdersHook = {
  error: FirebaseError;
  hasMore: boolean;
  load: () => void;
  loading: boolean;
  reset: () => void;
  orders: Order[];
};

type OrdersHookOptions = {
  status: OrderStatus;
  pageSize?: number;
  direction?: 'asc' | 'desc';
  fsaId?: string;
};

export const useOrders = ({
  status,
  pageSize = 10,
  direction = 'asc',
  fsaId,
}: OrdersHookOptions): OrdersHook => {
  const { id } = useAuth();
  const [ordersQuery, setOrdersQuery] = useState<Query>();
  const { error, hasMore, load, loading, reset, values } = usePagination(
    ordersQuery,
    pageSize
  );

  useEffect(() => {
    if (id && fsaId) {
      setOrdersQuery(
        query(
          collection(firestore, 'orders'),
          where('userIds', 'array-contains', id),
          where('fsa.id', '==', fsaId),
          where('status', 'in', status),
          orderBy('createdAt', direction)
        )
      );
    } else if (id) {
      setOrdersQuery(
        query(
          collection(firestore, 'orders'),
          where('userIds', 'array-contains', id),
          where('status', 'in', status),
          orderBy('createdAt', direction)
        )
      );
    }
    else if (ordersQuery) {
      setOrdersQuery(undefined);
    }
  }, [id, status, direction, fsaId]);

  useEffect(() => {
    console.log(error);
  }, [error]);

  return useMemo(
    () => ({
      error,
      hasMore,
      load,
      loading,
      reset,
      orders: values
        ? values.map((v) => prepareOrder(docToJSON(v) as Order))
        : values,
    }),
    [error, hasMore, load, loading, reset, values]
  );
};

export const useOrdersTypesense = ({
  status,
  direction = 'asc',
  searchText,
  pageSize = 10,
}) => {
  const { id, typesenseKeyOrders } = useAuth();
  const [ordersQuery, setOrdersQuery] = useState<SearchQuery>();
  const { error, hasMore, load, loading, reset, values } =
    usePaginationTypesense(ordersQuery, pageSize, 'orders', typesenseKeyOrders);

  useEffect(() => {
    let categoryFilter = '';
    if (status) {
      categoryFilter += `status:=['${status.join(',')}]`;
    }

    if (typesenseKeyOrders && id && searchText) {
      let search = {
        q: searchText,
        query_by: 'outlet.name,retailer.name,fsa.name',
        filter_by: `${categoryFilter}`,
        sort_by: `sort_by:created_at${direction}`,
      };

      setOrdersQuery(search);
    } else if (ordersQuery) {
      setOrdersQuery(undefined);
    }
  }, [id, status, pageSize, direction, searchText, typesenseKeyOrders]);

  useEffect(() => {
    console.log(error);
  }, [error]);

  return useMemo(
    () => ({
      error,
      hasMore,
      load,
      loading,
      reset,
      orders: values
        ? values.map((v) => {
          return prepareOrder(unflatten({ ...v.document, id: v.document.id, createdAt: v.document.createdAt * 1000 })) as Order;
        }

        )
        : values,
    }),
    [error, hasMore, load, loading, reset, values]
  );
};

export const useOrder = (id?: string) => {
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState<Order | undefined>();
  const [error, setError] = useState<FirestoreError>();

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    if (id) {
      setLoading(true);
      const ref = doc(firestore, 'orders', id);
      unsubscribe = onSnapshot(
        ref,
        (doc) => {
          if (doc.exists()) {
            setOrder(docToJSON(doc) as Order);
          } else {
            setOrder(undefined);
          }
          setLoading(false);
        },
        (error: FirestoreError) => {
          setLoading(false);
          setError(error);
        }
      );
    }
    return unsubscribe;
  }, [id]);

  const create = async (data: CreateOrderData) => {
    try {
      setLoading(true);
      await createOrder(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
      setError(err);
    }
  };

  const update = async (data: UpdateOrderData) => {
    if (!id) throw new Error('No order selected');
    try {
      setLoading(true);
      await updateOrder(id, data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
      setError(err);
    }
  };

  useEffect(() => {
    console.log(error);
  }, [error]);

  return {
    order,
    error,
    loading,
    create,
    update,
  };
};

const prepareOrder = (order: Order): Order => {
  let totalOrdered = 0;
  Object.keys(order.products).forEach(
    (key) =>
      (totalOrdered += !isNaN(+order.products[key]) ? +order.products[key] : 0)
  );
  let totalDelivered = 0;
  if (order.productsDelivered)
    Object.keys(order.productsDelivered).forEach(
      (key) =>
      (totalDelivered += !isNaN(+order.productsDelivered[key])
        ? +order.productsDelivered[key]
        : 0)
    );
  let color = '';
  if (order.status === 'pending' || order.status === 'accepted') {
    const timeDiff = dayjs().diff(order.createdAt, 'hours');
    if (order.status === 'pending' && timeDiff > 12) {
      color = 'red';
    } else if (order.status === 'accepted' && timeDiff > 48) {
      color = 'red';
    }
  }
  const retailerName = order.retailer.name;
  return {
    ...order,
    totalOrdered,
    totalDelivered,
    color,
    retailerName,
  };
};

const unflatten = (data) => {
  if (Object(data) !== data || Array.isArray(data))
    return data;
  var regex = /\.?([^.\[\]]+)|\[(\d+)\]/g,
    resultholder = {};
  for (var p in data) {
    var cur = resultholder,
      prop = "",
      m;
    while (m = regex.exec(p)) {
      cur = cur[prop] || (cur[prop] = (m[2] ? [] : {}));
      prop = m[2] || m[1];
    }
    cur[prop] = data[p];
  }
  return resultholder[""] || resultholder;
};