import { faMapMarked } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Status, Wrapper } from '@googlemaps/react-wrapper';
import { useState } from 'react';
import { useEffect } from 'react';
import Button from '../../../components/ui/Button';
import Map from '../../../components/map/Map';
import Marker from '../../../components/map/Marker';
import { Location, Outlet } from '../../../../../global';

const render = (status: Status) => {
  return <h1>{status}</h1>;
};

type VisitStep0Props = {
  currentStep: number;
  captureOutletLocation: () => void;
  location: Location;
  outlet: Outlet;
  outletLocationCaptured: boolean;
  outletLocationLoading: boolean;
};

const VisitStep0 = ({
  captureOutletLocation,
  currentStep,
  location,
  outlet,
  outletLocationCaptured,
  outletLocationLoading,
}: VisitStep0Props) => {
  const [outletZoom, setZoom] = useState(13); // initial zoom
  const [center, setCenter] = useState<google.maps.LatLngLiteral>({
    lat: 0,
    lng: 0,
  });
  const [outletMarker, setOutletMarker] = useState<google.maps.LatLngLiteral>();
  const [currentMarker, setCurrentMarker] =
    useState<google.maps.LatLngLiteral>();

  const onIdle = (m: google.maps.Map) => {
    setZoom(m.getZoom()!);
    setCenter(m.getCenter()!.toJSON());
  };

  useEffect(() => {
    if (outlet?.location) {
      setCenter({
        lat: outlet?.location.lat,
        lng: outlet?.location.lng,
      });
      setOutletMarker({
        lat: outlet?.location.lat,
        lng: outlet?.location.lng,
      });
    }
  }, [outlet]);

  useEffect(() => {
    if (location) {
      setCurrentMarker({
        lat: location.lat,
        lng: location.lng,
      });
    }
  }, [location]);

  if (currentStep !== 0) return null;
  return (
    outlet && (
      <div className="grid grid-cols-9 col-span-9 w-full ">
        <div className="grid grid-cols-9 col-span-9 content-center ">
          <hr className={'col-span-9'} />
          <p className={'col-span-4 pl-4 text-left mt-4 mb-2 font-semibold'}>
            Order Status
          </p>
          <p className={'col-span-2 pl-4 text-center mt-4 mb-2 font-semibold'}>
            Count
          </p>
          <p className={'col-span-3 pl-4 text-center mt-4 mb-2 font-semibold'}>
            Volume
          </p>
          <div className={'grid grid-cols-9 col-span-9'}>
            <p className={'col-span-4 pl-4 text-left mb-2'}>Orders pending:</p>
            <p className={'col-span-2 pl-4 font-bold text-center mb-2'}>
              {outlet &&
                outlet.ordersAmountAppPending +
                  outlet.ordersAmountChatbotPending}
            </p>
            <p className={'col-span-3 pl-4 font-bold text-center mb-2'}>
              {outlet && outlet.pendingVolume ? outlet.pendingVolume : 0}
            </p>
            <p className={'col-span-4 pl-4 text-left mb-2'}>Orders accepted:</p>
            <p className={'col-span-2 pl-4 font-bold text-center mb-2'}>
              {outlet &&
                outlet.ordersAmountAppAccepted +
                  outlet.ordersAmountChatbotAccepted}
            </p>
            <p className={'col-span-3 pl-4 font-bold text-center mb-2'}>
              {outlet && outlet.acceptedVolume ? outlet.acceptedVolume : 0}
            </p>
            <p className={'col-span-4 pl-4 text-left mb-2'}>
              Orders fulfilled:
            </p>
            <p className={'col-span-2 pl-4 font-bold text-center mb-2'}>
              {outlet &&
                outlet.ordersAmountAppFulfilled +
                  outlet.ordersAmountChatbotFulfilled}
            </p>
            <p className={'col-span-3 pl-4 font-bold text-center mb-2'}>
              {outlet && outlet.fulfilledVolume ? outlet.fulfilledVolume : 0}
            </p>
          </div>
          <hr className={'col-span-9'} />
          <p className={'col-span-9 pl-4 text-left mt-4 mb-2 font-semibold'}>
            Chatbot enabled
          </p>
          <p className="col-span-9 pl-4 text-left mb-2">
            Status:{' '}
            {outlet && !outlet.retailer.phoneCorrect
              ? 'Phone number wrong'
              : outlet && !outlet.retailer.hasWhatsApp
              ? 'Has no WhatsApp'
              : outlet && !outlet.retailer.hasInteracted
              ? 'Not interacted yet'
              : outlet && !outlet.retailer.chatbotTOSAccepted
              ? 'TOS not accepted'
              : 'Chatbot enabled'}
          </p>
          <p className={'col-span-9 pl-4 text-left mt-2 mb-2 font-semibold'}>
            Location
          </p>
          {outlet?.location && (
            <p className="col-span-9 pl-4 text-left mb-2">
              <FontAwesomeIcon icon={faMapMarked} />{' '}
              <a
                href={`geo:${outlet?.location?.lat},${
                  outlet?.location?.lng
                }?q=${outlet?.location?.lat},${outlet?.location?.lng}(${
                  outlet && outlet.name
                })`}
              >
                Open in Google Maps
              </a>
            </p>
          )}
          {outlet?.location && (
            <div className="col-span-full h-56 flex mt-4">
              <Wrapper
                apiKey={process.env.REACT_APP_MAPS_API_KEY}
                render={render}
              >
                <Map
                  center={center}
                  onIdle={onIdle}
                  zoom={outletZoom}
                  className="flex-grow h-full rounded-lg"
                  disableDefaultUI
                >
                  =
                  {outletMarker && (
                    <Marker position={outletMarker} label={outlet.name} />
                  )}
                </Map>
              </Wrapper>
            </div>
          )}
          {!outlet?.location && location && (
            <div className="col-span-full flex flex-col">
              <Button
                onClick={captureOutletLocation}
                text="Capture Outlet Location"
                color="green"
                className="mt-4 "
                buttonDisabled={outletLocationLoading}
              />
            </div>
          )}
          {outletLocationCaptured && (
            <p className="col-span-full text-center text-green-500 font-thin text-sm">
              The location has ben captured!
            </p>
          )}
        </div>
      </div>
    )
  );
};

export default VisitStep0;
