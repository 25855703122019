import { useState } from 'react';
import Loader from '../../components/ui/Loader';
import List from '../../components/ui/list/List';
import { useAuth } from '../../lib/hooks/use-auth';
import { useRoutes } from '../../lib/hooks/use-routes';
import Route from '../../components/routes/Route';
import Content from '../../components/layout/Content';
import TopBar from '../../components/layout/TopBar';
import Alert from '../../components/ui/Alert';
import Reload from '../../components/ui/Reload';
import { useCheckAccess } from '../../lib/auth/use-checkAccess';
import Select from 'react-select';
import { useAssociates } from '../../lib/hooks/use-associates';
import useStore from '../../lib/hooks/use-store';
import New from '../../components/ui/New';

const RoutesPage = () => {
  const user = useAuth();
  const [state, dispatch] = useStore();
  const [showAlertFSA, setShowAlertFSA] = useState<boolean>(false);
  const [showAlertRoute, setShowAlertRoute] = useState<boolean>(false);
  const checkAccess = useCheckAccess();
  const { routes, loading, hasMore, load, reset, error } = useRoutes({
    fsaId: state.routeFSA ? state.routeFSA.value.id : '',
  });

  const {
    error: fsaError,
    loading: fsaLoading,
    associates: fsas,
    reset: resetFSA,
  } = useAssociates({ roles: 'fsa', pageSize: 0 });

  const setFSASelected = (data) => {
    dispatch('SET_ROUTE_FSA', data);
  };

  const reloadRoutes = () => {
    reset();
    resetFSA();
  };

  return (
    <Content topBar>
      <Alert
        title="Error"
        message={fsaError && fsaError.message}
        open={showAlertFSA}
        setOpen={(open) => setShowAlertFSA(open)}
      />
      <Alert
        title="Error"
        message={error && error.message}
        open={showAlertRoute}
        setOpen={(open) => setShowAlertRoute(open)}
      />
      <Reload onClick={reloadRoutes} blocked={false} />
      {checkAccess(['sop']) && (
        <TopBar>
          <div className="w-full grid grid-cols-11 ">
            <div className="w-full grid col-span-11 grid-cols-11 justify-center mt-4"></div>
            <Select
              options={
                fsas
                  ? fsas.map((fsa) => ({
                      value: fsa,
                      label: fsa.name,
                    }))
                  : []
              }
              placeholder={'Select FSA...'}
              onChange={(fsa) => setFSASelected(fsa)}
              value={state.routeFS}
              defaultValue={state.routeFS}
              className="mt-4 md:col-span-5 md:col-start-4 col-span-7 col-start-3 justify-center text-lh-text-black"
            />
          </div>
        </TopBar>
      )}
      <Loader show={loading || fsaLoading} />
      {routes && (
        <List load={load} hasMore={hasMore} loading={loading}>
          {routes && routes.length > 0 && checkAccess(['sop']) ? (
            routes
              .filter(
                (route) => (!route.newRoute && !route.edited) || route.edited
              )
              .map((route) => (
                <Route
                  id={route.id}
                  routeName={route.name}
                  routeFSA={route.fsa.name}
                  routeOutlets={route.outlets}
                  routeDefaultDay={route.defaultDay}
                  route={route}
                />
              ))
          ) : routes && routes.length > 0 && checkAccess(['fsa']) ? (
            routes.map((route) => (
              <Route
                id={route.id}
                routeName={route.name}
                routeFSA={route.fsa.name}
                routeOutlets={route.outlets}
                routeDefaultDay={route.defaultDay}
                route={route}
              />
            ))
          ) : (
            <p className="justify-between mx-2 mb-2 h-18 list-none shadow-md rounded-lg pb-4 px-2 pt-2 bg-white text-center">
              No routes.
            </p>
          )}
        </List>
      )}
      {checkAccess(['sop']) && state.routeFSA && (
        <New to={'new/' + (state.routeFSA ? state.routeFSA.value.id : '')} />
      )}
      {checkAccess(['fsa']) && <New to={'new/' + user.id} />}
    </Content>
  );
};

export default RoutesPage;
