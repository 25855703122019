import { ReactNode } from 'react';

type ButtonProps = {
  children?: ReactNode;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  color?: 'green' | 'red' | 'blue' | 'gray';
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  id?: string;
  text?: string;
  buttonDisabled?: boolean;
};

const Button = ({
  children,
  onClick,
  type,
  text,
  className,
  color = 'gray',
  id,
  buttonDisabled,
}: ButtonProps) => {
  if (children && text) {
    throw new Error('Cannot set children and text');
  }

  return (
    <button
      id={id}
      type={type}
      className={
        className +
        ' text-center border rounded-md text-white cursor-pointer font-bold py-2 px-6 ' +
        (!buttonDisabled
          ? color === 'green'
            ? 'bg-green-600 hover:bg-green-400'
            : color === 'red'
            ? 'bg-red-600 hover:bg-red-400'
            : color === 'blue'
            ? 'bg-blue-600 hover:bg-blue-400'
            : color === 'gray'
            ? 'bg-gray-600 hover:bg-gray-400'
            : ''
          : color === 'green'
          ? 'bg-green-400'
          : color === 'red'
          ? 'bg-red-400'
          : color === 'blue'
          ? 'bg-blue-400'
          : color === 'gray'
          ? 'bg-gray-400'
          : '')
      }
      onClick={onClick}
      disabled={buttonDisabled}
    >
      {children}
      {text}
    </button>
  );
};

export default Button;
