import { forwardRef, useImperativeHandle } from 'react';
import Input from '../../../components/forms/Input';
import { useForm } from 'react-hook-form';

type Props = {
  editable: boolean;
  setPOSM: any;
  posm: {
    noPOSM: boolean;
    chair: boolean;
    table: boolean;
    parasol: boolean;
    bunting: boolean;
    tarpauline: boolean;
    container: boolean;
    abs: boolean;
    aframe: boolean;
    chairComp1: boolean;
    tableComp1: boolean;
    parasolComp1: boolean;
    buntingComp1: boolean;
    tarpaulineComp1: boolean;
    containerComp1: boolean;
    absComp1: boolean;
    aframeComp1: boolean;
    chairComp2: boolean;
    tableComp2: boolean;
    parasolComp2: boolean;
    buntingComp2: boolean;
    tarpaulineComp2: boolean;
    containerComp2: boolean;
    absComp2: boolean;
    aframeComp2: boolean;
  };
};

const VisitStep2 = forwardRef((props: Props, ref) => {
  const { register, handleSubmit } = useForm({
    defaultValues: { ...props.posm },
  });

  useImperativeHandle(ref, () => ({
    savePOSM() {
      doSavePOSM();
    },
  }));

  const doSavePOSM = () => {
    handleSubmit(props.setPOSM)();
  };

  return (
    <>
      <div className="grid grid-cols-9 col-span-9 ">
        <div className="grid grid-cols-12 col-span-9 content-center ">
          <p className={'col-span-12 text-left mt-4 font-semibold pl-6'}>
            Capture POSM Lafarge
          </p>
          <p className={'col-span-6 text-left mt-4 mb-2 font-normal'}>
            <p className="text-center">CHAIR</p>
            <Input
              register={register}
              type="checkbox"
              name="chair"
              placeholder="0"
              disabled={props.editable}
              className={'col-span-3 text-right mb-2 font-normal '}
            />
          </p>
          <p className={'col-span-6 text-left mt-4 mb-2 font-normal'}>
            <p className="text-center">TABLE</p>
            <Input
              register={register}
              type="checkbox"
              name="table"
              placeholder="0"
              disabled={props.editable}
              className={'col-span-3 text-right mb-2 font-normal '}
            />
          </p>
          <p className={'col-span-6 text-left mb-2 font-normal'}>
            <p className="text-center">PARASOL</p>
            <Input
              register={register}
              type="checkbox"
              name="parasol"
              placeholder="0"
              disabled={props.editable}
              className={'col-span-3 text-right mb-2 font-normal '}
            />
          </p>
          <p className={'col-span-6 text-left mb-2 font-normal'}>
            <p className="text-center">BUNTING</p>
            <Input
              register={register}
              type="checkbox"
              name="bunting"
              placeholder="0"
              disabled={props.editable}
              className={'col-span-3 text-right mb-2 font-normal '}
            />
          </p>
          <p className={'col-span-6 text-left mb-2 font-normal'}>
            <p className="text-center">TARPAULINE</p>
            <Input
              register={register}
              type="checkbox"
              name="tarpauline"
              placeholder="0"
              disabled={props.editable}
              className={'col-span-3 text-right mb-2 font-normal '}
            />
          </p>
          <p className={'col-span-6 text-left mb-2 font-normal'}>
            <p className="text-center">CONTAINER</p>
            <Input
              register={register}
              type="checkbox"
              name="container"
              placeholder="0"
              disabled={props.editable}
              className={'col-span-3 text-right mb-2 font-normal '}
            />
          </p>
          <p className={'col-span-6 text-left mb-2 font-normal'}>
            <p className="text-center">ABS</p>
            <Input
              register={register}
              type="checkbox"
              name="abs"
              placeholder="0"
              disabled={props.editable}
              className={'col-span-3 text-right mb-2 font-normal '}
            />
          </p>
          <p className={'col-span-6 text-left mb-2 font-normal'}>
            <p className="text-center">A-FRAME</p>
            <Input
              register={register}
              type="checkbox"
              name="aframe"
              placeholder="0"
              disabled={props.editable}
              className={'col-span-3 text-right mb-2 font-normal '}
            />
          </p>
          <p className={'col-span-12 text-left mt-4 font-semibold pl-6'}>
            Capture POSM Comp 1
          </p>
          <p className={'col-span-6 text-left mt-4 mb-2 font-normal'}>
            <p className="text-center">CHAIR</p>
            <Input
              register={register}
              type="checkbox"
              name="chairComp1"
              placeholder="0"
              disabled={props.editable}
              className={'col-span-3 text-right mb-2 font-normal '}
            />
          </p>
          <p className={'col-span-6 text-left mt-4 mb-2 font-normal'}>
            <p className="text-center">TABLE</p>
            <Input
              register={register}
              type="checkbox"
              name="tableComp1"
              placeholder="0"
              disabled={props.editable}
              className={'col-span-3 text-right mb-2 font-normal '}
            />
          </p>
          <p className={'col-span-6 text-left mb-2 font-normal'}>
            <p className="text-center">PARASOL</p>
            <Input
              register={register}
              type="checkbox"
              name="parasolComp1"
              placeholder="0"
              disabled={props.editable}
              className={'col-span-3 text-right mb-2 font-normal '}
            />
          </p>
          <p className={'col-span-6 text-left mb-2 font-normal'}>
            <p className="text-center">BUNTING</p>
            <Input
              register={register}
              type="checkbox"
              name="buntingComp1"
              placeholder="0"
              disabled={props.editable}
              className={'col-span-3 text-right mb-2 font-normal '}
            />
          </p>
          <p className={'col-span-6 text-left mb-2 font-normal'}>
            <p className="text-center">TARPAULINE</p>
            <Input
              register={register}
              type="checkbox"
              name="tarpaulineComp1"
              placeholder="0"
              disabled={props.editable}
              className={'col-span-3  text-right mb-2 font-normal '}
            />
          </p>
          <p className={'col-span-6 text-left mb-2 font-normal'}>
            <p className="text-center">CONTAINER</p>
            <Input
              register={register}
              type="checkbox"
              name="containerComp1"
              placeholder="0"
              disabled={props.editable}
              className={'col-span-3 text-right mb-2 font-normal '}
            />
          </p>
          <p className={'col-span-6 text-left mb-2 font-normal'}>
            <p className="text-center">ABS</p>
            <Input
              register={register}
              type="checkbox"
              name="absComp1"
              placeholder="0"
              disabled={props.editable}
              className={'col-span-3 text-right mb-2 font-normal '}
            />
          </p>
          <p className={'col-span-6 text-left mb-2 font-normal'}>
            <p className="text-center">A-FRAME</p>
            <Input
              register={register}
              type="checkbox"
              name="aframeComp1"
              placeholder="0"
              disabled={props.editable}
              className={'col-span-3 text-right mb-2 font-normal '}
            />
          </p>
          <p className={'col-span-12 text-left mt-4 font-semibold pl-6'}>
            Capture POSM Comp 2
          </p>
          <p className={'col-span-6 text-left mt-4 mb-2 font-normal'}>
            <p className="text-center">CHAIR</p>
            <Input
              register={register}
              type="checkbox"
              name="chairComp2"
              placeholder="0"
              disabled={props.editable}
              className={'col-span-3 text-right mb-2 font-normal '}
            />
          </p>
          <p className={'col-span-6 text-left mt-4 mb-2 font-normal'}>
            <p className="text-center">TABLE</p>
            <Input
              register={register}
              type="checkbox"
              name="tableComp2"
              placeholder="0"
              disabled={props.editable}
              className={'col-span-3 text-right mb-2 font-normal '}
            />
          </p>
          <p className={'col-span-6 text-left mb-2 font-normal'}>
            <p className="text-center">PARASOL</p>
            <Input
              register={register}
              type="checkbox"
              name="parasolComp2"
              placeholder="0"
              disabled={props.editable}
              className={'col-span-3 text-right mb-2 font-normal '}
            />
          </p>
          <p className={'col-span-6 text-left mb-2 font-normal'}>
            <p className="text-center">BUNTING</p>
            <Input
              register={register}
              type="checkbox"
              name="buntingComp2"
              placeholder="0"
              disabled={props.editable}
              className={'col-span-3 text-right mb-2 font-normal '}
            />
          </p>
          <p className={'col-span-6 text-left mb-2 font-normal'}>
            <p className="text-center">TARPAULINE</p>
            <Input
              register={register}
              type="checkbox"
              name="tarpaulineComp2"
              placeholder="0"
              disabled={props.editable}
              className={'col-span-3  text-right mb-2 font-normal '}
            />
          </p>
          <p className={'col-span-6 text-left mb-2 font-normal'}>
            <p className="text-center">CONTAINER</p>
            <Input
              register={register}
              type="checkbox"
              name="containerComp2"
              placeholder="0"
              disabled={props.editable}
              className={'col-span-3 text-right mb-2 font-normal '}
            />
          </p>
          <p className={'col-span-6 text-left mb-2 font-normal'}>
            <p className="text-center">ABS</p>
            <Input
              register={register}
              type="checkbox"
              name="absComp2"
              placeholder="0"
              disabled={props.editable}
              className={'col-span-3 text-right mb-2 font-normal '}
            />
          </p>
          <p className={'col-span-6 text-left mb-2 font-normal'}>
            <p className="text-center">A-FRAME</p>
            <Input
              register={register}
              type="checkbox"
              name="aframeComp2"
              placeholder="0"
              disabled={props.editable}
              className={'col-span-3 text-right mb-2 font-normal '}
            />
          </p>
          <p className={'col-span-12 text-left mt-4 font-semibold pl-6'}>
            Not available
          </p>
          <p className={'col-span-6 text-left mt-4 mb-2 font-normal'}>
            <p className="text-center">No POSM</p>
            <Input
              register={register}
              type="checkbox"
              name="noPOSM"
              placeholder="0"
              disabled={props.editable}
              className={'col-span-3 text-center mb-2 font-normal '}
            />
          </p>
        </div>
        <p></p>
      </div>
    </>
  );
});
export default VisitStep2;
