import { useOrders } from '../../lib/hooks/use-orders';
import Loader from '../../components/ui/Loader';
import SearchAndSort from '../../components/ui/SearchAndSort';
import React, { useEffect, useState } from 'react';
import useStore from '../../lib/hooks/use-store';
import Content from '../../components/layout/Content';
import TopBar from '../../components/layout/TopBar';
import Reload from '../../components/ui/Reload';
import Order from '../../components/orders/Order';
import List from '../../components/ui/list/List';
import { Link } from 'react-router-dom';
import Alert from '../../components/ui/Alert';
import { useCheckAccess } from '../../lib/auth/use-checkAccess';
import Button from '../../components/ui/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDoubleDown,
  faAngleDoubleUp,
} from '@fortawesome/free-solid-svg-icons';
import { useSales } from '../../lib/hooks/use-sales';
import Sale from '../../components/sales/Sale';
import { OrderStatus } from '../../../../global';
import New from '../../components/ui/New';

const Sales = React.memo(() => {
  const checkAccess = useCheckAccess();
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc');
  const [state, dispatch] = useStore();
  const { error, sales, loading, load, hasMore, reset } = useSales({
    status: 'fulfilled',
    direction,
  });
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (error) {
      setShowAlert(true);
    } else if (showAlert) {
      setShowAlert(false);
    }
    return () => {
      setShowAlert(false);
    };
  }, [error]);

  useEffect(() => {
    if (
      state.status.includes('fulfilled') ||
      state.status.includes('rejected')
    ) {
      setDirection('desc');
    } else {
      setDirection('asc');
    }
  }, [state.status]);

  const onCategoryChange = (event) => {
    if (event.target.value === 'all') {
      dispatch('SET_STATUS', ['rejected', 'fulfilled']);
    } else {
      dispatch('SET_STATUS', [event.target.value]);
    }
  };

  const handleStatusChange = (newStatus) => () => {
    dispatch('SET_STATUS', newStatus);
  };

  const handleDirectionChange = () => {
    if (direction === 'asc') {
      setDirection('desc');
    } else {
      setDirection('asc');
    }
  };

  const handleSelectedCategory = () => {
    if (!state.status.includes('rejected')) return 'fulfilled';
    if (!state.status.includes('fulfilled')) return 'rejected';
    return '';
  };

  return (
    <Content topBar>
      <Reload onClick={reset} blocked={loading} />
      <button
        onClick={handleDirectionChange}
        className="rounded-full flex justify-center items-center h-8 w-8 fixed top-5 right-20 text-xl z-30"
      >
        <FontAwesomeIcon
          icon={direction === 'asc' ? faAngleDoubleUp : faAngleDoubleDown}
        />
      </button>
      <Loader show={loading && sales.length === 0} />
      <Alert
        message={error && error.message}
        open={showAlert}
        setOpen={(open) => setShowAlert(open)}
        title="Error"
      />
      <TopBar>
        <div
          className={
            'flex justify-center content-center flex-col text-center flex-grow cursor-pointer text-lh-dark-blue text-xl font-medium border-b-2 border-lh-dark-blue h-full' +
            ' border-b-4 border-lh-dark-blue font-semibold'
          }
        >
          Direct sales
        </div>
      </TopBar>
      <List load={load} hasMore={hasMore} loading={loading}>
        {sales && sales.length > 0 ? (
          sales.map((sale) => <Sale sale={sale} key={sale.id} />)
        ) : (
          <p className="justify-between mx-2 mb-2 h-18 list-none shadow-md rounded-lg pb-4 px-2 pt-2 bg-white text-center">
            No direct sales yet.
          </p>
        )}
      </List>
      {checkAccess(['fsa', 'sop', 'distributor']) && <New to="new" />}
    </Content>
  );
});

export default Sales;
