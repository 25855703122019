import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Alert from '../../../components/ui/Alert';
import Card from '../../../components/ui/Card';
import Button from '../../../components/ui/Button';
import { useCheckAccess } from '../../../lib/auth/use-checkAccess';
import Loader from '../../../components/ui/Loader';
import Back from '../../../components/ui/Back';
import Content from '../../../components/layout/Content';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { useSites } from '../../../lib/hooks/use-sites';
import { faCircle, faStore } from '@fortawesome/free-solid-svg-icons';
import { Outlet } from '../../../../../global';
import Edit from '../../../components/ui/Edit';
import Map from '../../../components/map/Map';
import { Status, Wrapper } from '@googlemaps/react-wrapper';
import Marker from '../../../components/map/Marker';
import clsx from 'clsx';
import RejectRetailer, {
  RejectRetailerForm,
} from '../../../components/retailer/RejectRetailer';
import RejectOutlet, {
  RejectOutletForm,
} from '../../../components/retailer/RejectOutlet';
import RejectRetailerChanges, {
  RejectRetailerChangesForm,
} from '../../../components/retailer/RejectRetailerChanges';
import RejectOutletChanges, {
  RejectOutletChangesForm,
} from '../../../components/retailer/RejectOutletChanges';
import { useSite } from '../../../lib/hooks/use-sites';
import { useSiteOwner } from '../../../lib/hooks/use-siteOwners';

type OrderDetailsParams = 'id';

const render = (status: Status) => {
  return <h1>{status}</h1>;
};

const SiteOwner = () => {
  const checkAccess = useCheckAccess();
  const { id } = useParams<OrderDetailsParams>();
  const navigate = useNavigate();
  const {
    siteOwner,
    update: siteOwnerUpdate,
    error: siteOwnerError,
    loading: siteOwnerLoading,
    confirmChanges: siteOwnerConfirmChanges,
    confirmRegistration: siteOwnerConfirmRegistration,
    confirmRejection: siteOwnerConfirmRejection,
    confirmChangesRejection: siteOwnerConfirmChangesRejection,
  } = useSiteOwner(id);
  const {
    sites,
    error: siteError,
    loading: siteLoading,
    reset: siteReset,
  } = useSites('', id, 0);
  const [selectedSite, setSelectedOutlet] = useState<Outlet>();
  const {
    site,
    confirmChanges: siteConfirmChanges,
    confirmRegistration: siteConfirmRegistration,
    confirmRejection: siteConfirmRejection,
    confirmChangesRejection: siteConfirmChangesRejection,
  } = useSite(selectedSite?.id);
  const [showSiteAlert, setShowSiteAlert] = useState(false);
  const [showOutletAlert, setShowOutletAlert] = useState(false);
  const [zoom, setZoom] = useState(13); // initial zoom
  const [center, setCenter] = useState<google.maps.LatLngLiteral>({
    lat: 0,
    lng: 0,
  });
  const [marker, setMarker] = useState<google.maps.LatLngLiteral>();
  const [newZoom, setNewZoom] = useState(13); // initial zoom
  const [newCenter, setNewCenter] = useState<google.maps.LatLngLiteral>({
    lat: 0,
    lng: 0,
  });
  const [newMarker, setNewMarker] = useState<google.maps.LatLngLiteral>();
  const [showRejectSite, setShowRejectSite] = useState<boolean>(false);
  const [showRejectOutlet, setShowRejectOutlet] = useState<boolean>(false);
  const [showRejectSiteChanges, setShowRejectSiteChanges] =
    useState<boolean>(false);
  const [showRejectOutletChanges, setShowRejectOutletChanges] =
    useState<boolean>(false);

  const onIdle = (m: google.maps.Map) => {
    setZoom(m.getZoom()!);
    setCenter(m.getCenter()!.toJSON());
  };
  const newOnIdle = (m: google.maps.Map) => {
    setNewZoom(m.getZoom()!);
    setNewCenter(m.getCenter()!.toJSON());
  };

  useEffect(() => {
    if (siteOwnerError) {
      setShowSiteAlert(true);
    } else if (showSiteAlert) {
      setShowSiteAlert(false);
    }
    return () => {
      setShowSiteAlert(false);
    };
  }, [siteOwnerError, showSiteAlert]);

  useEffect(() => {
    if (siteError) {
      setShowOutletAlert(true);
    } else if (showOutletAlert) {
      setShowOutletAlert(false);
    }
    return () => {
      setShowOutletAlert(false);
    };
  }, [siteError, showOutletAlert]);

  useEffect(() => {
    if (sites) setSelectedOutlet(sites[0]);
  }, [sites]);

  useEffect(() => {
    if (selectedSite && selectedSite.location) {
      setCenter({
        lat: selectedSite.location.lat,
        lng: selectedSite.location.lng,
      });
      setMarker({
        lat: selectedSite.location.lat,
        lng: selectedSite.location.lng,
      });
    }
    if (selectedSite?.changes?.location) {
      setNewCenter({
        lat: selectedSite.changes.location.lat,
        lng: selectedSite.changes.location.lng,
      });
      setNewMarker({
        lat: selectedSite.changes.location.lat,
        lng: selectedSite.changes.location.lng,
      });
    }
  }, [selectedSite]);

  const handleSelectOutlet = (event) => {
    const site = sites.find((o) => o.id === event.target.value);
    setSelectedOutlet(site);
  };

  const handleConfirmSiteOwner = async () => {
    await siteOwnerConfirmRegistration(true);
    await siteConfirmRegistration(true);
  };

  const handleRejectSite = async (data: RejectRetailerForm) => {
    await siteOwnerConfirmRegistration(false, data.comment);
    await siteConfirmRegistration(false, data.comment);
    // navigate('/sites', { replace: true });
    setShowRejectSite(false);
  };

  const handleConfirmSiteOwnerChanges = async () => {
    await siteOwnerConfirmChanges(true, siteOwner.changes);
    siteReset();
  };

  const handleRejectSiteOwnerChanges = async (
    data: RejectRetailerChangesForm
  ) => {
    await siteOwnerConfirmChanges(false, siteOwner.changes, data.comment);
    siteReset();
    setShowRejectSite(false);
  };

  const handleConfirmSite = async () => {
    if (
      sites.filter(
        (site) =>
          site.status !== 'confirmed' && site.status !== 'changesRejected'
      ).length === 1
    ) {
      siteOwnerUpdate({ siteChanged: false });
    }
    await siteConfirmRegistration(true);
    siteReset();
  };

  const handleRejectOutlet = async (data: RejectOutletForm) => {
    if (
      sites.filter(
        (site) =>
          site.status !== 'confirmed' && site.status !== 'changesRejected'
      ).length === 1
    ) {
      siteOwnerUpdate({ siteChanged: false });
    }
    await siteConfirmRegistration(false, data.comment);
    siteReset();
    setShowRejectSite(false);
  };

  const handleConfirmOutletChanges = async () => {
    if (
      sites.filter(
        (site) =>
          site.status !== 'confirmed' &&
          site.status !== 'changesRejected' &&
          site.status !== 'rejected'
      ).length <= 1
    ) {
      siteOwnerUpdate({ siteChanged: false });
    }
    await siteConfirmChanges(true, site.changes);
    siteReset();
  };

  const handleRejectOutletChanges = async (data: RejectOutletChangesForm) => {
    if (
      sites.filter(
        (site) =>
          site.status !== 'confirmed' &&
          site.status !== 'changesRejected' &&
          site.status !== 'rejected'
      ).length <= 1
    ) {
      siteOwnerUpdate({ siteChanged: false });
    }
    await siteConfirmChanges(false, site.changes, data.comment);
    siteReset();
    setShowRejectSite(false);
  };

  const handleConfirmSiteRejection = async () => {
    await siteOwnerConfirmRejection();
    navigate('/siteOwners', { replace: true });
  };

  const handleConfirmOutletRejection = async () => {
    await siteConfirmRejection();
  };

  const handleConfirmSiteChangesRejection = async () => {
    await siteOwnerConfirmChangesRejection();
  };

  const handleConfirmOutletChangesRejection = async () => {
    await siteConfirmChangesRejection();
  };

  return (
    <Content>
      <Loader show={siteOwnerLoading || siteLoading} />
      <Alert
        message={siteOwnerError && siteOwnerError.message}
        open={showSiteAlert}
        setOpen={(open) => setShowSiteAlert(open)}
        title="Error"
      />
      <Alert
        message={siteError && siteError.message}
        open={showOutletAlert}
        setOpen={(open) => setShowOutletAlert(open)}
        title="Error"
      />
      {checkAccess(['sop']) && (
        <RejectRetailer
          open={showRejectSite}
          onReject={handleRejectSite}
          setOpen={(open) => setShowRejectSite(open)}
        />
      )}
      {checkAccess(['sop']) && (
        <RejectOutlet
          open={showRejectOutlet}
          onReject={handleRejectOutlet}
          setOpen={(open) => setShowRejectOutlet(open)}
        />
      )}
      {checkAccess(['sop']) && (
        <RejectRetailerChanges
          open={showRejectSiteChanges}
          onReject={handleRejectSiteOwnerChanges}
          setOpen={(open) => setShowRejectSiteChanges(open)}
        />
      )}
      {checkAccess(['sop']) && (
        <RejectOutletChanges
          open={showRejectOutletChanges}
          onReject={handleRejectOutletChanges}
          setOpen={(open) => setShowRejectOutletChanges(open)}
        />
      )}
      {siteOwnerLoading || siteOwner ? (
        <Card>
          <div className="grid grid-cols-12">
            <Back to="/siteOwners" className=" col-span-3" />
            <div className="col-span-6 flex justify-center items-center text-center text-xl font-bold text-lh-head-black">
              {siteOwner && (
                <FontAwesomeIcon
                  icon={faUserCircle}
                  className="mr-2 text-lh-head-black"
                />
              )}
              Site
              {siteOwner && (
                <FontAwesomeIcon
                  icon={faCircle}
                  className={clsx(
                    (siteOwner.status === 'unconfirmed' ||
                      siteOwner.status === 'changes') &&
                      'ml-2 text-yellow-500',
                    ((siteOwner.status === 'confirmed' &&
                      !siteOwner.siteChanged &&
                      siteOwner.phoneCorrect) ||
                      (siteOwner.status === 'changesRejected' &&
                        !siteOwner.siteChanged &&
                        siteOwner.phoneCorrect)) &&
                      'ml-2 text-green-600',
                    siteOwner.status === 'rejected' ||
                      ('phoneCorrect' in siteOwner &&
                        !siteOwner.phoneCorrect &&
                        'ml-2 text-red-600'),
                    siteOwner.siteChanged && 'ml-2 text-yellow-500'
                  )}
                />
              )}
            </div>
            <Edit
              to={`/siteOwners/${siteOwner?.id}`}
              className="col-span-3 flex-row-reverse"
            />
            <p className="text-lh-head-black text-lg col-span-3 col-start-2">
              Name:
            </p>
            <p className="text-lh-head-black text-lg col-span-8 text-left">
              {siteOwner && siteOwner.name}
            </p>
            <p className="text-lh-head-black text-lg col-span-3 col-start-2">
              Phone:
            </p>
            <p
              className={clsx(
                'text-lg col-span-8 text-left text-lh-head-black',
                siteOwner && !siteOwner.phoneCorrect && 'text-red-600'
              )}
            >
              {siteOwner && siteOwner.phone}
            </p>
            {siteOwner &&
              (siteOwner.status === 'confirmed' ||
                siteOwner.status === 'changesRejected') && (
                <>
                  <p className="text-lh-head-black text-lg col-span-3 col-start-2">
                    Last order:
                  </p>
                  <p className="text-lh-head-black text-lg col-span-8 text-left">
                    {siteOwner && siteOwner.lastOrderPlacedAt
                      ? dayjs(siteOwner.lastOrderPlacedAt).format(
                          'DD.MM.YYYY HH:mm'
                        )
                      : 'No orders placed yet'}
                  </p>
                </>
              )}
            {siteOwner && siteOwner.status === 'changes' && (
              <>
                {siteOwner && siteOwner.changes && siteOwner.changes.name && (
                  <>
                    <p className="text-lh-head-black text-lg col-span-3 col-start-2 font-bold">
                      New name:{' '}
                    </p>
                    <p className="text-lh-head-black text-lg col-span-8 font-bold">
                      {siteOwner.changes && siteOwner.changes.name}
                    </p>
                  </>
                )}
                {siteOwner && siteOwner.changes && siteOwner.changes.phone && (
                  <>
                    <p className="text-lh-head-black text-lg col-span-3 col-start-2 font-bold">
                      New phone:{' '}
                    </p>
                    <p className="text-lh-head-black text-lg col-span-8 font-bold">
                      {siteOwner.changes && siteOwner.changes.phone}
                    </p>
                  </>
                )}
                {checkAccess(['sop']) && (
                  <div className="col-span-full flex justify-between">
                    <Button
                      onClick={handleConfirmSiteOwnerChanges}
                      color="green"
                      className="flex-grow"
                    >
                      Confirm
                    </Button>
                    <Button
                      onClick={() => setShowRejectSiteChanges(true)}
                      color="red"
                      className="flex-grow"
                    >
                      Reject
                    </Button>
                  </div>
                )}
              </>
            )}
            <hr className="border-black mt-2 col-span-full" />
            {sites && (
              <>
                {sites.length > 1 ? (
                  <>
                    <div className="col-span-12 grid grid-cols-12 justify-center items-center text-center text-xl font-bold text-lh-head-black">
                      <h1 className="col-span-11 font-bold mt-2 text-center text-xl">
                        <FontAwesomeIcon
                          icon={faStore}
                          className="mr-2 text-lh-head-black"
                        />
                        {site && site.type ? site.type : 'Non'}
                      </h1>
                      <Edit
                        to={`/sites/${selectedSite?.id}`}
                        className="col-span-1 flex-row-reverse"
                      />
                    </div>
                    <select
                      className={
                        'col-span-full text-center cursor-pointer border-2 bg-gray-200 rounded-lg mt-2'
                      }
                      onChange={handleSelectOutlet}
                      value={selectedSite?.id}
                    >
                      {sites &&
                        sites.map((site) => {
                          return (
                            <option key={site.id} value={site.id}>
                              {site.name}
                            </option>
                          );
                        })}
                    </select>
                  </>
                ) : (
                  <div className="col-span-12 grid grid-cols-12 items-center text-center text-xl font-bold text-lh-head-black">
                    <h1 className="col-span-11 font-bold mt-2 text-center text-xl">
                      <FontAwesomeIcon
                        icon={faStore}
                        className="mr-2 text-lh-head-black"
                      />
                      {site && site.type ? site.type : 'non'}
                    </h1>
                    <Edit
                      to={`/sites/${selectedSite?.id}`}
                      className="col-span-1 flex-row-reverse"
                    />
                  </div>
                )}
                <div className="col-span-full grid grid-cols-12 justify-center mt-2">
                  <p className="text-lh-head-black text-lg col-span-3 col-start-2">
                    Name:
                  </p>
                  <p className="text-lh-head-black text-lg col-span-8 text-left">
                    {site && site.name}
                  </p>
                  <p className="text-lh-head-black text-lg col-span-3 col-start-2">
                    TAE:
                  </p>
                  <p className="text-lh-head-black text-lg col-span-8 text-left">
                    {site && site.primaryTAE.name}
                  </p>
                </div>
                <hr className="border-black mt-2 col-span-full" />
                <h1 className="col-span-12 font-bold mt-2 text-center text-xl">
                  Metrics
                </h1>
                <div className="col-span-full grid grid-cols-12 justify-center mt-2">
                  <p className="text-lh-head-black text-lg col-span-11 col-start-2 font-bold">
                    Leads
                  </p>
                  <p className="text-lh-head-black text-lg col-span-3 col-start-2">
                    Volume:
                  </p>
                  <p className="text-lh-head-black text-lg col-span-8 text-left">
                    {site && site.fulfilledVolumeLeads}
                  </p>
                  <p className="text-lh-head-black text-lg col-span-3 col-start-2">
                    Amount:
                  </p>
                  <p className="text-lh-head-black text-lg col-span-8 text-left">
                    {site && site.leadsAmountFulfilled}
                  </p>
                </div>
                {site && site.status === 'changes' && (
                  <>
                    <hr className="border-black mt-2 col-span-full" />
                    <h1 className="col-span-12 font-bold mt-2 text-center text-xl">
                      Changes
                    </h1>
                    {site.changes.name && (
                      <>
                        <p className="col-span-5 col-start-2 font-bold mt-2">
                          New name:
                        </p>
                        <p className="col-span-6 font-bold mt-2">
                          {site.changes.name}
                        </p>
                      </>
                    )}
                    {site.changes.type && (
                      <>
                        <p className="col-span-5 col-start-2 font-bold mt-2">
                          New Type:
                        </p>
                        <p className="col-span-6 font-bold mt-2">{site.type}</p>
                      </>
                    )}
                    {site.changes.location && (
                      <div className="col-span-full h-56 flex mt-4">
                        <Wrapper
                          apiKey={process.env.REACT_APP_MAPS_API_KEY}
                          render={render}
                        >
                          <Map
                            center={newCenter}
                            onIdle={newOnIdle}
                            zoom={newZoom}
                            className="flex-grow h-full rounded-lg"
                            disableDefaultUI
                          >
                            {newMarker && <Marker position={newMarker} />}
                          </Map>
                        </Wrapper>
                      </div>
                    )}
                    {checkAccess(['sop']) && (
                      <div className="col-span-full flex justify-between mt-6">
                        <Button
                          onClick={handleConfirmOutletChanges}
                          color="green"
                          className="flex-grow"
                        >
                          Confirm
                        </Button>
                        <Button
                          onClick={() => setShowRejectOutletChanges(true)}
                          color="red"
                          className="flex-grow"
                        >
                          Reject
                        </Button>
                      </div>
                    )}
                  </>
                )}
                {selectedSite && selectedSite.location && (
                  <div className="col-span-full h-56 flex mt-4">
                    <Wrapper
                      apiKey={process.env.REACT_APP_MAPS_API_KEY}
                      render={render}
                    >
                      <Map
                        center={center}
                        onIdle={onIdle}
                        zoom={zoom}
                        className="flex-grow h-full rounded-lg"
                        disableDefaultUI
                      >
                        ={marker && <Marker position={marker} />}
                      </Map>
                    </Wrapper>
                  </div>
                )}
                {checkAccess(['sop']) &&
                  site &&
                  site.status === 'unconfirmed' &&
                  ['confirmed', 'changes'].includes(siteOwner.status) && (
                    <div className="col-span-full flex justify-between">
                      <Button
                        onClick={handleConfirmSite}
                        color="green"
                        className="flex-grow"
                      >
                        Confirm
                      </Button>
                      <Button
                        onClick={() => setShowRejectOutlet(true)}
                        color="red"
                        className="flex-grow"
                      >
                        Reject
                      </Button>
                    </div>
                  )}
                {checkAccess(['tae']) &&
                  siteOwner &&
                  siteOwner.status !== 'rejected' &&
                  site &&
                  site.status === 'rejected' && (
                    <div className="col-span-full flex flex-col">
                      <h2>
                        The Outlet has been rejected with the following reason:{' '}
                      </h2>
                      <p>{site && site.rejectionComment}</p>
                      <p>
                        Contact your SOP if you have further questions about
                        this.
                      </p>
                      <Button
                        onClick={handleConfirmOutletRejection}
                        color="red"
                      >
                        Confirm Rejection
                      </Button>
                    </div>
                  )}
                {checkAccess(['tae']) &&
                  siteOwner &&
                  siteOwner.status !== 'changesRejected' &&
                  site &&
                  site.status === 'changesRejected' && (
                    <div className="col-span-full flex flex-col">
                      <h2>
                        The changes to the Outlet have been rejected with the
                        following reason:{' '}
                      </h2>
                      <p>{site && site.rejectionComment}</p>
                      <p>
                        Contact your SOP if you have further questions about
                        this.
                      </p>
                      <Button
                        onClick={handleConfirmOutletChangesRejection}
                        color="red"
                      >
                        Confirm Rejection
                      </Button>
                    </div>
                  )}
              </>
            )}
            {siteOwner &&
              siteOwner.status === 'unconfirmed' &&
              checkAccess(['sop']) && (
                <div className="col-span-full flex justify-between mt-6">
                  <Button
                    onClick={handleConfirmSiteOwner}
                    color="green"
                    className="flex-grow"
                  >
                    Confirm
                  </Button>
                  <Button
                    onClick={() => setShowRejectSite(true)}
                    color="red"
                    className="flex-grow"
                  >
                    Reject
                  </Button>
                </div>
              )}
            {checkAccess(['tae']) &&
              siteOwner &&
              siteOwner.status === 'rejected' && (
                <div className="col-span-full flex flex-col mt-6">
                  <h2>
                    The Site has been rejected with the following reason:{' '}
                  </h2>
                  <p>{siteOwner && siteOwner.rejectionComment}</p>
                  <p>
                    Contact your SOP if you have further questions about this.
                  </p>
                  <Button onClick={handleConfirmSiteRejection} color="red">
                    Confirm Rejection
                  </Button>
                </div>
              )}
            {checkAccess(['tae']) &&
              siteOwner &&
              siteOwner.status === 'changesRejected' && (
                <div className="col-span-full flex flex-col mt-6">
                  <h2>
                    The changes to the Site have been rejected with the
                    following reason:{' '}
                  </h2>
                  <p>{siteOwner && siteOwner.rejectionComment}</p>
                  <p>
                    Contact your SOP if you have further questions about this.
                  </p>
                  <Button
                    onClick={handleConfirmSiteChangesRejection}
                    color="red"
                  >
                    Confirm Rejection
                  </Button>
                </div>
              )}
            {checkAccess(['tae']) &&
              siteOwner &&
              siteOwner.status === 'confirmed' && (
                <div className="col-span-full flex flex-col mt-6">
                  <Button
                    onClick={() => navigate('/siteVisits/' + selectedSite.id)}
                    color="blue"
                  >
                    Start Visit
                  </Button>
                </div>
              )}
          </div>
        </Card>
      ) : (
        <Card>Site not found!</Card>
      )}
    </Content>
  );
};

export default SiteOwner;
