import { FirebaseError } from 'firebase/app';
import {
  Query,
  query,
  where,
  orderBy,
  FirestoreError,
  Unsubscribe,
  collection,
  doc,
  onSnapshot,
} from 'firebase/firestore';
import { useState, useEffect, useMemo } from 'react';
import {
  DeliveryType,
  DeliveryStatus,
  PageDirection,
} from '../../../../global';
import { useCheckAccess } from '../auth/use-checkAccess';
import { confirmDelivery, rejectDelivery } from '../db/deliveries';
import { useLoadingValue } from '../utils';
import { docToJSON, firestore } from '../utils/firebase';
import { usePagination } from '../utils/usePagination';
import { useAuth } from './use-auth';

type DeliveriesHook = {
  error: FirebaseError;
  hasMore: boolean;
  load: () => void;
  loading: boolean;
  reset: () => void;
  deliveries: DeliveryType[];
};

type DeliveriesHookProps = {
  status?: DeliveryStatus;
  userId?: string;
  pageSize?: number;
  direction: PageDirection;
};

type DeliveryHook = {
  confirm: () => void;
  delivery: DeliveryType;
  error: FirebaseError;
  loading: boolean;
  reject: (comment: string) => void;
};

export const useDeliveries = ({
  userId,
  status = 'open',
  pageSize = 10,
  direction = 'asc',
}: DeliveriesHookProps): DeliveriesHook => {
  const { id } = useAuth();
  const [deliveriesQuery, setDeliveriesQuery] = useState<Query>();
  const { error, hasMore, load, loading, reset, values } = usePagination(
    deliveriesQuery,
    pageSize
  );

  useEffect(() => {
    if (id) {
      let q = query(
        collection(firestore, 'deliveries'),
        where('userIds', 'array-contains', id),
        orderBy('createdAt', direction)
      );

      if (status) {
        q = query(q, where('status', 'in', status));
      }
      setDeliveriesQuery(q);
    }
  }, [id, status, userId]);

  return useMemo(
    () => ({
      error,
      hasMore,
      load,
      loading,
      reset,
      deliveries: values
        ? values.map((v) => docToJSON(v) as DeliveryType)
        : values,
    }),
    [error, hasMore, load, loading, reset, values]
  );
};

export const useDelivery = (id?: string): DeliveryHook => {
  const checkAccess = useCheckAccess();
  const { id: userId } = useAuth();
  const { error, loading, setLoading, setError, setValue, value } =
    useLoadingValue<DeliveryType | null, FirestoreError>();

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    if (userId && id) {
      const ref = doc(firestore, `deliveries/${id}`);
      unsubscribe = onSnapshot(
        ref,
        (doc) => {
          if (doc.exists) {
            setValue(docToJSON(doc) as DeliveryType);
          } else {
            setValue(null);
          }
          setLoading(false);
        },
        setError
      );
    } else {
      setValue(null);
    }
    return unsubscribe;
  }, [id, userId]);

  const confirm = async () => {
    if (!id || !checkAccess(['sop'])) return;
    try {
      setLoading(true);
      await confirmDelivery(id);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  };

  const reject = async (comment: string) => {
    if (!id || !checkAccess(['sop'])) return;
    try {
      setLoading(true);
      await rejectDelivery(id, comment);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  };

  return useMemo(
    () => ({
      confirm,
      delivery: value,
      error,
      loading,
      reject,
    }),
    [error, loading, value]
  );
};
