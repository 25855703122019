import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRef, useState } from 'react';
import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Alert from '../../../components/ui/Alert';
import Loader from '../../../components/ui/Loader';
import VisitStep0 from './SiteVisitStep0';
import VisitStep1 from './SiteVisitStep1';
import VisitStep2 from './SiteVisitStep2';
import VisitStep3 from './SiteVisitStep3';
import VisitStep4 from './SiteVisitStep4';
import Content from '../../../components/layout/Content';
import Card from '../../../components/ui/Card';
import Back from '../../../components/ui/Back';
import Button from '../../../components/ui/Button';
import { Timestamp } from 'firebase/firestore';
import { useUser } from '../../../lib/hooks/use-user';
import dayjs from 'dayjs';
import { Location } from '../../../../../global';
import { getDistance } from 'geolib';
import { useSite } from '../../../lib/hooks/use-sites';
import { useSiteVisit } from '../../../lib/hooks/use-siteVisits';
import SiteVisitStep0 from './SiteVisitStep0';
import SiteVisitStep1 from './SiteVisitStep1';
import SiteVisitStep2 from './SiteVisitStep2';
import SiteVisitStep3 from './SiteVisitStep3';
import SiteVisitStep4 from './SiteVisitStep4';

type VisitParams = 'id';

interface CustomHTMDIVElement extends HTMLDivElement {
  saveStep1?: Function;
  saveTechAssistance?: Function;
}

const SiteVisit = () => {
  const { id } = useParams<VisitParams>();
  const { user } = useUser();
  const navigate = useNavigate();
  const [visitStep, setVisitStep] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [step1, setStep1] = useState(null);
  const [technicalAssistance, setTechnicalAssistance] = useState(null);
  const [leadPlaced, setLeadPlaced] = useState(false);
  const visitStep1Ref = useRef<CustomHTMDIVElement>(null);
  const visitStep2Ref = useRef<CustomHTMDIVElement>(null);
  const [startedAt, setStartedAt] = useState(null);
  const [visitId, setVisitId] = useState('');
  const [location, setLocation] = useState<Location>();
  const [geohashLoading, setGeohashLoading] = useState<boolean>(false);
  const [step1IsDirty, setStep1IsDirty] = useState<boolean>(false);
  const [step1IsValid, setStep1IsValid] = useState<boolean>(false);
  const [step2IsValid, setStep2IsValid] = useState<boolean>(false);

  const {
    site,
    loading: siteLoading,
    error: siteError,
    update: siteUpdate,
  } = useSite(id);

  const {
    visit,
    loading: visitLoading,
    error: visitError,
    add,
  } = useSiteVisit(visitId);

  const finalStep = 4;

  useEffect(() => {
    if (id && site) {
      setVisitId(id);
      setVisitStep(1);
    }
  }, [id]);

  useEffect(() => {
    setStartedAt(Timestamp.now());
  }, []);

  const handleNext = () => {
    if (visitStep !== finalStep) {
      setVisitStep(visitStep + 1);
    }

    if (visitStep === 1) {
      if (visitStep1Ref && visitStep1Ref.current) {
        visitStep1Ref.current.saveStep1();
      }
    }

    if (visitStep === 2) {
      if (visitStep2Ref && visitStep2Ref.current) {
        visitStep2Ref.current.saveTechAssistance();
      }
    }
  };

  const handlePrevious = () => {
    if (visitStep !== 0) {
      setVisitStep(visitStep - 1);
    }

    if (visitStep === 1) {
      if (visitStep1Ref && visitStep1Ref.current && !visit) {
        visitStep1Ref.current.saveStep1();
      }
    }

    if (visitStep === 2) {
      if (visitStep2Ref && visitStep2Ref.current && !visit) {
        visitStep2Ref.current.saveTechAssistance();
      }
    }
  };

  const visitStep1Props = {
    setStep1: setStep1,
    step1: step1,
  };

  const visitStep2Props = {
    setTechnicalAssistance: setTechnicalAssistance,
    technicalAssistance: technicalAssistance,
  };

  const addVisit = async () => {
    const siteLocation = site.location;

    await add({
      siteVisitBasics: step1,
      technicalAssistance,
      distance: getDistance(location, siteLocation),
      location: siteLocation,
      leadPlaced: leadPlaced,
      createdBy: {
        id: user.id,
        name: user.name,
        phone: user.phone,
      },
      sop: {
        email: user.sop.email,
        id: user.sop.id,
        name: user.sop.name,
        phone: user.sop.phone,
      },
      createdAt: Timestamp.now(),
      site: site,
      startedAt: startedAt,
      finishedAt: Timestamp.now(),
      day: dayjs().date(),
      month: dayjs().month() + 1,
      year: dayjs().year(),
    });

    navigate('/siteVisits');
  };

  /*
  const captureLocation = (location: Location) => {
    siteUpdate({ location });
  };
  */

  return (
    <Content>
      <Loader show={visitLoading} />
      <Alert
        message={visitError && 'Error'}
        open={showAlert}
        setOpen={(open) => setShowAlert(open)}
        title="Error"
      />
      {visitLoading || site ? (
        <Card>
          <div className="grid grid-cols-12">
            {visitStep === 0 && (
              <Back to="/siteVisits" className=" col-span-3" />
            )}
            <div className="grid grid-cols-9 col-span-12 gap-2">
              <div className="grid grid-rows-2 col-span-9 text-center">
                <h2 className="font-bold row-span-1 -mt-4">
                  {site && site.name}
                </h2>
              </div>
              {!visit && (
                <SiteVisitStep0
                  currentStep={visitStep}
                  site={site}
                  //setLocation={captureLocation}
                />
              )}
              {visitStep === 1 && (
                <SiteVisitStep1
                  ref={visitStep1Ref}
                  {...visitStep1Props}
                  editable={visit ? true : false}
                  setLocation={setLocation}
                  setGeohashLoading={setGeohashLoading}
                  geohashLoading={geohashLoading}
                  setStep1IsDirty={setStep1IsDirty}
                  setStep1IsValid={setStep1IsValid}
                />
              )}
              {visitStep === 2 && (
                <SiteVisitStep2
                  ref={visitStep2Ref}
                  {...visitStep2Props}
                  editable={visit ? true : false}
                  setStep2IsValid={setStep2IsValid}
                />
              )}
              <SiteVisitStep3
                currentStep={visitStep}
                site={site}
                setLeadPlaced={setLeadPlaced}
              />
              <SiteVisitStep4
                currentStep={visitStep}
                changeStep={(step) => setVisitStep(step)}
                step1Done={step1 ? true : false}
                step2Done={
                  technicalAssistance &&
                  !Object.values(technicalAssistance).every((v) => v === false)
                    ? true
                    : false
                }
                step3Done={leadPlaced}
              />
              {visitStep === 0 &&
                (site?.location || site?.changes?.location) && (
                  <Button
                    className="col-span-7 col-start-2"
                    onClick={() => handleNext()}
                    text={'Click to Check-In'}
                  />
                )}
              {visitStep === finalStep && (
                <Button
                  buttonDisabled={
                    !(
                      (step1 ? true : false) ||
                      (technicalAssistance &&
                      !Object.values(technicalAssistance).every(
                        (v) => v === false
                      )
                        ? true
                        : false)
                    )
                  }
                  className="col-span-7 col-start-2"
                  onClick={() => addVisit()}
                  text={'Click to Check-Out'}
                />
              )}
              {((visitStep !== 1 && visit) || (visitStep !== 0 && !visit)) && (
                <button
                  className="col-span-4 mt-12 font-semibold"
                  onClick={() => handlePrevious()}
                >
                  <FontAwesomeIcon icon={faAngleLeft} /> Go back
                </button>
              )}
              {((visitStep !== 0 &&
                visitStep !== finalStep &&
                visitStep !== 1 &&
                visitStep !== 2 &&
                !visit) ||
                (visitStep !== 0 &&
                  visitStep !== 3 &&
                  visitStep !== 2 &&
                  visit) ||
                (visitStep === 1 && step1IsDirty && step1IsValid) ||
                (visitStep === 2 && step2IsValid)) && (
                <button
                  disabled={geohashLoading}
                  className="col-span-4 col-start-5 text-right mt-12 font-semibold"
                  onClick={() => {
                    handleNext();
                  }}
                >
                  Next step <FontAwesomeIcon icon={faAngleRight} />
                </button>
              )}
            </div>
          </div>
        </Card>
      ) : (
        <Card>Retailer not found!</Card>
      )}
    </Content>
  );
};

export default SiteVisit;
