import { useEffect, useState } from 'react';
import { RegisterOptions, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Confirm } from '../../../components/forms/Confirm';
import Input from '../../../components/forms/Input';
import Content from '../../../components/layout/Content';
import Back from '../../../components/ui/Back';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import { useCheckPhone } from '../../../lib/hooks/use-checkphone';
import { useSiteOwner } from '../../../lib/hooks/use-siteOwners';
import { useSite } from '../../../lib/hooks/use-sites';
import { asyncDebounce } from '../../../lib/utils/debounce';

type EditSiteParams = 'id';

type EditSiteForm = {
  name: string;
  phone: string;
};

const EditSiteOwner = () => {
  const { id } = useParams<EditSiteParams>();
  const navigate = useNavigate();
  const checkPhone = useCheckPhone();
  const { siteOwner, error, loading, update } = useSiteOwner(id);
  const {
    register,
    formState: { isValid, isDirty, errors, dirtyFields },
    reset,
    handleSubmit,
    trigger,
  } = useForm<EditSiteForm>();

  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

  const validatePhoneNumber = asyncDebounce(async (v: string) => {
    if (!dirtyFields.phone) {
      return true;
    }
    const phoneExists = await checkPhone(v);
    return !phoneExists || 'Phone is already in use!';
  }, 500);

  const nameOptions: RegisterOptions = {
    required: 'Please enter a name!',
    minLength: {
      value: 3,
      message: 'Please enter a name with at least 3 characters!',
    },
    maxLength: {
      value: 50,
      message: 'The name cannot exceed 50 characters!',
    },
    onChange: () => trigger('name'),
  };

  const phoneOptions: RegisterOptions = {
    required: 'Please enter a phone number!',
    pattern: {
      value: /^\+[0-9]{11,13}$/,
      message: 'Please enter a valid phone number!',
    },
    validate: validatePhoneNumber,
    onChange: () => trigger('phone'),
  };

  useEffect(() => {
    if (siteOwner) {
      reset({ name: siteOwner.name, phone: siteOwner.phone });
    }
  }, [siteOwner]);

  const submitHandler = (data: EditSiteForm) => {
    if (isDirty && isValid) {
      const updateObj = {};
      if (dirtyFields.name) updateObj['name'] = data.name;
      if (dirtyFields.phone) updateObj['phone'] = data.phone;
      update(updateObj);
      navigate(`/siteOwners/${id}`, { replace: true });
    }
  };

  const handleOnConfirm = () => {
    setShowConfirmation(false);
    handleSubmit(submitHandler)();
  };

  const handleOnCancel = () => {
    setShowConfirmation(false);
  };

  return (
    <Content>
      <Confirm
        title="Are you sure you want to save these changes?"
        open={showConfirmation}
        onConfirm={handleOnConfirm}
        onCancel={handleOnCancel}
        setOpen={(open) => {
          setShowConfirmation(open);
        }}
      />
      <Card>
        <Back to={`/siteOwners/${id}`} className="col-span-3" />
        <form
          className="col-span-full flex flex-col"
          onSubmit={(event) => event.preventDefault()}
        >
          <label htmlFor="name">Name</label>
          <Input
            register={register}
            options={nameOptions}
            name="name"
            type="text"
            placeholder="Name..."
            error={errors.name}
          />
          <label htmlFor="phone">Phone</label>
          <Input
            register={register}
            options={phoneOptions}
            name="phone"
            type="text"
            placeholder="+234"
            error={errors.phone}
          />
          <Button
            buttonDisabled={!isValid || !isDirty}
            text="Save"
            color="green"
            onClick={() => {
              setShowConfirmation(true);
            }}
          />
        </form>
      </Card>
    </Content>
  );
};

export default EditSiteOwner;
