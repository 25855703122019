import { Auth, AuthError, onAuthStateChanged, User } from 'firebase/auth';
import { useEffect, useMemo } from 'react';
import { LoadingHook, useLoadingValue } from '../utils';

export type AuthStateHook = LoadingHook<User | null, AuthError>;

export const useAuthState = (auth: Auth): AuthStateHook => {
  const { error, loading, setError, setValue, value } = useLoadingValue<
    User | null,
    AuthError
  >(() => auth.currentUser);

  useEffect(() => {
    return onAuthStateChanged(auth, setValue, setError);
  }, [auth]);

  const resArray: AuthStateHook = [value, loading, error];
  return useMemo<AuthStateHook>(() => resArray, resArray);
};
